import { Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { ClientServiceService } from '../../services/clientservice.service';
import { CodeService } from '../../services/code.service';
import { ICode } from '../../model/code';
import { IPage } from '../../model/page';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { from, Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/add/observable/timer';
import { Subscription } from 'rxjs/Subscription';

import * as jquery from 'jquery';
declare var $: any;

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
})

export class ServicesListComponent implements OnInit {
  @Input() client_id: any;
  services: any[] = [];
  isFilterTrue = true;
  // userValue: IUserValue;
  paging: IPage;
  per_page = 10;
  selected_status: number;
  filter: any = { 'name': '', 'order': 'asc'};
  curr_user_id: any;
  total: number = 0;
  newActive: any = false;
  public row_header: any = [];
  public header_index: any;
  public assignee_array: any = [];
  private allItems: any[];
  showloader: boolean;
  pager: any = {};
  pagedItems: any[];
  crumbList: any[];
  serviceCodes: any[];
  private timer: Observable<any>;
  private subscription: Subscription;
  successful_result = false;
  error_result = false;
  editRec: any;
  firstCodeValue: any;

  constructor(
    private adminSer: AdminTiketService,
    private pagerS: PagerService,
    private clientServiceSer: ClientServiceService,
    private codeSer: CodeService,
    private modalService: NgbModal,
    private ngZone: NgZone) {
      this.showloader = true;
  }

  ngOnInit() {
    // this.crumbList = [
    //   { title: 'Home', url: '/dashboard' },
    //   { title: 'Admin Menu', url: '/admins/admin-menu' }
    // ];
    const userData = localStorage.getItem('UserData');
    this.curr_user_id = JSON.parse(userData);
    this.selected_status = null;

    this.row_header  = [
      {text: 'Service', value: 'Code', 'sort': null},
      {text: 'Active', value: 'Active', 'sort': 'none' }
    ];
    this.header_index = 0;

    this.editRec = null;
    this.codeSer.getServices().subscribe((serviceData: any) => {
      this.serviceCodes = serviceData;
      this.firstCodeValue = serviceData[0].Value;
      this.tableSort(0);
    });
  }

  updateDescription( event: any ) {
    const id = event.target.value;
    const servCode = this.findLocalCode( parseInt(id, 10) );
    if (servCode) {
      $('#' + event.target.id + 'desc').html( servCode.Value );
    } else {
      console.log('didnt find service_code');
    }
  }


  findLocalCode( id: number ) {
    for ( var idx = 0; idx < this.serviceCodes.length; idx++ ) {
       const obj = this.serviceCodes[idx];
       if (obj.ID === id) {
         return obj;
       }
    }
    return null;
  }


  findLocalService( id: number ) {
     for ( var idx = 0; idx < this.services.length; idx++ ) {
        const obj = this.services[idx];
        if (obj.ID === id) {
          return obj;
        }
     }
     return null;
  }

  cancelService(id: any) {
    if (this.editRec) {
      this.editRec = null;
    }
    if (id > 0) {
      $('#icon' + id).removeClass('fa-save').addClass('fa-pencil');
      $('#label' + id).show();
      $('#code' + id).hide();
    } else {
       $('#newservice').removeClass('show');
    }
  }

  editService(id: number) {
    if (this.editRec && $('#icon' + id).hasClass('fa-save')) {
      this.saveService(id);
      return;
    }

    if (this.editRec || this.newActive) {
      return;
    }

    const service = this.findLocalService( id );
    if (service === null) {
      alert('Unable to find Service');
      return;
    }
    this.editRec = service;
    $('#label' + id).hide();
    $('#code' + id).show();
    $('#icon' + id).removeClass('fa-pencil').addClass('fa-save');
  }

  filterByService(event) {
    this.showloader = true;
    const id = event.target.value;
    // this.filter.companyname = id;
    this.setPage(1);
  }

  setPage(page: number = 1) {
        const that = this;

        this.showloader = true;
        this.services = [];
        this.clientServiceSer.getClientServicesByClient( this.client_id ).subscribe( (serviceData: any) => {
                that.showloader = false;
                this.total = serviceData.length;
                from(serviceData).subscribe( (obj:any) => {
                    const servCode = this.findLocalCode( obj.CodeID );
                    obj.description = servCode.Value;
                    obj.code = servCode.Code;
                    this.services.push( obj );
                });

          });
  }

  tableSort(index) {
    if (this.row_header[index].sort === 'none') {
       return;
    }

    this.showloader = true;
    if (this.header_index === index) {
      (this.row_header[index].sort === 'asc') ? this.row_header[index].sort = 'desc' : this.row_header[index].sort = 'asc';
    } else {
      this.row_header[index].sort = 'asc';
      this.row_header[this.header_index].sort = null;
      this.header_index = index;
    }
    this.setPage(1);
  }

  // collapseMe() {
  //   $('.collapse.show').removeClass('show');
  //   this.setPage(1);
  // }

  expandTable() {
    $('#serviceTable').addClass('show');
    this.newActive = true;
  }

  swapIcon(event: any) {
    const expanded = $('#expand-alt').hasClass('fa-expand-alt');
    if (expanded) {
      $('#expand').removeClass('fa-expand-alt').addClass('fa-compressv');
    } else {
      $('#expand').removeClass('fa-compress-alt').addClass('fa-expand-alt');
    }
    // window.scroll({
    //   top: event.pageY + 100,
    //   behavior: 'smooth'
    // });
  }

  cleanUpAfterSave( eId: any ) {
    this.editRec = null;
    this.newActive = false;
    this.showloader = false;
    const iCode =  parseInt( $('#code' + eId ).val(), 10 );
    const serRec = this.findLocalCode( iCode );
    if (serRec) {
      $('#label' + eId).html( serRec.Code );
    }
    this.cancelService( eId );
    if (eId === 0) {
      this.setPage(1);
    }
  }

  saveService( eId: any) {
    // this.submitted = true;
    this.showloader = true;

    const fd = new FormData();

    // let eId = 0;
    // if (this.editRec) {
    //   eId = parseInt( this.editRec.ID, 10);
    // }
    fd.append('CodeID', $('#code' + eId).val() );
    fd.append('ClientID', this.client_id);

    const that = this;
    if (this.editRec == null) {
        this.clientServiceSer.createClientService(fd).subscribe(
          {
          next(res: any) {
            that.cleanUpAfterSave(eId);
            if (res.status) {
              that.successful_result = true;
            } else {
              that.error_result = true;
              console.log('unable to save');
            }
            that.setTimer();
          },
          error(msg) {
            that.ngZone.run( () => {
              alert( 'Error saving record. Most likely the Service is a duplicate of an existing record' );
              that.cleanUpAfterSave(eId);
            });
          }
      });

    } else {
      this.clientServiceSer.saveClientService(this.editRec.ID, fd ).subscribe(
        {
          next(res: any) {
            that.cleanUpAfterSave(eId);
            console.log( res );
            if (res) {
              that.successful_result = true;
            } else {
              that.error_result = true;
              console.log('unable to save');
            }
            that.setTimer();
          },
          error(msg) {
            that.ngZone.run( () => {
              that.cleanUpAfterSave(eId);
            });
          }
        });
    }
  }

  public setTimer() {
    this.timer = Observable.timer(2500);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
    });
  }


  deleteService(id: number) {
    // if edit defined then we just need to cancel the edit
    if (this.editRec) {
      this.cancelService( id );
      return;
    }

    const service = this.findLocalService( id );
    if (service === null) {
      alert('Unable to find Service');
      return;
    }
    const servCode = this.findLocalCode( service.CodeID );
    if (servCode === null) {
      alert('Unable to find Service Code');
      return;
    }
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to delete ' +
    '<span class="text-primary">"' + servCode.Value + '"</span> record?</strong></p>' +
    '<p>All information associated to this Services record will be permanently deleted.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'Services Record';
    modalRef.result.then((res: any) => {
      this.clientServiceSer.deleteClientService(id).subscribe((data: any) => {
        this.setPage(1);
      });
    })
    .catch( (res: any) => {
    });

  }


}


