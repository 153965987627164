import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-resend',
  templateUrl: './resend.component.html',
  styleUrls: ['./resend.component.scss'],
  host: {'class': 'login'}
})
export class ResendComponent implements OnInit {
  isSubmit: boolean = false;
  submitted: boolean = false;
  form: FormGroup;
  isError: any = '';

  constructor(private fb: FormBuilder, private router: Router, private authS: AuthService) { }

  ngOnInit() {
   this.form = this.fb.group({
    resendEmail: ['', [Validators.required, Validators.email]]
   });
  }
  get f() { return this.form.controls; }
  /**
   * Submit
   */
  resendForm() {
    this.submitted = true;
   // stop here if form is invalid
   if (this.form.invalid) {
       return;
   }
   if (this.form.valid) {
     const data = new FormData();
     data.append('email', this.form.value.resendEmail);
     this.authS.forgotPassword(data).subscribe((data: any) => {
       console.log(data);
       if (data.status === true) {
         this.isSubmit = true;
       } else {
         this.isError = data.message;
       }
     });
    }
   // console.log(this.form.value.email);
  }

}
