import {Component, OnInit, Input, NgZone, EventEmitter, Output, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { IContent } from '../../model/content';
import { IPage } from '../../model/page';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CodeService } from '../../services/code.service';
import { UserService } from '../../services/user.service';

import * as jquery from 'jquery';
import { state } from '@angular/animations';
import { ContentService } from 'src/app/services/content.service';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss'],
})

export class ContentListComponent implements OnInit {


  isFilterTrue = true;
  // userValue: IUserValue;
  paging: IPage;
  totalContents: number;
  per_page = 10;
  selected_status: number;
  filter: any = { 'title': '' };
  contentList: any;
  total: number = 0;
  public row_header: any = [];
  public header_index: any;
  showloader: boolean;
  pager: any = {};
  pagedItems: any[];
  crumbList: any[];
  contentType: any = '';
  contentUrl: any = '';
  contentId: any = 0;

  constructor(
    private pagerS: PagerService,
    private contentSer: ContentService,
    private modalService: NgbModal,
    private actRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone) {
      this.showloader = true;
  }

  ngOnInit() {

    this.crumbList = [
      { title: 'Home', url: '/dashboard' }
    ];
    this.selected_status = null;
    const url = this.actRoute.snapshot['_routerState'].url;
    if (url.includes('newsletter')) {
      this.contentType = 'Newsletters';
      this.contentUrl = 'newsletter';
      this.contentId = 2;
    } else if (url.includes('webinar')) {
      this.contentType = 'Webinars';
      this.contentUrl = 'webinar';
      this.contentId = 4;
    } else if (url.includes('tip')) {
      this.contentType = 'HR Tips & Tools';
      this.contentUrl = 'tip';
      this.contentId = 5;
    } else if (url.includes('blog')) {
      this.contentType = 'Blogs';
      this.contentUrl = 'blog';
      this.contentId = 3;
    } else if (url.includes('news')) {
      this.contentType = 'News';
      this.contentUrl = 'news';
      this.contentId = 6;
    }

    this.row_header  = [
      {text: 'Title', value: 'title', 'sort': null},
      {text: 'Date Published', value: 'EffDate', 'sort': 'desc'}
    ];
    this.header_index = 1;

    this.setPage( 1 );
  }


  filterByTitle(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.title = id;
    this.setPage(1);
  }

  openContent( id: any ) {
    this.router.navigate(['/content/' + this.contentUrl + '/' + id]);
  }


  setPage(page: number = 1) {
        const that = this;

        this.filter.type = this.contentId;
        this.showloader = true;
        this.contentSer
          .getAllContent( page, this.per_page, this.row_header[this.header_index], this.filter)
          .subscribe( {
              next(data: any) {
                that.showloader = false;
                that.contentList = data.data; // return athe all data
                that.contentList = that.contentList.filter( x => x.Type === that.contentId );
                that.paging = data.page; // return all the paginationinfo
                that.total = that.paging.total;
                that.pager = that.pagerS.getPager(that.paging.total, page, that.per_page);
          },
          error(msg) {
            that.ngZone.run( () => {
              that.showloader = false;
            });
          }
        });
  }

  tableSort(index) {
    this.showloader = true;
    if (this.header_index === index) {
      (this.row_header[index].sort === 'asc') ? this.row_header[index].sort = 'desc' : this.row_header[index].sort = 'asc';
    } else {
      this.row_header[index].sort = 'asc';
      this.row_header[this.header_index].sort = null;
      this.header_index = index;
    }
    this.setPage(1);
  }


}


