import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {Routes, RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ResendComponent } from './resend/resend.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { VerifyTokenComponent } from './verify-token/verify-token.component';
import { SSOLoginComponent } from './sso-login/sso-login.component';

/**
 * Auth Routing module
 */
const auth_routing: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'signup', component: SignupComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'resend', component: ResendComponent},
  {path: 'verify-token/:token', component: VerifyTokenComponent},
  {path: 'sso-login', component: SSOLoginComponent},
  // {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(auth_routing),
    SharedModule,

  ],
  declarations: [
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ResendComponent,
    PageNotFoundComponent,
    VerifyTokenComponent,
    SSOLoginComponent
  ]
})
export class AuthModule { }
