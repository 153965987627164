import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { IPage } from '../../model/page';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { PageService } from '../../services/page.service';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';

// import { formControlBinding } from '@angular/forms/src/directives/ng_model';
// import { validateConfig } from '@angular/router/src/config';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
  page: IPage;
  pages: any;
  id: any;
  status: any;
  showloader = true;
  successful_result = false;
  error_result = false;
  dropdownList = [];
  dropdownSettings: {};
  form: FormGroup;
  title: string;
  submitted = false;
  post_results: string;
  private subscription: Subscription;
  private timer: Observable<any>;
  crumbList: any = [];
  center: any = 'HR';

  NameCtrl: FormControl;
  OrderCtrl: FormControl;

  constructor(
    private http: HttpClient,
    private userSer: UserService,
    private pageSer: PageService,
    private adminSer: AdminTiketService,
    private fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private router: Router ) {

      this.showloader = true;
  }

  ngOnInit() {
    this.center = sessionStorage.getItem( 'center');

    this.showloader = true;
    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' },
      { title: 'Page List', url: '/admins/page-list' }
    ];
    this.NameCtrl = new FormControl('CompanyName', [Validators.required]);
    const numberRegex = new RegExp(/^[0-9]$/);
    this.OrderCtrl = new FormControl('Order', [Validators.required, Validators.pattern(numberRegex)]);


    this.form = this.fb.group({
      Name: this.NameCtrl,
      Order: this.OrderCtrl
    });

    this.actRoute.params.subscribe( params =>
      this.id = params['id']
    );
    if (this.id === undefined) {
      this.id = 0;
      this.title = 'New Page';
      this.form.patchValue({
        Name: '',
        Order: 1
      });
      this.status = 'Yes';

      this.showloader = false;
    } else {
      this.title = 'Edit Page';
      this.pageSer.getPage(this.id).subscribe((pageData: any) => {
        this.form.patchValue({
              Name: pageData.Name,
              Order: pageData.Order
          });
          this.showloader = false;
      });
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    const userData = localStorage.getItem('UserData');
  }

  savePage() {
    // this.submitted = true;
    this.showloader = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showloader = false;
      return;
    }

    if (this.form.valid) {
      const fd = new FormData();

      fd.append('Name', this.form.value.Name);
      fd.append('Order', this.form.value.Order);
      fd.append('Center', this.center );

      if (this.id === 0) {
         this.pageSer.createPage(fd).subscribe((res: any) => {
            if (res.status) {
              this.successful_result = true;
              this.id = res.data.ID;
            } else {
              this.error_result = true;
              console.log('unable to save');
            }
            this.setTimer();
        });
      } else {
        this.pageSer.savePage(this.id, fd ).subscribe((res: any) => {
          if (res) {
             this.successful_result = true;
          } else {
            this.error_result = true;
            console.log('unable to save');
          }
          this.setTimer();
        });
      }
      this.showloader = false;
      this.submitted = false;
    }
  }

  public setTimer() {
    this.timer = Observable.timer(2500);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
    });
  }

  toggleChange(event: any) {
    this.status = (event.target.checked ? 'Yes' : 'No');
  }

}
