import { Component, OnInit, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { AuthKeyService } from '../../services/authkey.service';
import { AuthService } from '../../services/auth.service';
import { IAuth } from '../../model/auth';
import { IAuthKey } from '../../model/authkey';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';


class AuthKeyAuth {
  ID: number;
  Description: string;
  Status: number;
  StatusType: number;
}

@Component({
  selector: 'app-authkey',
  templateUrl: './authkey.component.html',
  styleUrls: ['./authkey.component.scss']
})
export class AuthKeyComponent implements OnInit {
  @Input() authkey_id: number = null;
  @Input() partner_id: number = null;
  @Output() submit_return: EventEmitter<boolean> = new EventEmitter<boolean>();

  authkey: IAuthKey;
  auths: IAuth[];
  authList: any;
  showloader = true;
  successful_result = false;
  error_result = false;
  dropdownList = [];
  dropdownSettings: {};
  form: FormGroup;
  token: any;
  title: string;
  submitted = false;
  fullAccess: any;
  post_results: string;
  userData: any;
  private subscription: Subscription;
  private timer: Observable<any>;

  AuthCtrl: FormControl;
  IssCtrl: FormControl;

  constructor(
    private http: HttpClient,
    private authKeySer: AuthKeyService,
    private authSer: AuthService,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private userSer: UserService,
    private actRoute: ActivatedRoute,
    private router: Router ) {

      this.showloader = true;
  }

  ngOnInit() {
    this.showloader = true;
    this.userData = this.userSer.getUserData();

    if (typeof(this.partner_id) === 'undefined') {
      this.partner_id = null;
    }

    this.AuthCtrl = new FormControl('Auth', [Validators.required, Validators.minLength(2)]);
    this.IssCtrl = new FormControl('Issuer');

    this.form = this.fb.group({
      Auth: this.AuthCtrl,
      Iss: this.IssCtrl,
    });

    if (this.authkey_id === undefined || this.authkey_id === 0) {
      this.authkey_id = 0;
      this.title = 'New SSO Auth Key';
      this.form.patchValue({
        Auth: '',
        Iss: ''
      });
      this.showloader = false;

    } else {
      this.title = 'Edit Auth Key';
      this.authKeySer.getAuthKey(this.authkey_id).subscribe((rpData: any) => {
        this.form.patchValue({
          Auth: rpData.Auth,
          Iss: rpData.Iss,
        });
        this.showloader = false;
      });
    }

    this.token = localStorage.getItem('token');
  }

  saveAuthKey() {
    // this.submitted = true;
    this.showloader = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showloader = false;
      return;
    }

    if (this.form.valid) {
      const fd = new FormData();

      fd.append('Auth', this.form.value.Auth);
      fd.append('Iss', this.form.value.Iss);
      fd.append('PartnerId', this.partner_id.toString() );

      const that = this;
      if (this.authkey_id === 0) {
         this.authKeySer.createAuthKey(fd).subscribe(
          {
            next(res: any) {
              if (res.status) {
                that.successful_result = true;
              } else {
                that.error_result = true;
                console.log('unable to save');
              }
              that.setTimer();
            },
            error(msg) {
              that.ngZone.run( () => {
                alert( 'Error saving record. Most likely the Auth Key is a duplicate of an existing record' );
              });
            }
        });
      } else {
        this.authKeySer.saveAuthKey(this.authkey_id, fd ).subscribe( {
          next(res: any) {
            if (res) {
               that.successful_result = true;
            } else {
              that.error_result = true;
              alert('Error saving record. Likely using the same Auth?');
              console.log('unable to save');
            }
            that.setTimer();
          },
          error(msg) {
            that.ngZone.run( () => {
              alert( 'Error saving record. Most likely the Auth Key Auth field is a duplicate of an existing record' );
              alert( msg );
            });
          }
        });
      }
      this.showloader = false;
      this.submitted = false;
    }
  }

  public setTimer() {
    this.timer = Observable.timer(1500);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
        if (this.authkey_id === 0) {
          this.form.patchValue({
            Iss: '',
            Auth: ''
          });
        }
        this.submit_return.emit( true );
    });
  }

}
