import {Component, OnInit, AfterViewInit, AfterViewChecked, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError, from } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { IPost } from '../../model/post';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageService } from '../../services/page.service';
import { DocumentService } from '../../services/document.service';
import { PostService } from '../../services/post.service';
import { IDBPage } from '../../model/db-page';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isXMLDoc } from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;


@Component({
  selector: 'app-hr-menu',
  templateUrl: './hr-menu.component.html',
  styleUrls: ['./hr-menu.component.scss'],
})

export class HRMenuComponent implements OnInit, AfterViewChecked {
  title: any;
  pages: IDBPage[] = [];
  posts: any[];
  selectedPage: any;
  origSelectedPage: any;
  selectedPost: any = 0;
  calledOnce: any = false;
  showloader: any;
  documents: any;
  menuClass: any;
  menuId: any;

  constructor(
    private pageSer: PageService,
    private postSer: PostService,
    private userSer: UserService,
    private modalService: NgbModal,
    private docSer: DocumentService,
    private actRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone) {
  }

  ngOnInit() {
    const url = this.actRoute.snapshot['_routerState'].url;
    let pageType = 'HR';
    if (url.includes('solution-center')) {
      this.userSer.selectMenu('solution-center');
      this.menuClass = 'solution-center';
      this.menuId = '#sc-';
      this.title = 'HR Solutions Center';
    } else if (url.includes('business-center')) {
      this.userSer.selectMenu('biz-menu');
      this.menuClass = 'business-center';
      pageType = 'PRO';
      this.menuId = '#pro-';
      this.title = 'Business Solutions Center';
    } else {
      this.userSer.selectMenu('erisa-menu');
      this.menuClass = 'erisa-center';
      pageType = 'ERISA';
      this.menuId = '#erisa-';
      this.title = 'ERISA Compliance Center';
    }

    this.showloader = true;
    const userData = localStorage.getItem('UserData');


    this.pageSer.getPages( pageType ).subscribe((pageData: any) => {
      // this.pages = pageData;
      from(pageData).subscribe( (rec: IDBPage) => {
        rec.href = rec.Name.replace(/\s+/g, '').replace(/\&+/g, '');
        this.pages.push( rec );
      });

      this.selectedPage = this.pages[0].ID;
      // as the param is subscribed to the var will change when the route changes
      // but this init is only called the one time
      this.actRoute.params.subscribe( params =>
        this.selectedPage = params['id']
      );
      this.calledOnce = false;
      if (this.selectedPage === 'undefined' || this.selectedPage === undefined) {
        this.selectedPage = this.pages[0].ID;
      }
      this.selectedPage = Number( this.selectedPage );
      this.origSelectedPage = this.selectedPage;

      this.docSer.getDocumentData().subscribe((docData: any) => {
        this.documents = docData;
        this.postSer.getPosts().subscribe((postData: any) => {
          this.posts = postData;
          this.parseJsonFields( );
          this.posts.forEach( x => x.Body = this.sanitizer.bypassSecurityTrustHtml(x.Body) );
          const selPosts = this.posts.filter( x => x.PageId === this.selectedPage);
          if (selPosts.length > 0) {
            this.selectedPost = selPosts[0].ID;
          }
          this.showloader = false;
        });
      });
    });
  }

  ngAfterViewChecked() {
    if (this.origSelectedPage !== this.selectedPage) {
      this.calledOnce = false;
      const selPosts = this.posts.filter( x => x.PageId === this.selectedPage);
      if (selPosts.length > 0) {
        this.selectedPost = selPosts[0].ID;
      }
    }
    if (this.calledOnce) {
      return ;
    }

    const post = $('#post' + this.selectedPost);
    if (post.length > 0) {
      this.origSelectedPage = this.selectedPage;
      this.selectPageObj( 'page' + this.selectedPage )
      this.calledOnce = true;
    }
  }

  parseJsonFields() {
      for (let idx=0; idx < this.posts.length; idx++ ) {
        let obj = this.posts[idx];
        try {
          if (obj.Style === null) {
            obj.Style = 1;
          }
          obj.overviewTable = JSON.parse( obj.Form );
          obj.faqTable = JSON.parse( obj.FAQ );
          obj.trainTable = JSON.parse( obj.Training );
          let preSectionTable = JSON.parse( obj.Resource );
          obj.sectionTable = [];
          if (obj.faqTable === null) {
            obj.faqTable = [];
          }
          if (obj.trainTable === null) {
            obj.trainTable = [];
          }
          if (preSectionTable === null) {
            preSectionTable = [];
          }

          // format consequetive forms sections to be in one
          let lastSect = null;
          for (let sId=0; sId < preSectionTable.length; sId++ ) {
            let sect = preSectionTable[sId];
            if (sect.style === '2') {
               if (lastSect != null) {
                 lastSect.subsects.push( sect );
               } else {
                 sect.subsects = [];
                 sect.subsects.push( sect );
                 obj.sectionTable.push( sect );
                 lastSect = sect;
               }
            } else {
              lastSect = null;
              obj.sectionTable.push( sect );
            }
          }

          for (let fId = 0; fId < obj.faqTable.length; fId++) {
            let faq = obj.faqTable[fId];
            if (faq.answer.length > 180) {
              faq.partialAnswer = faq.answer.substring(0, 180);
              let foundSpace = false;
              let idx = 179;
              while (!foundSpace && idx > 150) {
                const letter = faq.partialAnswer.substr(idx, 1);
                foundSpace = (letter === ' ');
                if (!foundSpace) {
                  faq.partialAnswer = faq.partialAnswer.substring(0, idx );
                }
                --idx;
              }
              faq.continue = true;
            } else {
              faq.partialAnswer = faq.answer;
              faq.continue = false;
            }
          }

        } catch(e) {
          console.log( e );
          obj.overviewTable = [];
          obj.faqTable = [];
        }

      }
  }

  openDoc( docId: any ) {
    docId = parseInt( docId, 10 );
    const dIdx = this.documents.findIndex( x => x.ID === docId );
    if (dIdx > -1) {
      window.open( this.documents[dIdx].Link, '_blank');
    }
  }

  openTrain( pId, tId: any ) {
    tId = parseInt( tId, 10 );
    pId = parseInt( pId, 10 );
    const pIdx = this.posts.findIndex( x => x.ID === pId );
    if (pIdx > -1) {
      const dIdx = this.posts[pIdx].trainTable.findIndex( x => x.id === tId );
      if (dIdx > -1) {
        window.open( this.posts[pIdx].trainTable[dIdx].link, '_blank');
      }
    }
  }

  selectMe( event: any ) {
    const id = $(event.target).attr('data-target');
    this.selectPageObj( id );
  }

  selectPageObj( id: any ) {
    $('.tab').removeClass('selected');
    const upperMenu = '#menu-' + id;
    const toolMenu = this.menuId + id;
    id = '#' + id;
    $(upperMenu).addClass('selected');

    $('#custom-nav').find('.' + this.menuClass).find('.sub-bbtn').removeClass('selected');
    $('#custom-nav').find('.' + this.menuClass).find('.sub-bbtn').addClass('act');
    $('#custom-nav').find(toolMenu).addClass('selected');

    const firstPost = $(id).find('.tab2').first();
    (<any>$('.page-title')).collapse('hide');
    (<any>$(id)).collapse('show');
    if (firstPost) {
      this.selectPostObj( $(firstPost).attr( 'data-target' ) );
    }
  }

  selectPost( event: any ) {
    const id = $(event.target).attr('data-target');
    this.selectPostObj( id );
  }

  selectPostObj( id: any ) {
    $('.tab2').removeClass('selected');
    const menuId = '#menu-' + id;
    id = '#' + id;
    (<any>$('.post')).collapse('hide');
    $(menuId).addClass('selected');

    (<any>$(id)).collapse('show');
  }

  filterPost(post: IPost, pageId: any ) {
    return (post.PageId === pageId );
  }

  openFaq( pId, fId: any ) {
    $('#faq-partial-' + pId + '-' + fId).collapse('hide');
    $('#faq-full-' + pId + '-' + fId).collapse('show');
  }

  closeFaq( pId, fId: any) {
    $('#faq-partial-' + pId + '-' + fId).collapse('show');
    $('#faq-full-' + pId + '-' + fId).collapse('hide');
  }

  openClose( postId, sectId: any ) {
    const id = '#doc-sect-' + postId + '-' + sectId;
    if ($(id).hasClass('show')) {
      $(id).collapse('hide');
    } else {
      $(id).collapse('show');
    }
  }

}


