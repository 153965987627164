import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { IClientService } from '../model/clientservice';
import { rolesStatus} from '../model/roles';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class ClientServiceService {

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }

  getAllClientServices(): Observable<IClientService> {
    return this.http.get(`${environment.apiUrl}clientservice/all-clientservices/` )
       .pipe(map((res: any) => {
         return res.data;
       }));

  }

  getClientServicesByClient( client_id: any): Observable<IClientService> {
    return this.http.get(`${environment.apiUrl}clientservice/by-client/` + client_id )
       .pipe(map((res: any) => {
         return res.data;
       }));

  }

  // //
  // // Get Posts
  // //
  // getAllPosts( parent_id, page, per_page, sorting, filtering): Observable<any> {
  //   let add_url = '';
  //   if (filtering.name !== '') {
  //     add_url = add_url + '&name=' + filtering.name;
  //   }

  //   return this.http.get(`${environment.apiUrl}post/posts/` + '?parent_id=' + parent_id + '&page=' + page
  //                   + '&per_page=' + per_page + '&sort_by=' + sorting.value + '&sort_type='
  //                   + sorting.sort + add_url )
  //      .pipe(map(res => { return res; }));
  // }

  //
  // Creates a new ClientService record
  //
  createClientService( form ) {
    return this.http.post(`${environment.apiUrl}clientservice/`, form);

            // .pipe(
            //   map(response => {
            //     return response;
            //   })
            // )
            // .catch(error => {
            //   console.log(error);
            //   return Observable.throw(error);
            // });


      // .pipe(
      //   map((res: any) => {
      //     if (res.status) {
      //       return res;
      //     }
      //   })
      // );
  }

  //
  // Update a ClientService record
  //
  saveClientService( cs_id, form ) {
    return this.http.post(`${environment.apiUrl}clientservice/` + cs_id, form
    )
    .pipe(
      map((res: any) => {
        if (res.status) {
          return res.status;
        }
      })
    );
  }

  //
  // Retrieve ClientService with given service-id
  //
  getClientService( cs_id ): Observable <IClientService> {
    return this.http.get(`${environment.apiUrl}clientservice/` + cs_id)
        .pipe(
           map((res: any) => {
             if (res.status) {
               return res.data;
             }
           })
        );
  }


  //
  // Delete a new ClientService record
  //
  deleteClientService( id ): Observable <any> {

    return this.http.delete(`${environment.apiUrl}clientservice/` + id)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

}
