export interface IContent {
    ID: number;
    Title: string;
    Type: number;
    EffDate: Date;
    Body: string;
}

export const CONTENT_TYPE: any[] = [
    {type: 1, value: 'Service'},
    {type: 2, value: 'Newsletter'},
    {type: 3, value: 'Blog'},
    {type: 4, value: 'Webinar'},
    {type: 5, value: 'Tips'},
    {type: 6, value: 'News'}
  ];

export const STYLE_TYPE: any[] = [
    {type: 1, value: 'Popup Service'},
    {type: 2, value: 'Link to elsewhere'},
    {type: 3, value: 'Link to elsewhere with Co name in url'}
];