import {Component, OnInit, Input, NgZone, EventEmitter, Output, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { AuthKeyService } from '../../services/authkey.service';
import { IAuthKey } from '../../model/authkey';
import { IPage } from '../../model/page';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';

import * as jquery from 'jquery';
import { state } from '@angular/animations';
declare var $: any;

@Component({
  selector: 'app-authkey-list',
  templateUrl: './authkey-list.component.html',
  styleUrls: ['./authkey-list.component.scss'],
})

export class AuthKeyListComponent implements OnInit {
  @Input() partner_id: number = null;

  @Output() submitted: EventEmitter<boolean> = new EventEmitter<boolean>();


  isFilterTrue = true;
  // userValue: IUserValue;
  paging: IPage;
  totalAuthKeys: number;
  per_page = 10;
  selected_status: number;
  filter: any = { 'name': '', 'role': ''};
  authKeysList: any;
  userData: any;
  total: number = 0;
  public row_header: any = [];
  public header_index: any;
  public assignee_array: any = [];
  private allItems: any[];
  showloader: boolean;
  pager: any = {};
  pagedItems: any[];
  crumbList: any[];
  embedded: any = false;

  constructor(
    private pagerS: PagerService,
    private authKeySer: AuthKeyService,
    private modalService: NgbModal,
    private userSer: UserService,
    private ngZone: NgZone) {
      this.showloader = true;
  }

  ngOnInit() {
    this.userData = this.userSer.getUserData();

    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' }
    ];
    this.selected_status = null;

    this.row_header  = [
      {text: 'Auth', value: 'Client Id/Auth'},
      {text: 'Iss', value: 'Issuer/Iss', 'sort': 'asc' },
      {text: 'Created_at', value: 'Created Date', 'sort': null}
    ];
    this.header_index = 1;

    this.tableSort(0);
    this.showloader = false;
  }

  findLocalAuthKey( id: number ) {
    return this.authKeysList.find( p => p.ID === id);
  }

  deleteAuthKey(id: number) {
    const authkey = this.findLocalAuthKey( id );
    if (authkey === null) {
      alert('Unable to find Role Profile');
      return;
    }
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to delete ' +
    '<span class="text-primary">"' + authkey.Iss + '"</span> record?</strong></p>' +
    '<p>All information associated to this record will be permanently deleted.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'Role Profile Record';
    modalRef.result.then((res: any) => {
      this.authKeySer.deleteAuthkey(authkey.ID).subscribe((data: any) => {
        if (this.embedded) {
          this.showloader = true;
          this.submitted.emit( true );
        } else {
          this.setPage(1);
        }
      });
    })
    .catch( (res: any) => {
    });

  }


  filterByAuth(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.auth = id;
    this.setPage(1);
  }

  filterByIss(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.iss = id;
    this.setPage(1);
  }

  setPage(page: number = 1) {
        const that = this;
        this.showloader = true;
        this.authKeySer
          .getPartnerKeysData( this.partner_id )  // page, this.per_page, this.row_header[this.header_index], this.filter)
          .subscribe( {
              next(data: any) {
                that.showloader = false;
                that.authKeysList = data; // return athe all data
                console.log( data );
                // that.paging = ; // return all the paginationinfo
                that.total = (that.authKeysList ? that.authKeysList.length : 0); // that.paging.total;
                that.pager = that.pagerS.getPager(that.total, page, that.per_page);
          },
          error(msg) {
            that.ngZone.run( () => {
              that.showloader = false;
            });
          }
        });
  }

  tableSort(index) {
    this.showloader = true;
    if (this.header_index === index) {
      (this.row_header[index].sort === 'asc') ? this.row_header[index].sort = 'desc' : this.row_header[index].sort = 'asc';
    } else {
      this.row_header[index].sort = 'asc';
      this.row_header[this.header_index].sort = null;
      this.header_index = index;
    }
    this.setPage(1);
  }


  // embedded functions
  expandTable() {
    $('#newAuthKey').addClass('show');
  }

  swapIcon(event: any) {
    const expanded = $('#authkey-expand-alt').hasClass('fa-expand-alt');
    console.log('in swap' + expanded);
    if (expanded) {
      $('#authkey-expand').removeClass('fa-expand-alt').addClass('fa-compress-alt');
    } else {
      $('#authkey-expand').removeClass('fa-compress-alt').addClass('fa-expand-alt');
    }
    // window.scroll({
    //   top: event.pageY + 100,
    //   behavior: 'smooth'
    // });
  }

  collapseAuthKey() {
    $('.authkey_edit.collapse.show').removeClass('show');
    $('#newAuthKey.show').removeClass('show');

    // need to push event upstream so the whole page can get refreshed
    if (this.embedded) {
       this.submitted.emit( true );
    } else {
      this.setPage(1);
    }
  }

  scrollView(event: any, id: any) {
    // window.scroll({
    //   top: event.pageY + 100,
    //   behavior: 'smooth'
    // });
  }

}


