import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { IClients } from '../model/clients';
import { IUser } from '../model/user';
import { rolesStatus} from '../model/roles';
import { MessageService } from './message.service';

export class ServiceConstants {
  public static ASK: any = 'ASK HR';
  public static ASKPRO: any = 'ASK Pro';
  public static FMLA: any = 'FMLA';
  public static HANDBOOK: any = 'Handbook';
  public static ERISA_WRAP: any = 'Wrap';
  public static ERISA_COMPLETE: any = 'Complete';
}


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userApi = 'user/';

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }

  isAgentOrGreater( userType: any) {
    const userData = this.getUserData();
    return userData.RoleType === 6 || userData.RoleType === 8;
    // return (userType == 6 || userType == 8);
  }

  isAdmin() {
    const userData = this.getUserData();
    return userData.RoleType === 8;
    // return (userType == 8 );
  }
  isPartner() {
    const userData = this.getUserData();
    return userData.RoleType === 3;
  }
  isAgent() {
    const userData = this.getUserData();
    return userData.RoleType === 6;
  }
  isClient() {
    const userData = this.getUserData();
    return userData.RoleType === 2;
  }


  isClientAdminOrLess( userType: any ) {
    const userData = this.getUserData();
    return (userData.RoleType === 2);
    // return (userType == 3 || userType == 4 || userType == 5 );
  }

  isCrazyListForSearch( userType: any ) {
    const userData = this.getUserData();
    return (userData.RoleType === 6 || userData.RoleType === 8);
    // return (userType === 1 || userType === 6 || 
    //         userType === 7 || userType === 8);
  }

  isCrazy2ListForFilter( userType: any ) {
    return true;
    // return (userType === 3 || userType === 4 || 
    //         userType === 1 || userType === 6 || 
    //         userType === 7 || userType === 8);    
  }

  profileIsOne( userData: any, key: any ) {
    if (!userData.roleprofile) {
      return false;
    }
    if (userData.roleprofile.FullAccess === 1) {
      return true;
    }
    if (!userData.roleprofile.AuthList) {
      return false;
    }
    const profiles = JSON.parse( userData.roleprofile.AuthList );
    const pro = profiles.find( x => x.Description === key );
    if (pro) {
      return pro.Status === '1';
    }
    return false;
  }

  getMultiClientStatus() {
    const info = JSON.parse( localStorage.getItem('info'));
    return (info.multi_client != null);
  }

  getMultiClientData() {
    const info = JSON.parse( localStorage.getItem('info'));
    return info.multi_client;
  }

  getUserData() {
    const userJson = localStorage.getItem('UserData');
    if (!userJson) {
       return false;
    }

    const userData = JSON.parse( userJson );
    return userData;
  }

  getClientName() {
    const userData = this.getUserData();
    if (userData.client) {
      return userData.client.ClientName;
    }
    return '';
  }

  getClientId() {
    const userData = this.getUserData();
    if (userData.client) {
      return userData.client.ID;
    }
    return '';
  }

  getPartnerId() {
    const userData = this.getUserData();
    if (userData.client) {
      return userData.client.PartnerID;
    }
    return null;
  }

  haveAccess( feature: any, isRoleFeature: any ) {
    const userData = this.getUserData();
    if (!userData || !userData.roleprofile) {
      return false;
    }

    if (isRoleFeature) {
      return this.profileIsOne( userData, feature );
    }

    switch (feature) {
       case 'admin-menu': {
         if (userData.RoleType === 3 || userData.RoleType === 8) {
           return true;
         }
         break;
       }

       case 'client': {
          if (userData.RoleType === 3) {
             if (userData.roleprofile.FullAccess) {
               return true;
             }
             return this.profileIsOne(userData, 'Manage Client Setup');
          } else if (userData.RoleType === 8) {
            return true;
          }
          return false;
          break;
       }

       case 'partner':
       case 'user':
       case 'hr-setup':
       case 'business-setup':
       case 'email-templates':
       case 'template-list':
       case 'template':
       case 'states':
       case 'content':
       case 'states':
       case 'document':
       case 'erisa-setup':
       case 'talentlms':
       case 'roleprofile':  {
          if (userData.RoleType === 8) {
              return true;
          }
          break;
       }

       case 'partner-profile': {
          if (userData.RoleType === 3) {
            return this.profileIsOne(userData, 'Manage Partner Profile');
          }
          break;
        }

        case 'client-profile': {
          if (userData.RoleType === 2) {
            return this.profileIsOne(userData, 'Manage Client Profile');
          }
          break;
        }

        case 'user-profile': {
          if (userData.RoleType === 8) {
            return false;
          }
          return true;
          break;
        }


    }

    return false;
  }

  haveService( service: any ) {
    const userData = this.getUserData();
    if (!userData || !userData.client || !userData.client.ClientServices) {
      return false;
    }

    const services = JSON.parse( userData.client.ClientServices );

    const servs = services.filter( x => x.Code.indexOf( service ) >= 0 );
    if (servs.length > 0) {
      let serv: any;
      let effDate: any;
      let expDate: any;

      serv = servs[servs.length - 1];
      if (serv.EffectiveDate) {
        effDate = new Date( serv.EffectiveDate );
      }

      if (serv.ExpirationDate) {
        expDate = new Date( serv.ExpirationDate );
      }

      if (expDate && expDate < Date.now() ){
        return false;
      }

      if (effDate && effDate < Date.now()) {
        return true;
      }
    }


    return false;
  }

  getUserId() {
    const userData = this.getUserData();
    return userData.UserID;
  }

  selectMenu( ele: any ) {
    (<any>$('#custom-nav')).find('a').removeClass('selected');
    (<any>$('#custom-nav')).find('#' + ele).addClass('selected');
  }


  //
  // use jwt to look up user and return User record
  //
  getLoggedInUser() {
    return this.http.get(`${environment.apiUrl}user/user-by-jwt/`)
       .pipe(map(res => { return res; }))
       .subscribe((data: any) => {
          if (data.status) {
             localStorage.setItem('ClientID', data.data.ClientID);
             localStorage.setItem('UserData', JSON.stringify(data.data));
             this.router.navigate(['/dashboard']);
          }
       }
    );
  }

  /**
   * Get Users
   */
  getAllUsers( page, per_page, sorting, filtering): Observable<any> {
    let add_url = '';
    if (filtering.clientname !== '') {
      add_url = add_url + '&clientname=' + filtering.clientname;
    }
    if (filtering.search !== '') {
      add_url = add_url + '&name=' + filtering.name;
    }
    if (filtering.email !== '') {
      add_url = add_url + '&email=' + filtering.email;
    }
    if (typeof(filtering.client_id) !== 'undefined' && filtering.client_id !== null) {
      add_url = add_url + '&client_id=' + filtering.client_id;
    }
    if (typeof(filtering.partner_id) !== 'undefined' && filtering.partner_id !== null) {
      add_url = add_url + '&partner_id=' + filtering.partner_id;
    }

    return this.http.get(`${environment.apiUrl}user/users/` + '?page=' + page
                    + '&per_page=' + per_page +'&sort_by=' + sorting.value + '&sort_type='
                    + sorting.sort + add_url )
       .pipe(map(res => { return res; }));
  }

  //
  // Get all users for a given client id
  //
  getUsersByClient(id): Observable<IUser> {
    return this.http.get(`${environment.apiUrl}user/user-by-client/` + id)
    .pipe(
      map((res: any) => {
        if (res.status) {
          return res.data;
        }
      })
    );
  }


  // returns other agents to cc
  getAgents(): Observable <IUser> {

    return this.http.get(`${environment.apiUrl}user/agents/`)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res.data;
          }
        })
      );
  }

  // 
  // Creates a new User record
  //
  createUser( form ) {

    return this.http.post(`${environment.apiUrl}user/`, form)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );    
  }

  // 
  // Creates a new User record
  //
  saveUser( user_id, form ) {
    return this.http.post(`${environment.apiUrl}user/` + user_id, form
//      ,{ headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
                        // { headers: new HttpHeaders( {
                        //   'accept': 'application/json',
                        //   'Authorization': `Bearer` +  token
                        //   })
                        // }      
    )
    .pipe(
      map((res: any) => {
        if (res.status) {
          return res.status;
        }
      })
    );
  }
  
  // 
  // Retrieve User with given user-id
  //
  getUser( user_id ): Observable <IUser> {
    return this.http.get(`${environment.apiUrl}user/`+ user_id)
        .pipe(
           map((res: any) => {
             if (res.status) {
               return res.data;
             }
           })
        );
  }


  // 
  // Delete a new User record
  //
  deleteUser( id ): Observable <IUser> {

    return this.http.delete(`${environment.apiUrl}user/` + id)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );  
  }

  //
  // Upload and import csv file
  //
  uploadUsers(file: any, overwrite: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'user/users-import-csv/?' + 'overwrite=' + overwrite, file )
           .pipe( map((resp: any) => {
                       return resp;
    }));
  }


  //
  // Get Client Download
  //
  getUserDownload( sorting, filtering): Observable<ArrayBuffer> {
    let add_url = '';

    if (filtering.clientname !== '') {
      add_url = add_url + '&clientname=' + filtering.clientname;
    }
    if (filtering.search !== '') {
      add_url = add_url + '&name=' + filtering.name;
    }
    if (filtering.email !== '') {
      add_url = add_url + '&email=' + filtering.email;
    }
    if (typeof(filtering.client_id) !== 'undefined' && filtering.client_id !== null) {
      add_url = add_url + '&client_id=' + filtering.client_id;
    }
    if (typeof(filtering.partner_id) !== 'undefined' && filtering.partner_id !== null) {
      add_url = add_url + '&partner_id=' + filtering.partner_id;
    }


    let headers = new HttpHeaders();

    const options: {
        headers?: HttpHeaders;
        observe?: 'body';
        reportProgress?: boolean;
        responseType: 'arraybuffer';
        withCredentials?: boolean;
    } = {
        responseType: 'arraybuffer'
    };

    return this.http.get(`${environment.apiUrl}user/users-csv/` + '?sort_by=' + sorting.value +
           '&sort_type=' + sorting.sort + add_url, options )
       .pipe( map((file: ArrayBuffer) => {
          return file;
       }));
  }


}
