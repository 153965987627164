import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { RoutingModule } from './routing.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { UserDefaultModule } from './user-default/user-default.module';
import { AdminBaseModule } from './admin/admin-base.module';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { Ng2CompleterModule } from 'ng2-completer';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { AuthInterceptor} from './auth/auth-interceptor';
// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'node_modules/froala-editor/js/froala_editor.pkgd.min.js';
import { FroalaEditorModule } from 'angular-froala-wysiwyg/editor/editor.module.js';
import { FroalaViewModule } from 'angular-froala-wysiwyg/view/view.module.js';

import * as $ from 'jquery';
import { FileUploadDirective } from './directives/file-upload.directive';
import { RouterModule } from '@angular/router';


// import pagination component
import { JwPaginationComponent } from 'jw-angular-pagination';
import { MyDatePickerModule } from 'mydatepicker';
import { ModalComponent } from './shared/modal.component';
import { InfoModalComponent } from './shared/info-modal.component';

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    AppComponent,
    FileUploadDirective,
    JwPaginationComponent,
    ModalComponent,
    InfoModalComponent
  ],
  imports: [
    RoutingModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    AuthModule,
    SharedModule,
    CoreModule,
    UserDefaultModule,
    AdminBaseModule,
    HttpClientModule,
    MyDatePickerModule,
    Ng2CompleterModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    DataTablesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  entryComponents: [ModalComponent, InfoModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule {

}
