import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from '../shared/shared.module';
import { CoreModule} from '../core/core.module';
import { Routes, RouterModule, CanActivate  } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { MyDatePickerModule } from 'mydatepicker';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { UserComponent } from './user/user.component';
import { AdminBaseComponent } from './admin-base.component';
import { UserListComponent } from './user-list/user-list.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientComponent } from './client/client.component';
import { PartnerListComponent } from './partner-list/partner-list.component';
import { PartnerComponent } from './partner/partner.component';
import { PageComponent } from './page/page.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { PageListComponent } from './page-list/page-list.component';
import { PostListComponent } from './post-list/post-list.component';
import { PostComponent } from './post/post.component';
import { ServicesListComponent } from './services-list/services-list.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { TemplateComponent } from './template/template.component';
import { RoleProfileListComponent } from './roleprofile-list/roleprofile-list.component';
import { RoleProfileComponent } from './roleprofile/roleprofile.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { StatesListComponent } from './states-list/states-list.component';
import { StatesComponent } from './states/states.component';
import { ContentListComponent } from './content-list/content-list.component';
import { ContentComponent } from './content/content.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentComponent } from './document/document.component';
import { AuthKeyListComponent } from './authkey-list/authkey-list.component';
import { AuthKeyComponent } from './authkey/authkey.component';
import { TalentComponent } from './talent/talent.component';

//
// Admin Routing
//
const admin_routing: Routes = [
  {path : 'admins', component : AdminBaseComponent,  canActivateChild: [AuthGuard],
   data: {
     expectedRoles: [3, 8]
   },
   children: [
     {path: 'admin-menu', component: AdminMenuComponent},
     {path: 'authkey-list', component: AuthKeyListComponent },
     {path: 'authkey', component: AuthKeyComponent },
     {path: 'authkey/:id', component: AuthKeyComponent },
     {path: 'user-list', component: UserListComponent},
     {path: 'user', component: UserComponent},
     {path: 'user/:id', component: UserComponent},
     {path: 'client-list', component: ClientListComponent},
     {path: 'client', component: ClientComponent},
     {path: 'client/:id', component: ClientComponent},
     {path: 'content', component: ContentComponent},
     {path: 'content/:id', component: ContentComponent},
     {path: 'content-list', component: ContentListComponent},
     {path: 'document', component: DocumentComponent},
     {path: 'document/:id', component: DocumentComponent},
     {path: 'document-list', component: DocumentListComponent},
     {path: 'partner-list', component: PartnerListComponent},
     {path: 'partner', component: PartnerComponent},
     {path: 'partner/:id', component: PartnerComponent},
     {path: 'page-list', component: PageListComponent},
     {path: 'page-list/HR', component: PageListComponent},
     {path: 'page-list/PRO', component: PageListComponent},
     {path: 'page-list/ERISA', component: PageListComponent},
     {path: 'page', component: PageComponent},
     {path: 'page', component: PageComponent},
     {path: 'page', component: PageComponent},
     {path: 'page/:id', component: PageComponent},
     {path: 'roleprofile-list', component: RoleProfileListComponent },
     {path: 'roleprofile', component: RoleProfileComponent },
     {path: 'roleprofile/:id', component: RoleProfileComponent },
     {path: 'talent', component: TalentComponent},
     {path: 'template-list', component: TemplateListComponent},
     {path: 'template/:name', component: TemplateComponent },
     {path: 'states-list', component: StatesListComponent },
     {path: 'states', component: StatesComponent },
     {path: 'states/:id', component: StatesComponent },
    //  {path: 'states', component: RoleProfileComponent },
     {path: '**', redirectTo: 'user-list', pathMatch: 'full' }
   ]
 }
];


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    MyDatePickerModule,
    ClickOutsideModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    RouterModule.forChild(admin_routing),
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
    RouterModule,
    MyDatePickerModule
  ],
  declarations: [
    AdminBaseComponent,
    AuthKeyListComponent,
    AuthKeyComponent,
    UserComponent,
    UserListComponent,
    ClientListComponent,
    ClientComponent,
    ContentComponent,
    ContentListComponent,
    DocumentComponent,
    DocumentListComponent,
    PartnerListComponent,
    PartnerComponent,
    AdminMenuComponent,
    PageListComponent,
    PageComponent,
    PostListComponent,
    PostComponent,
    RoleProfileListComponent,
    RoleProfileComponent,
    ServicesListComponent,
    StatesListComponent,
    StatesComponent,
    TalentComponent,
    TemplateListComponent,
    TemplateComponent
  ]
})
export class AdminBaseModule { }
