import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { IRoleProfile } from '../model/roleprofile';
import { rolesStatus} from '../model/roles';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class RoleProfileService {

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }

  getRoleProfiles(): Observable<IRoleProfile> {
    return this.http.get(`${environment.apiUrl}roleprofile/all-roleprofiles/` )
       .pipe(map((res: any) => {
         return res.data;
       }));

  }

  //
  // Get RoleProfiles
  //
  getAllRoleProfiles( page, per_page, sorting, filtering): Observable<any> {
    let add_url = '';
    if (filtering.name !== '') {
      add_url = add_url + '&name=' + filtering.name;
    }
    if (typeof(filtering.client_id) !== 'undefined' && filtering.client_id !== null) {
       add_url = add_url + '&client_id=' + filtering.client_id;
    }
    if (typeof(filtering.partner_id) !== 'undefined' && filtering.partner_id !== null) {
      add_url = add_url + '&partner_id=' + filtering.partner_id;
   }

    return this.http.get(`${environment.apiUrl}roleprofile/roleprofiles/` + '?page=' + page
                    + '&per_page=' + per_page + '&sort_by=' + sorting.value + '&sort_type='
                    + sorting.sort + add_url )
       .pipe(map(res => { return res; }));
  }

  //
  // Creates a new RoleProfile record
  //
  createRoleProfile( form ) {

    return this.http.post(`${environment.apiUrl}roleprofile/`, form)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

  //
  // Update a RoleProfile record
  //
  saveRoleProfile( id, form ) {
    return this.http.post(`${environment.apiUrl}roleprofile/` + id, form
    )
    .pipe(
      map((res: any) => {
        if (res.status) {
          return res.status;
        }
      })
    );
  }

  //
  // Retrieve Roleprofile with given id
  //
  getRoleProfile( id ): Observable <IRoleProfile> {
    return this.http.get(`${environment.apiUrl}roleprofile/` + id)
        .pipe(
           map((res: any) => {
             if (res.status) {
               return res.data;
             }
           })
        );
  }


  //
  // Delete a new RoleProfile record
  //
  deleteRoleProfile( id ): Observable <any> {

    return this.http.delete(`${environment.apiUrl}roleprofile/` + id)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

}
