import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { CodeService } from '../../services/code.service';

import { IStates } from '../../model/states';
import { StatesService } from '../../services/states.service';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { ModalComponent } from '../../shared/modal.component';
import { InfoModalComponent } from '../../shared/info-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as jquery from 'jquery';
import { state } from '@angular/animations';
import { ClickOutsideDirective } from 'ng-multiselect-dropdown/click-outside.directive';

declare var $: any;

@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss']
})
export class StatesComponent implements OnInit, AfterViewInit {
  state: IStates;
  states: any;
  stateCodes: any[];
  id: any;
  active: any;
  showloader = true;
  successful_result = false;
  error_result = false;
  dropdownList = [];
  dropdownSettings: {};
  form: FormGroup;
  title: string;
  submitted = false;
  post_results: string;
  private subscription: Subscription;
  private timer: Observable<any>;
  crumbList: any[];
  updateList: any[];
  userData: any;
  // editorLaw: any;
  // editorWhatToDo: any;
  public options: Object = {
    key: 'eC6D6C2E3E2H2xC4B3A3C2B5A1B1E4F1A2sd1Te1LXe1URVJe1DWXG==',
    // key: 'dC10B5D5A2A-9H3E2J2A4C6C3C2B5B1E1pj1wgbrD-11fzfsB-22B3zuv==',
    // key: 'eHE5C-11B2C1F2I2A5C4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4D3E4C2G2E3C1B2==',
    attribution: false,
    charCounterCount: false,
    height: 170
  };

  StateCtrl: FormControl;
  NewUpdatesCtrl: FormControl;
  OverviewCtrl: FormControl;

  constructor(
    private http: HttpClient,
    private userSer: UserService,
    private statesSer: StatesService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private infoModalService: NgbModal,
    private actRoute: ActivatedRoute,
    private codeSer: CodeService,
    private ngZone: NgZone,
    private router: Router ) {

      this.showloader = true;
  }

  ngOnInit() {
    this.showloader = true;

    this.userData = this.userSer.getUserData();
    if (!this.userData || !this.userData.roleprofile) {
      this.router.navigate(['/dashboard']);
    }

    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' },
      { title: 'State Update List', url: '/admins/states-list' }
    ];

    this.StateCtrl = new FormControl('State', [Validators.required]);
    this.NewUpdatesCtrl = new FormControl('NewUpdates', [Validators.required]);
    this.OverviewCtrl = new FormControl('Overview', [Validators.required]);

    this.form = this.fb.group({
      State: this.StateCtrl,
      NewUpdates: this.NewUpdatesCtrl,
      Overview: this.OverviewCtrl
    }, { updateOn: 'submit' });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'Code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    this.actRoute.params.subscribe( params =>
      this.id = parseInt( params['id'], 10 )
    );

    if (this.id === undefined || this.id === 0 || isNaN(this.id)) {
      this.id = 0;
      this.title = 'New State';
      this.form.patchValue({
        State: '',
        NewUpdates: 0,
        Overview: ''
      });
      this.updateList = [];
    }

    // this.editorLaw = new FroalaEditor('#law',
    //   this.options
    // );
    // this.editorWhatToDo = new FroalaEditor('#whatToDo',
    //   this.options
    // );


    this.codeSer.getCodeByType('STATE').subscribe( (data: any ) => {
      this.stateCodes = data;

      if (this.id > 0) {
        this.statesSer.getStates( this.id ).subscribe((stateData: any) => {
          this.state = stateData;
          this.title = 'Edit State';

          this.form.patchValue({
            State: stateData.State,
            NewUpdates: stateData.NewUpdates,
            Overview: stateData.Overview
          });
          this.parseUpdates( stateData );
          this.showloader = false;
        });
      } else {
        this.showloader = false;
      }
    });

  }

  ngAfterViewInit() {
  }

  parseUpdates( stateData ) {
    try {
       this.updateList = JSON.parse( stateData.UpdateList );
    } catch (e) {
       this.updateList = [];
    }
    if (this.updateList !== null && this.updateList !== undefined) {
      for (var i = 0; i < this.updateList.length; i++ ) {
        let rec = this.updateList[i];
        rec.ID = i + 1;
      }
    } else {
      this.updateList = [];
    }
  }

  openMe( id: any ) {
    const obj = this.updateList.find( x => x.ID === id );
    if (!obj) {
      this.displayMessage('Unable to locate Update record');
      console.log( typeof(id) );
      console.log( this.updateList[0].ID );
    }

    $('#ID').val( id );
    $('#Title').val( obj.Title );
    $('#EffectiveDate').val( obj.EffectiveDate );
    // this.editorLaw.html.set( obj.Law );
    // this.editorWhatToDo.html.set( obj.WhatToDo );
    $('#Law').froalaEditor( 'html.set', obj.Law );
    $('#WhatToDo').froalaEditor('html.set', obj.WhatToDo );
    console.log( obj.WhatToDo );
  }


  saveState() {
    // this.submitted = true;
    this.showloader = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showloader = false;

      return;
    }

    if (this.form.valid) {
      const fd = new FormData();

      fd.append('State', this.form.value.State);
      fd.append('NewUpdates', this.form.value.NewUpdates);
      fd.append('Overview', this.form.value.Overview);
      fd.append('UpdateList', JSON.stringify( this.updateList ));

      const that = this;
      if (this.id === 0) {
         this.statesSer.createStates(fd).subscribe(
           {

            next(res: any) {
              if (res.status) {
                that.successful_result = true;
                that.id = res.data.ID;
                that.showloader = false;
                that.setTimer();
              } else {
                that.error_result = true;
                console.log('unable to save');
              }
              that.setTimer();
            },
            error(msg) {
              that.ngZone.run( () => {
                that.showloader = false;
                that.displayMessage( 'Error saving record. Most likely the State is a duplicate of an existing record' );
              });
            }
          });

      } else {
        this.statesSer.saveStates(this.id, fd ).subscribe((res: any) => {
          if (res) {
             this.successful_result = true;
          } else {
            this.error_result = true;
            this.showloader = false;
            console.log('unable to save');
          }
          this.setTimer();
        });
      }
      this.submitted = false;
    }
  }

  public setTimer() {
    this.timer = Observable.timer(2500);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
        this.showloader = false;
      });
  }

  toggleChange(event: any) {
    this.active = (event.target.checked ? 'Yes' : 'No');
  }

  addUpdate() {
    const rowId = '#statesDiv';
    $(rowId).collapse( !$(rowId).hasClass('show') );
    $('#ID').val( 0 );
    $('#Title').val( '' );
    $('#EffectiveDate').val('');
    $('#Law').froalaEditor('html.set', '' );
    $('#WhatToDo').froalaEditor('html.set', '' );
    // this.editorLaw.html.set('');
    // this.editorWhatToDo.html.set('');
  }

  swapIcon( event: any ) {
    const ele = event.target;
    const expanded = $(ele).hasClass('fa-expand');
    if (expanded) {
      $(ele).removeClass('fa-expand').addClass('fa-compress');
    } else {
      $(ele).removeClass('fa-compress').addClass('fa-expand');
    }
  }

  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Warning';
    modalRef.result.then((res: any) => {
    });

  }

  // checkForAutoSave() {
  //   let newState = $('#newState').val();
  //   newState = parseInt( newState, 10 );
  //   const newStateCount = $('#newStateCount').val();
  //   if (newState > 0 && newStateCount > 0) {
  //     const stateName = $('#newState option:selected').text().trim();
  //     const title = $('#newStateTitle').val();
  //     const obj = this.findUpdate( stateName );
  //     if (obj === null) {
  //       this.updateList.push( { ID: this.updateList.length,
  //             state: stateName,
  //             title: title
  //       });
  //       this.clearState();
  //     } else {
  //       this.displayMessage( 'Duplicate Update. Please edit existing record.' );
  //     }
  //   }
  // }


  today() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  }


  findUpdate( id: any ) {
    if (!this.updateList) {
      return null;
    }

    for (var idx = 0; idx < this.updateList.length; ++idx) {
      const obj = this.updateList[idx];
      if (obj.ID === id) {
        return idx;
      }
    }

    return null;
  }


  clearState() {
    $('#newState').val('');
    $('#newStateCount').val('');
    $('#newStateRow').collapse('hide');
  }

  // deleteState( id: any ) {
  //   const stateRow = $('#state' + id);
  //   if (stateRow.length === 0) {
  //     alert('Unable to find State');
  //     return;
  //   }
  //   const stateName = $('#state' + id + ' option:selected').text().trim();

  //   const modalRef = this.modalService.open( ModalComponent );
  //   modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to remove ' +
  //   '<span class="text-primary">"' + stateName + '"</span> record?</strong></p>' +
  //   '<p>All information associated to this State will be permanently deleted.' +
  //   '<span class="text-danger"> This operation can not be undone.</span>' +
  //   '</p>';
  //   modalRef.componentInstance.title = 'State Record';
  //   modalRef.result.then((res: any) => {
  //     const idx = this.findUpdate( id );
  //     this.updateList.slice( idx, 1 );
  //   })
  //   .catch( (res: any) => {
  //   });

  // }


  saveUpdate() {
    let id = $('#ID').val();
    const effDate = $('#EffectiveDate');
    const title = $('#Title');
    const law = $('#Law');
    const whattodo = $('#WhatToDo');
    if (typeof(id) === 'string') {
      id = parseInt(id, 10);
    }
    if (id === 0) {
      const newRec: any = {};
      newRec.ID = this.updateList.length + 1;
      newRec.Title = title.val();
      newRec.Law = law.froalaEditor('html.get');
      newRec.WhatToDo = whattodo.froalaEditor('html.get');
      // newRec.Law = this.editorLaw.html.get();
      // newRec.WhatToDo = this.editorWhatToDo.html.get();
      newRec.EffectiveDate = effDate.val();
      this.updateList.push( newRec );
    } else {
      const rec = this.updateList.find( x => x.ID === id );
      if (rec === undefined) {
        this.displayMessage('Unable to locate existing record. Please contact the Administrator');
        return;
      }
      rec.Title = title.val();
      rec.Law = law.froalaEditor('html.get');
      rec.WhatToDo = whattodo.froalaEditor('html.get');
      // rec.Law = this.editorLaw.html.get();
      // rec.WhatToDo = this.editorWhatToDo.get();
      rec.EffectiveDate = effDate.val();
    }
    this.saveState();
  }

  deleteUpdate( id: any ) {
    if (typeof(id) === 'string') {
      id = parseInt( id, 10 );
    }
    const obj = this.updateList.find( x => x.ID === id );
    if (obj === undefined) {
      this.displayMessage('Unable to locate State Update record');
    }

    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to remove ' +
    '<span class="text-primary">"' + obj.Title + '"</span> record?</strong></p>' +
    '<p>All information associated to this State Update will be permanently deleted.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'State Update Record';
    modalRef.result.then((res: any) => {
      const idx = this.findUpdate( id );
      console.log( idx );
      if (idx) {
         this.updateList.splice( idx, 1 );
         this.saveState();
      }
    })
    .catch( (res: any) => {
    });

  }






}

