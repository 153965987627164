import { Component, OnInit,EventEmitter, Input, Output  } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() page: number; // the current page
  @Input() total: number; // how many total items there are in all pages
  @Input() per_page: number; // how many items we want to show per page
  @Input() total_pages: number; // how many pages between next/prev
  @Input() loading: boolean; // check if content is being loaded


  @Output() goPrev = new EventEmitter<boolean>();
  @Output() goNext = new EventEmitter<boolean>();
  @Output() goPage = new EventEmitter<number>();

  constructor() {}

  
  getMin(): number {
    return ((this.per_page * this.page) - this.per_page) + 1;
  }

  getMax(): number {
    let max = this.per_page * this.page;
    if (max > this.total) {
      max = this.total;
    }
    return max;
  }

  onPage(n: number): void {
    this.goPage.emit(n);
  }

  onPrev(): void {
    this.goPrev.emit(true);
  }

  onNext(next: boolean): void {
    this.goNext.emit(next);
  }

  totalPages(): number {
    return Math.ceil(this.total / this.per_page) || 0;
  }

  lastPage(): boolean {
    return this.per_page * this.page > this.total;
  }

  getPages(): number[] {
    const c = Math.ceil(this.total / this.per_page);
    const p = this.page || 1;
    const pagesToShow = this.total_pages || 9;
    const pages: number[] = [];
    pages.push(p);
    const times = pagesToShow - 1;
    for (let i = 0; i < times; i++) {
      if (pages.length < pagesToShow) {
        if (Math.min.apply(null, pages) > 1) {
          pages.push(Math.min.apply(null, pages) - 1);
        }
      }
      if (pages.length < pagesToShow) {
        if (Math.max.apply(null, pages) < c) {
          pages.push(Math.max.apply(null, pages) + 1);
        }
      }
    }
    pages.sort((a, b) => a - b);
    return pages;
  }


  ngOnInit(){
    
  }
}
