import {Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError, from } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { mergeMap, map, switchMap, debounceTime, catchError, filter } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as jquery from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentService } from 'src/app/services/content.service';

declare var $: any;

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})

export class ContentComponent implements OnInit {
  buttonSelected: any;
  id: any;
  currentUser: any;
  gotData: any = false;
  afterContent: any = false;
  crumbList: any[];
  contentRec: any;
  contentList: any[];
  contentType: any = '';
  contentUrl: any = '';


  constructor(
    private actRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private contentSer: ContentService,
    private ngZone: NgZone) {
  }

  ngOnInit() {
    const url = this.actRoute.snapshot['_routerState'].url;
    if (url.includes('newsletter')) {
      this.contentType = 'Newsletter';
      this.contentUrl = 'newsletter';
    } else if (url.includes('webinar')) {
      this.contentType = 'Webinar';
      this.contentUrl = 'webinar';
    } else if (url.includes('tip')) {
      this.contentType = 'HR Tips & Tools';
      this.contentUrl = 'tip';
    } else if (url.includes('blog')) {
      this.contentType = 'Blog';
      this.contentUrl = 'blog';
    } else if (url.includes('new')) {
      this.contentType = 'News';
      this.contentUrl = 'news';
    }
    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: this.contentType + ' List', url: '/content/' + this.contentUrl + '-list/' }
    ];


    this.actRoute.params.subscribe( params =>
      this.id = params['id']
    );
    console.log( this.id );

    this.contentSer.getContent( this.id ).subscribe(( contentData: any) => {
      this.contentRec = contentData;
      this.contentRec.Body = this.sanitizer.bypassSecurityTrustHtml( this.contentRec.Body );
    });

  }




}


