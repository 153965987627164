import { NgZone, AfterViewInit, Component, OnInit } from '@angular/core';
import { AdminTiketService } from '../../../services/admin-tiket.service';
import { UserService } from '../../../services/user.service';
import { IClients } from '../../../model/clients';
import { IUser } from '../../../model/user';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { ISubject } from '../../../model/subject';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';
import { ClientService } from 'src/app/services/client.service';
import { CodeService } from 'src/app/services/code.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { InfoModalComponent } from '../../../shared/info-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../shared/modal.component';

import * as jquery from 'jquery';
declare var $: any;

class MetaFile {
  public name: string;
  public filename: string;
  public uploaded_at: number;
  public userName: string;
  constructor(filename: string) {
    this.filename = filename;
  }
}


@Component({
  selector: 'app-add-ticket',
  templateUrl: './add-ticket.component.html',
  styleUrls: ['./add-ticket.component.scss']
})
export class AddTicketComponent implements  OnInit {
  submitted = false;
  clients: IClients;
  users: IUser;
  subject: ISubject;
  assignedUser: any;
  keywords: any;
  form: FormGroup;
  default_status: any = 1;
  file_data: Array<any>;
  metaFiles: MetaFile[] = [];
  files: Array<any> = null;
  curr_user_id: any;
  show_error: boolean;
  custom_image_title: any = '';
  showloader: boolean;
  dropdownList = [];
  selectedItems = [];
  CCemailList = [];
  agentCCemailList = [];
  selectedEmails = [];
  dropdownSettings = {};
  CCdropdownSettings = {};
  StatedropdownSettings = {};
  agentCCdropdownSettings = {};
  searchTerm$ = new Subject<string>();
  token: any;
  agentCC = '';
  clientCC = '';
  states = [];
  empTable = [];
  empQuestion = 'No';
  // editor: any;

  public options: Object = {
    key: 'eC6D6C2E3E2H2xC4B3A3C2B5A1B1E4F1A2sd1Te1LXe1URVJe1DWXG==',
    // key: 'dC10B5D5A2A-9H3E2J2A4C6C3C2B5B1E1pj1wgbrD-11fzfsB-22B3zuv==',
    // key: 'eHE5C-11B2C1F2I2A5C4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4D3E4C2G2E3C1B2==',
    charCounterCount: true,
    attribution: false,
    height: 135,
    toolbarButtons: ['bold', 'italic', 'underline', 'align', 'undo', 'redo', 'strikeThrough',
                     'color', 'paragraphFormat', 'paragraphStyle', 'emoticons']
  };

  constructor( private http: HttpClient,
               private adminSer: AdminTiketService,
               private userSer: UserService,
               private clientSer: ClientService,
               private fb: FormBuilder,
               private infoModalService: NgbModal,
               private modalService: NgbModal,
               private router: Router,
               private codeSer: CodeService,
               private ngZone: NgZone) {

      this.showloader = true;
  }


  parseFilenames( files: Array<any>) {
    this.metaFiles = [];
    for (let i = 0; i < files.length; i++ ) {
      const meta = new MetaFile( files[i].filename );

      meta.filename = files[i].filename;
      meta.uploaded_at = files[i].uploaded_at;
      let fname: string;
      fname = files[i].filename == null ? '' : files[i].filename;
      const splitStr = fname.split( '=' );
      if (splitStr.length > 1) {
        meta.name = splitStr[1];
      } else {
        meta.name = files[i].filename;
      }

     this.metaFiles.push( meta );
    }
  }


  ngOnInit() {
    this.selectedItems = [];
    this.selectedEmails = [];
    this.token = localStorage.getItem('token');
    this.userSer.selectMenu('askanattorney');


    this.CCdropdownSettings = {
      singleSelection: false,
      idField: 'UserID',
      textField: 'EMail',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false
    };

    this.StatedropdownSettings = {
      singleSelection: true,
      idField: 'Code',
      textField: 'Code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll: false
    };

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    this.clientSer.getClients().subscribe((data: any) => {
      this.clients = data;
      this.showloader = false;
    });
    this.codeSer.getSubjectMatter().subscribe((data: any) => {
      this.subject = data;
    });
    this.codeSer.getCodeByType('STATE').subscribe( (data: any ) => {
      this.states = data;
      const obj =  { Value: 'Select', Code: 'Select', Type: 'STATE', ID: 0 };
      this.states.unshift( obj );
    });

    this.userSer.getAgents().subscribe((data: any) => {
      this.assignedUser = data;
      this.agentCCemailList = data;
    });
    this.adminSer.getKeywords().subscribe((data: any) => {
      this.dropdownList = data;

      // this.editor = new FroalaEditor('#response',
      //   this.options,
      //   function () {
      //   }
      // );

    });

    const userData = localStorage.getItem('UserData');
    this.curr_user_id = JSON.parse(userData);

    if (this.userSer.isClientAdminOrLess( this.curr_user_id.RoleType )) {
      this.userSer.getUsersByClient(this.curr_user_id.client.ID).subscribe((data: any) => {
        this.CCemailList = data;
        this.users = data;
        this.showloader = false;
      });

      this.form = this.fb.group({
        assignedTo: [''],
        keyword: [''],
        clientId: [this.curr_user_id.client.ID],
        userId: [this.curr_user_id.UserID],
        statusId: [1],
        subject: ['', Validators.required],
        subjectId: ['', Validators.required],
        clientCC: '',
        agentCC: '',
        queryText: [''],
        imageInput: null
      });
    } else {
      this.form = this.fb.group({
        assignedTo: ['', Validators.required],
        keyword: [''],
        clientId: ['', Validators.required],
        userId: ['', Validators.required],
        statusId: ['', Validators.required],
        subject: ['', Validators.required],
        subjectId: ['', Validators.required],
        clientCC: '',
        agentCC: '',
        queryText: [''],
        imageInput: null
      });
    }

  }


  //
  // Get Requester
  //
  getRequester(event) {
    this.showloader = true;
    const id = event.target.value;
    this.userSer.getUsersByClient(id).subscribe((data: any) => {
      this.CCemailList = data;
      this.users = data;
      this.showloader = false;
    });
  }

  toggleChange(event: any) {
    this.empQuestion = (event.target.checked ? 'Yes' : 'No');
    const collap = (this.empQuestion === 'No' ? 'hide' : 'show');
    $('#conflict').collapse( collap );
    if (collap === 'show') {
      $('#newEmpRow').collapse('show');
    }
  }

  checkForAutoSave() {
    const newEmpName = $('#newEmpName').val();
    const newEmpCity = $('#newEmpCity').val();
    let newEmpState = $('#newEmpState').val();
    newEmpState = parseInt( newEmpState, 10 );
    if (newEmpName.length > 0 && newEmpCity.length > 0 && newEmpState > 0) {
      const stateName = $('#newEmpState option:selected').text().trim();
      const obj = this.findEmpByName( newEmpName, newEmpCity );
      if (obj === null) {
        this.empTable.push( { ID: this.empTable.length,
              empName: newEmpName,
              empCity: newEmpCity,
              empState: stateName
        });
        this.clearEmp();
      } else {
         this.displayMessage( 'Duplicate Employee. Please edit existing record.' );
      }
    }
  }

  updateEmp( id: any ) {
    if (this.empTable.length > id - 1) {
      const obj = this.empTable[id];
      const empName = $('#empName' + id).val();
      const empCity = $('#empCity' + id).val();
      const empState = $('#empState' + id + ' option:selected').text().trim();
      obj.empName = empName;
      obj.empCity = empCity;
      obj.empState = empState;
    }
  }

  findEmpByName( name: any, city: any ) {
    if (!this.empTable) {
      return null;
    }

    for (var idx = 0; idx < this.empTable.length; ++idx) {
      const obj = this.empTable[idx];
      if (obj.empName === name && obj.empCity === city) {
        return obj;
      }
    }

    return null;
  }


  clearEmp() {
    $('#newEmpName').val('');
    $('#newEmpCity').val('');
    $('#newEmpState').val(0);
    $('#newEmpRow').collapse('hide');
  }


  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Warning';
    modalRef.result.then((res: any) => {
    });

  }

  /**
   *
   * Upload Files
   */
  getFiles(event) {
    // this.files = <File>event.target.files[0];
    // this.custom_image_title = this.files.name;
    this.files = <Array<File>>event.target.files;
    let fNames: any = '';
    for (let i = 0; i < this.files.length; i++ ) {
      if (fNames.length > 0) {
        fNames = fNames + ', ' + this.files[i].name;
      } else {
        fNames = this.files[i].name;
      }
    }
    this.custom_image_title = fNames;

  }

  showFields() {
    const controls = this.form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            console.log( name );
        }
    }

  }



  get f() { return this.form.controls; }
  /**
   * Save Tickets
   */
  saveTickets() {
    this.submitted = true;
    this.showloader = true;
    if (this.form.value.queryText === '<br>' || this.form.value.queryText === '') {
      this.show_error = true;
    }

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showFields();
      this.showloader = false;
      return;
    }

    if (this.form.valid) {
      const data = new FormData();

      let replyText;
      // replyText = this.editor.html.get();
      replyText = $('#response').froalaEditor('html.get');

      if (this.files !== null) {
        for (let i = 0; i < this.files.length; i++) {
          data.append('file', this.files[i], this.files[i].name);
        }
        if (this.files.length > 0) {
          let len;
          len = replyText.length;
          if (replyText.split('</p>').length > 0 && len > 4) {
            let lastfour;
            lastfour = replyText[len-4] + replyText[len-3] + replyText[len-2] + replyText[len-1];
            if (lastfour === '</p>') {
               replyText = replyText.substr(0, len-4) +
                        '<img class="paperclip" src="assets/images/file.png" alt="File Attached"></p>';
            } else {
              replyText = replyText +
                        '<p><img class="paperclip" src="assets/images/file.png" alt="File Attached"></p>';
            }
          } else {
            replyText = replyText + '<p>File attached--no reply message</p>'
                        + '<div class="paperclip"><img src="assets/images/file.png" alt="File Attached"></div>';
          }
        }
      }
      data.append('message', replyText);

      data.append('subject', this.form.value.subject);
      data.append('subject_matter', this.form.value.subjectId);

      if (this.form.value.agentCC) {
        data.append('cc_agent', this.userToEmailStr( this.form.value.agentCC));
      }
      if (this.form.value.clientCC) {
        data.append('cc', this.userToEmailStr( this.form.value.clientCC));
      }

      if (this.userSer.isClientAdminOrLess( this.curr_user_id.RoleType )) {
        if (this.form.value.keyword) {
          this.form.value.keyword = Array.prototype.map.call(this.form.value.keyword, s => s.name).toString();
          data.append('keyword', this.form.value.keyword);
        }
      }
      if (this.form.value.assignedTo) {
        data.append('assigned_to', this.form.value.assignedTo);
      }
      if (this.form.value.userId) {
        data.append('requester', this.form.value.userId);
      } else {
        data.append('requester', this.curr_user_id.UserID);
      }
      if (this.form.value.statusId) {
        data.append('status', this.form.value.statusId);
      } else {
        data.append('status', this.default_status);
      }

      if (this.form.value.clientId) {
        data.append('client_id', this.form.value.clientId);
      } else {
        data.append('client_id', this.curr_user_id.ClientID);
      }

      data.append('EmpQuestion', (this.empQuestion === 'Yes' ? '1' : '0') );
      data.append('EmpTable', JSON.stringify( this.empTable ) );


      const that = this;
      this.adminSer.saveTicket(data).subscribe(
        {
          next(fData: any) {
            that.ngZone.run( () => {
              that.router.navigate(['/ticket']);
            });
          },
          error(msg) {
            alert('Unable to save ticket');
            that.ngZone.run( () => {
              that.router.navigate(['/ticket']);
            });
          }
        });
    }
  }

  userToEmailStr( ccArr ): string {
    let ccStr = '';
    for (var uIdx = 0; uIdx < ccArr.length; uIdx++ ) {
      if (ccStr.length > 0) {
        ccStr = ccStr + ',' + ccArr[uIdx].EMail;
      } else {
        ccStr = ccArr[uIdx].EMail;
      }
     }
     return ccStr;
  }

  getEmpState( stArr ): string {
    let stStr = '';
    console.log( stArr );
    // if (stStr.length > 0) {
    //   stStr = stStr + ',' + stArr[uIdx].Code;
    // } else {
    //   stStr = stArr[uIdx].Code;
    // }
    return stStr;
  }

  deleteEmp( id: any ) {
    const empRow = $('#emp' + id);
    if (empRow.length === 0) {
      alert('Unable to find Employee');
      return;
    }
    const empName = $('#emp' + id ).val().trim();

    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to remove ' +
    '<span class="text-primary">"' + empName + '"</span> record?</strong></p>' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'Employee Record';
    modalRef.result.then((res: any) => {
      const cId = this.empTable.find( x => x.empName === empName );
      if (cId) {
        this.empTable.splice( cId, 1 );
      }
    })
    .catch( (res: any) => {
    });

  }


}
