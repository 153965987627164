import { Component, OnInit, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { RoleProfileService } from '../../services/roleprofile.service';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { ClientService } from '../../services/client.service';
import { IClients } from '../../model/clients';
import { PartnerService } from '../../services/partner.service';
import { AuthService } from '../../services/auth.service';
import { IAuth } from '../../model/auth';
import { IRoleProfile } from '../../model/roleprofile';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailService } from '../../services/mail.service';
import { isNumeric } from 'rxjs/internal-compatibility';
import { UserService } from 'src/app/services/user.service';


class RoleProfileAuth {
  ID: number;
  Description: string;
  Status: number;
  StatusType: number;
}

@Component({
  selector: 'app-roleprofile',
  templateUrl: './roleprofile.component.html',
  styleUrls: ['./roleprofile.component.scss']
})
export class RoleProfileComponent implements OnInit {
  @Input() roleprofile_id: number = null;
  @Input() client_id: number = null;
  @Input() partner_id: number = null;
  @Output() submit_return: EventEmitter<boolean> = new EventEmitter<boolean>();

  roleprofile: IRoleProfile;
  clients: IClients[];
  auths: IAuth[];
  authList: any;
  partners: [];
  embedded: any = false;
  clientEmbed: any = false;
  showloader = true;
  successful_result = false;
  error_result = false;
  dropdownList = [];
  dropdownSettings: {};
  form: FormGroup;
  token: any;
  title: string;
  submitted = false;
  fullAccess: any;
  emailCount: any = 0;
  post_results: string;
  userData: any;
  private subscription: Subscription;
  private timer: Observable<any>;

  NameCtrl: FormControl;
  ClientIDCtrl: FormControl;
  RoleCtrl: FormControl;
  PartnerIDCtrl: FormControl;
  FullAccessCtrl: FormControl;
  RoleTypes: any;
  crumbList: any = [];

  constructor(
    private http: HttpClient,
    private roleProfileSer: RoleProfileService,
    private authSer: AuthService,
    private clientSer: ClientService,
    private partnerSer: PartnerService,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private userSer: UserService,
    private actRoute: ActivatedRoute,
    private router: Router ) {

      this.showloader = true;
  }

  ngOnInit() {
    this.showloader = true;
    this.userData = this.userSer.getUserData();

    if (typeof(this.partner_id) === 'undefined') {
      this.partner_id = null;
    }

    // if roleprofile_id was passed in then it's embedded within the client set up or
    // partner set up screens
    if (typeof(this.roleprofile_id) !== 'undefined' && this.roleprofile_id !== null) {
      this.embedded = true;
      this.clientEmbed = (this.partner_id === null || this.partner_id === 0 ||
              typeof(this.partner_id) === 'undefined');
    } else {
      this.actRoute.params.subscribe( params =>
         this.roleprofile_id = params['id']
      );
    }

    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' },
      { title: 'Role Profile List', url: '/admins/roleprofile-list' }
    ];
    this.NameCtrl = new FormControl('Name', [Validators.required, Validators.minLength(2)]);
    this.ClientIDCtrl = new FormControl('ClientId');
    this.RoleCtrl = new FormControl('RoleType', [Validators.required]);
    const regex = new RegExp(/^(?=.*[a-z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,20}/);
    this.PartnerIDCtrl = new FormControl('PartnerID');
    this.FullAccessCtrl = new FormControl('FullAccess');

    if (this.clientEmbed) {
      this.ClientIDCtrl.disable();
      this.ClientIDCtrl.setValue( this.client_id );
    } else if (this.embedded) {
      this.PartnerIDCtrl.disable();
      this.PartnerIDCtrl.setValue( this.partner_id );
    }

    this.form = this.fb.group({
      Name: this.NameCtrl,
      ID: this.ClientIDCtrl,
      Role: this.RoleCtrl,
      FullAccess: this.FullAccessCtrl,
      ClientID: this.ClientIDCtrl,
      PartnerID: this.PartnerIDCtrl
    });

    this.RoleTypes = [
      { RoleType: 2, RoleName: 'Client User' },
      { RoleType: 3, RoleName: 'Partner User' },
      { RoleType: 6, RoleName: 'Agent' },
      { RoleType: 8, RoleName: 'System Admin' },
    ];

    this.authSer.getAllAuths().subscribe( (authData: any) => {
      this.auths = authData;

      this.clientSer.getClients().subscribe((data: any) => {
        this.clients = data;

        if (this.roleprofile_id === undefined || this.roleprofile_id === 0) {
          this.roleprofile_id = 0;
          this.title = 'New Role Profile';
          this.form.patchValue({
            Name: '',
            Role: (this.client_id ? '2' : '3'),
            FullAccess: 1
          });
          this.parseAuths( null );
          this.showloader = false;
          this.fullAccess = 'Yes';

        } else {
          this.title = 'Edit Role Profile';
          this.roleProfileSer.getRoleProfile(this.roleprofile_id).subscribe((rpData: any) => {
            this.form.patchValue({
                  Name: rpData.Name,
                  Role: rpData.Role,
                  ClientID: rpData.ClientID,
                  PartnerID: rpData.PartnerID,
                  FullAccess: rpData.FullAccess
            });
            if (rpData.Role === 2) {
              this.form.patchValue( { ClientID: rpData.ClientID });
            } else if (rpData.Role === 3) {
              this.form.patchValue( { PartnerID: rpData.PartnerID });
            }
            this.fullAccess = (rpData.FullAccess === 1 ? 'Yes' : 'No');
            this.parseAuths( rpData.AuthList );
            this.showloader = false;
          });
        }

        // no reason to get partner list unless we are in the stand alone 
        // user setup
        if (!this.embedded) {
          this.partnerSer.getPartners().subscribe( (pData: any) => {
            this.partners = pData;
            this.showloader = false;
          });
        }

        // embedded into the partner user list screen
        // fix the role to partner only
        if (this.embedded && !this.clientEmbed) {
          this.form.patchValue( { PartnerID: this.partner_id, Role: 3 });

          this.RoleTypes = [
            { RoleType: 3, RoleName: 'Partner User', UserType: 3}
          ];
        }

        // client embedded, patch the roletype, and soon to be depricated UserType
        if (this.clientEmbed) {
          this.RoleTypes = [
            { RoleType: 2, RoleName: 'Client User' }
          ];
          this.form.patchValue( {Role: 2, UserType: 5 });
        }

      });
    });

    this.token = localStorage.getItem('token');

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
  }

  parseAuths( jsonList ) {
    if (jsonList) {
       this.authList = JSON.parse( jsonList );
    } else {
       this.authList = [];
    }

    if (this.authList !== null) {
        for (var i = 0; i < this.authList.length; i++ ) {
          let rec = this.authList[i];
          rec.ID = i + 1;
        }
    } else {
      this.authList = [];
    }

    let roleType = this.form.value.Role;
    if (typeof(roleType) === 'string') {
      roleType = parseInt( roleType, 10 );
    }

    for (var i = 0; i < this.auths.length; i++ ) {
      if (this.auths[i].RoleType === roleType) {
          const rec = this.authList.find( x => x.Description === this.auths[i].Description );
          if (rec === undefined) {
            const rec = this.auths[i];
            let newAuth: RoleProfileAuth;
            newAuth = new RoleProfileAuth();
            newAuth.ID = this.authList.length + 1;
            newAuth.Status = this.form.value.FullAccess;
            newAuth.Description = rec.Description;
            newAuth.StatusType = rec.StatusType;
            this.authList.push( newAuth );
          } else if (this.form.value.FullAccess === 1) {
            rec.Status = 1;
          } else if (rec.Status === undefined) {
            rec.Status = 0;
          }
      }
    }
    let tempList : any[] = [];

    for (var i = 0; i < this.authList.length; i++) {
      const aRec = this.auths.find( x => x.Description === this.authList[i].Description );
      if (aRec !== undefined && aRec.RoleType === roleType) {
        tempList.push( this.authList[i] );
      }
    }
    this.authList = tempList;
  }

  updateAuths() {
    this.authList = [];
    this.parseAuths( null );
  }

  saveStatus( id: any ) {
    const status = $('#auth_' + this.roleprofile_id + '_' + id).val();
    const auth = this.authList.find( x => x.ID === id);
    if (auth !== undefined) {
      auth.Status = status;
    }
  }

  saveRoleProfile() {
    // this.submitted = true;
    this.showloader = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showloader = false;
      return;
    }

    if (this.form.valid) {
      const fd = new FormData();

      fd.append('Name', this.form.value.Name);
      if (this.clientEmbed) {
         fd.append('ClientID', this.client_id.toString( 10 ) );
      } else if (this.embedded) {
         fd.append('PartnerID', this.partner_id.toString(10) );
      } else {
         if (this.form.value.Role === '2' || this.form.value.Role === 2) {
           fd.append('ClientID', this.form.value.ClientID);
         } else if (this.form.value.Role === '3' || this.form.value.Role === 3) {
           fd.append('PartnerID', this.form.value.PartnerID );
         } else {
           fd.append('PartnerID', '0');
         }
      }
      fd.append('Role', this.form.value.Role);
      fd.append('FullAccess', (this.fullAccess === 'Yes' ? '1' : '0' ));
      fd.append('AuthList', JSON.stringify( this.authList ) );

      const that = this;
      if (this.roleprofile_id === 0) {
         this.roleProfileSer.createRoleProfile(fd).subscribe(
          {
            next(res: any) {
              if (res.status) {
                that.successful_result = true;
                this.user_id = res.data.UserID;
              } else {
                that.error_result = true;
                console.log('unable to save');
              }
              that.setTimer();
            },
            error(msg) {
              that.ngZone.run( () => {
                alert( 'Error saving record. Most likely the Role Profile is a duplicate of an existing record' );
              });
            }
        });
      } else {
        this.roleProfileSer.saveRoleProfile(this.roleprofile_id, fd ).subscribe( {
          next(res: any) {
            if (res) {
               that.successful_result = true;
            } else {
              that.error_result = true;
              alert('Error saving record. Likely using the same name?');
              console.log('unable to save');
            }
            that.setTimer();
          },
          error(msg) {
            that.ngZone.run( () => {
              alert( 'Error saving record. Most likely the Role Profile is a duplicate of an existing record' );
              alert( msg );
            });
          }
        });
      }
      this.showloader = false;
      this.submitted = false;
    }
  }

  public setTimer() {
    this.timer = Observable.timer(1500);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
        if (this.roleprofile_id === 0) {
          this.form.patchValue({
            Name: ''
          });
        }
        this.submit_return.emit( true );
    });
  }

  toggleChange(event: any) {
    this.fullAccess = (event.target.checked ? 'Yes' : 'No');
    this.form.value.FullAccess = (this.fullAccess === 'Yes' ? 1 : 0);
    for( let a of this.authList) {
        a.Status = this.form.value.FullAccess;
    }
  }

}
