import {Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { PartnerService } from '../../services/partner.service';
import { IPartner } from '../../model/partner';
import { IPage } from '../../model/page';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// export interface IUserValue {
//   Text: string;
//   value: number;
//   status: any;
// }

@Component({
  selector: 'app-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss'],
})

export class PartnerListComponent implements OnInit {
  partners : any;
  isFilterTrue = true;
  // userValue: IUserValue;
  paging: IPage;
  per_page = 10;
  selected_status: number;
  filter: any = { 'companyname': '', 'contactname': '', 'status': ''};
  curr_user_id: any;
  total: number = 0;
  public row_header: any = [];
  public header_index: any;
  public assignee_array: any = [];
  private allItems: any[];
  showloader: boolean;
  pager: any = {};
  pagedItems: any[];
  crumbList: any[];

  constructor(
    private pagerS: PagerService,
    private adminSer: AdminTiketService,
    private partnerSer: PartnerService,
    private modalService: NgbModal,
    private ngZone: NgZone) {
      this.showloader = true;
  }

  ngOnInit() {
    const userData = localStorage.getItem('UserData');
    this.curr_user_id = JSON.parse(userData);
    let that = this;
    this.selected_status = null;
    // this.partnerSer.getClients().subscribe((data: any) => {
    //   this.partners = data;
    // });
    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' }
    ];
    this.row_header  = [
      {text: 'Partner', value: 'CompanyName', 'sort': 'asc'},
      {text: 'ContactName', value: 'ContactName', 'sort': null},
      {text: 'Status', value: 'Status', 'sort': null},
      {text: 'Notes', value: 'Notes', 'sort': 'none'}
    ];
    this.header_index = 1;

    this.tableSort(0);
  }

  findLocalPartner( id: number ) {
     for ( var idx = 0; idx < this.partners.length; idx++ ) {
        const obj = this.partners[idx];
        if (obj.ID === id) {
          return obj;
        }
     }
     return null;
  }

  deleteUser(id: number) {
    const partner = this.findLocalPartner( id );
    if (partner === null) {
      alert('Unable to find Partner');
      return;
    }
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to delete ' +
    '<span class="text-primary">"' + partner.CompanyName + '"</span> record?</strong></p>' +
    '<p>All information associated to this Partner record will be permanently deleted.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'Partner Record';
    modalRef.result.then((res: any) => {
      this.partnerSer.deletePartner(id).subscribe((data: any) => {
        this.setPage(1);
      });
    })
    .catch( (res: any) => {
    });

  }

  filterByPartner(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.companyname = id;
    this.setPage(1);
  }

  filterByContactName(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.contactname = id;
    this.setPage(1);
  }

  filterByStatus(event) {
    this.showloader = true;
    const searchStr = event.target.value;
    this.filter.status = searchStr;
    this.setPage(1);
  }

  setPage(page: number = 1) {
        const that = this;

        this.showloader = true;
        this.partnerSer
          .getAllPartners( page, this.per_page, this.row_header[this.header_index], this.filter)
          .subscribe( {
              next(data: any) {
                that.showloader = false;
                that.partners = data.data; // return all data
                that.paging = data.page; // return all the paginationinfo
                that.total = that.paging.total;
                that.pager = that.pagerS.getPager(that.paging.total, page, that.per_page);
          },
          error(msg) {
            that.ngZone.run( () => {
              that.showloader = false;
            });
          }
        });
  }

  tableSort(index) {
    if (this.row_header[index].sort === 'none') {
       return;
    }

    this.showloader = true;
    if (this.header_index === index) {
      (this.row_header[index].sort === 'asc') ? this.row_header[index].sort = 'desc' : this.row_header[index].sort = 'asc';
    } else {
      this.row_header[index].sort = 'asc';
      this.row_header[this.header_index].sort = null;
      this.header_index = index;
    }
    this.setPage(1);
  }

}


