import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-verify-token',
  templateUrl: './verify-token.component.html',
  styleUrls: ['./verify-token.component.scss'],
  host : {'class': 'login'}
})
export class VerifyTokenComponent implements OnInit {

  resetForm: FormGroup;
  submitted = false;
  serverError: any = '';
  token: any;
  verifyToken: any;
  message: any;
  showloader: boolean;

  constructor(private authS: AuthService, 
    private fb: FormBuilder,
    private router: Router,
    private userSer: UserService,
    private route: ActivatedRoute) {
      this.showloader = false;
  }

  ngOnInit() {
    this.token = this.route.snapshot.params.token;
    this.authS.verifyToken(this.token).subscribe((data: any) => {
      if (data.status === true) {
        this.verifyToken = 1;
        this.message = 'Reset Your Password';
      } else {
        this.verifyToken = 2;
        this.message = 'Token Has Expired';
      }
      this.showloader = true;
    });
    this.resetForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetForm.controls; }

  onSubmit() {
    if (this.resetForm.invalid) {
      this.submitted = true;
      return;
    } else {
      const data = new FormData();
      if (this.resetForm.value.password !== this.resetForm.value.confirm_password) {
        this.serverError = 'Password and Confirm Password must be same.';
        return;
      } else {
        this.serverError = '';
      }
      data.append('password', this.resetForm.value.password);
      this.authS.resetPassword(data, this.token).subscribe((data: any) => {
        if (data.status) {
          // console.log(data);
          this.verifyToken = 3;
          this.message = `Password Changed Successfully.`;
        } else {
          this.serverError = data.error;
        }
      });
    }
  }

}

