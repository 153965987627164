import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { RouterModule } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, NgZone} from '@angular/core';


@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html'
  })
export class BreadCrumbComponent implements OnInit {
  @Input() crumbList: any = [];

  ngOnInit() {
  }

}
