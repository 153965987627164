import {Component, OnInit, Input, NgZone, EventEmitter, Output, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { StatesService } from '../../services/states.service';
import { IStates } from '../../model/states';
import { IPage } from '../../model/page';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CodeService } from '../../services/code.service';
import { UserService } from '../../services/user.service';

import * as jquery from 'jquery';
import { state } from '@angular/animations';
declare var $: any;

@Component({
  selector: 'app-states-list',
  templateUrl: './states-list.component.html',
  styleUrls: ['./states-list.component.scss'],
})

export class StatesListComponent implements OnInit {


  isFilterTrue = true;
  // userValue: IUserValue;
  paging: IPage;
  totalStatess: number;
  per_page = 10;
  selected_status: number;
  filter: any = { 'state': '' };
  states: any;
  stateIdList: any;
  userData: any;
  total: number = 0;
  public row_header: any = [];
  public header_index: any;
  public assignee_array: any = [];
  private allItems: any[];
  showloader: boolean;
  pager: any = {};
  pagedItems: any[];
  crumbList: any[];

  constructor(
    private pagerS: PagerService,
    private StatesSer: StatesService,
    private modalService: NgbModal,
    private userSer: UserService,
    private codeSer: CodeService,
    private ngZone: NgZone) {
      this.showloader = true;
  }

  ngOnInit() {
    this.userData = this.userSer.getUserData();

    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' }
    ];
    this.selected_status = null;

    this.row_header  = [
      {text: 'State', value: 'State', 'sort': null},
      {text: 'Long Name', value: 'State', 'sort': null},
      {text: 'New Updates', value: 'NewUpdates', 'sort': null}
    ];
    this.header_index = 0;

    this.codeSer.getCodeByType('STATE').subscribe( (data: any ) => {
      this.stateIdList = data;

      this.setPage( 1 );
    });
  }


  findLocalStates( id: number ) {
    return this.states.find( p => p.ID === id);
  }

  deleteStates(id: number) {
    const states = this.findLocalStates( id );
    if (states === undefined) {
      alert('Unable to find State');
      return;
    }
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to delete ' +
    '<span class="text-primary">"' + states.State + '"</span> record?</strong></p>' +
    '<p>All information associated to this record will be permanently deleted.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'State Update Record';
    modalRef.result.then((res: any) => {
      this.StatesSer.deleteStates(states.ID).subscribe((data: any) => {
         this.setPage(1);
      });
    })
    .catch( (res: any) => {
    });

  }


  filterByState(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.state = id;
    this.setPage(1);
  }


  setPage(page: number = 1) {
        const that = this;

        this.showloader = true;
        this.StatesSer
          .getAllStates( page, this.per_page, this.row_header[this.header_index], this.filter)
          .subscribe( {
              next(data: any) {
                that.showloader = false;
                that.states = data.data; // return athe all data
                that.paging = data.page; // return all the paginationinfo
                that.total = that.paging.total;
                that.pager = that.pagerS.getPager(that.paging.total, page, that.per_page);
          },
          error(msg) {
            that.ngZone.run( () => {
              that.showloader = false;
            });
          }
        });
  }

  tableSort(index) {
    this.showloader = true;
    if (this.header_index === index) {
      (this.row_header[index].sort === 'asc') ? this.row_header[index].sort = 'desc' : this.row_header[index].sort = 'asc';
    } else {
      this.row_header[index].sort = 'asc';
      this.row_header[this.header_index].sort = null;
      this.header_index = index;
    }
    this.setPage(1);
  }


}


