import { Component, OnInit } from '@angular/core';
import { IUser } from '../../model/user';
import { Observable, of, pipe, throwError, from } from 'rxjs';
import { UserService } from '../../services/user.service';
import { PageService } from '../../services/page.service';
import * as jquery from 'jquery';
import { find } from 'rxjs/operators';
import { IDBPage } from '../../model/db-page';

declare var $: any;

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  hrPages: IDBPage[] = [];
  proPages: any = [];
  erisaPages: any = [];
  showErisa: any;

  constructor(private userSer: UserService,
    private pageSer: PageService) {
  }

  permissionData: IUser = JSON.parse(localStorage.getItem(('UserData')));

  ngOnInit() {
    this.pageSer.getPages('HR').subscribe((pageData: any) => {
      // this.pages = pageData;
      from(pageData).subscribe( (rec: IDBPage) => {
        rec.href = rec.Name.replace(/\s+/g, '').replace(/\&+/g, '');
        this.hrPages.push( rec );
      });

      this.pageSer.getPages('PRO').subscribe( (proPageData: any) => {
        this.proPages = proPageData;

        this.showErisa = (localStorage.getItem('showErisa') === 'yes' ? true : false);
        if (this.showErisa) {
          this.pageSer.getPages('ERISA').subscribe( (erisaData: any ) => {
            this.erisaPages = erisaData;
          });
        }
      });
    });
  }

  selectMe(event: any) {
    $('#custom-nav a').removeClass('selected');
    let obj = event.target;
    if (event.target.outerHTML.substr(0,6) === '< span' || event.target.outerHTML.substr(0,5) === '<span') {
      obj = event.target.parentNode;
    }
    $(obj).addClass('selected');

    const parentDiv = $(event.target).parents('div').first();

    if ($(parentDiv).hasClass('solution')) {
       $('.solution-center').find('a.sub-bbtn').addClass('act');
    } else {
      if ($(parentDiv).hasClass('solution-center')) {
       $('.solution').find('a').addClass('selected');
       $('.solution-center').find('a.sub-bbtn').addClass('act');
      } else {
        $('.solution-center').find('a.sub-bbtn').removeClass('act');
      }
    }

    if ($(parentDiv).hasClass('business')) {
       $('.business-center').find('a.sub-bbtn').addClass('act');
    } else {
      if ($(parentDiv).hasClass('business-center')) {
       $('.business').find('a').addClass('selected');
        $('.business-center').find('a.sub-bbtn').addClass('act');
      } else {
        $('.business-center').find('a.sub-bbtn').removeClass('act');
      }
    }

  }
}
