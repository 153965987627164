import { Injectable, SecurityContext } from '@angular/core';
import { environment} from '../../environments/environment';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { SafeHtml } from './safehtml';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-info-modal-component',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body" [innerHTML]="getHtml()" >
  </div>
  <div class="modal-footer">
    <button ngbAutofocus type="button" class="btn btn-danger" (click)="modal.close('Ok click')">Ok</button>
  </div>
  `
})
export class InfoModalComponent implements OnInit {
  @Input() confirmationStr = '<p><strong>Are you sure you want to delete ' +
  '<span class="text-primary">"John Doe"</span> profile?</strong></p>' +
  '<p>All information associated to this user profile will be permanently deleted.' +
  '<span class="text-danger">This operation can not be undone.</span>' +
  '</p>';
  @Input() title = 'Information';
  @Input() trustHtml = false;

  html_body: any;

  constructor(public modal: NgbActiveModal,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit() {

  }

  getHtml() {
    if (this.trustHtml) {
       return this.sanitizer.bypassSecurityTrustHtml( this.confirmationStr );
    } else {
      return this.confirmationStr;
    }
  }

  dismiss() {
    this.modal.close();
  }
}
