import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  host : {'class': 'login'}
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  submitted = false;
  serverError: any = '';

  constructor(private authS: AuthService, private fb: FormBuilder, private router: Router, private userSer: UserService) { }

  ngOnInit() {
    this.resetForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetForm.controls; }

  onSubmit() {
    if (this.resetForm.invalid) {
      this.submitted = true;
      return;
    } else {
      const data = new FormData();
      if (this.resetForm.value.password !== this.resetForm.value.confirm_password) {
        this.serverError = 'Password and Confirm must be same.';
        return;
      } else {
        this.serverError = '';
      }
      data.append('password', this.resetForm.value.password);
      this.authS.sendResetPassword(data, localStorage.getItem('token')).subscribe((data: any) => {
        if (data.status) {
          // console.log(data);
          localStorage.clear();
          this.router.navigate(['/login']);
        } else {
          this.serverError = data.error;
        }
      });
    }
  }

}
