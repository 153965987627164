import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { IPartner } from '../../model/partner';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { PartnerService } from '../../services/partner.service';
import { CodeService } from '../../services/code.service';
import { ClientService } from '../../services/client.service';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { ModalComponent } from '../../shared/modal.component';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import { formControlBinding } from '@angular/forms/src/directives/ng_model';
// import { validateConfig } from '@angular/router/src/config';

import * as jquery from 'jquery';
import { ContentService } from 'src/app/services/content.service';
declare var $: any;

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {
  partner: IPartner;
  partners: any;
  id: any;
  status: any;
  platform: any;
  erisa: any;
  toolsList = [];
  masterToolsList = [];
  showloader = true;
  successful_result = false;
  error_result = false;
  dropdownList = [];
  dropdownSettings: {};
  form: FormGroup;
  title: string;
  submitted = false;
  post_results: string;
  userData: any;
  isPartner: any = false;
  private subscription: Subscription;
  private timer: Observable<any>;

  CompanyNameCtrl: FormControl;
  ContactNameCtrl: FormControl;
  BillingNameCtrl: FormControl;
  BillingEmailCtrl: FormControl;
  Address1Ctrl: FormControl;
  Address2Ctrl: FormControl;
  NotesCtrl: FormControl;
  CityCtrl: FormControl;
  StateCtrl: FormControl;
  ZipCtrl: FormControl;
  StatusCtrl: FormControl;
  PhoneCtrl: FormControl;
  PlatformPartnerCtrl: FormControl;
  PlatformPartnerIDCtrl: FormControl;
  crumbList: any[];
  platPartners: any[];
  partnerServices: any[];
  masterServiceList: any[];
  ServiceSolicitationCtrl: FormControl;
  ErisaCenterCtrl: FormControl;

  constructor(
    private http: HttpClient,
    private userSer: UserService,
    private partnerSer: PartnerService,
    private adminSer: AdminTiketService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private codeSer: CodeService,
    private actRoute: ActivatedRoute,
    private contentSer: ContentService,
    private clientSer: ClientService,
    private router: Router ) {

      this.showloader = true;
  }

  ngOnInit() {
    this.showloader = true;
    this.userData = this.userSer.getUserData();
    if (!this.userData || !this.userData.roleprofile) {
      this.router.navigate(['/dashboard']);
    }
    this.isPartner = (this.userData.RoleType === 3);

    if (this.isPartner) {
      this.crumbList = [
        { title: 'Home', url: '/dashboard' },
        { title: 'Admin Menu', url: '/admins/admin-menu' },
      ];
    } else {
      this.crumbList = [
        { title: 'Home', url: '/dashboard' },
        { title: 'Admin Menu', url: '/admins/admin-menu' },
        { title: 'Partner List', url: '/admins/partner-list' }
      ];
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    this.CompanyNameCtrl = new FormControl('CompanyName', [Validators.required]);
    this.ContactNameCtrl = new FormControl('ContactName', [Validators.required]);
    this.BillingNameCtrl = new FormControl('BillingName');
    const emailRegex = new RegExp(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/);
    this.BillingEmailCtrl = new FormControl('BillingEmail', [Validators.pattern( emailRegex )]);
    this.Address1Ctrl = new FormControl('Address1', [Validators.required]);
    this.Address2Ctrl = new FormControl('Address2' );
    this.NotesCtrl = new FormControl('BusinessSummary');
    this.CityCtrl = new FormControl('City', [Validators.required]);
    this.StateCtrl = new FormControl('State', [Validators.required]);
    this.ZipCtrl = new FormControl('Zip', [Validators.required]);
    const regex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
    this.PhoneCtrl = new FormControl('Phone', [Validators.required, Validators.pattern( regex )]);
    this.StatusCtrl = new FormControl('Status');
    this.PlatformPartnerCtrl = new FormControl('PlatformPartner');
    this.PlatformPartnerIDCtrl = new FormControl('PlatformPartnerID');
    this.ServiceSolicitationCtrl = new FormControl('ServiceSoliitation');
    this.ErisaCenterCtrl = new FormControl('ErisaCenter');

    this.form = this.fb.group({
      CompanyName: this.CompanyNameCtrl,
      ContactName: this.ContactNameCtrl,
      BillingName: this.BillingNameCtrl,
      BillingEmail: this.BillingEmailCtrl,
      Address1: this.Address1Ctrl,
      Address2: this.Address2Ctrl,
      Notes: this.NotesCtrl,
      City: this.CityCtrl,
      State: this.StateCtrl,
      Zip: this.ZipCtrl,
      Phone: this.PhoneCtrl,
      Status: this.StatusCtrl,
      PlatformPartner: this.PlatformPartnerCtrl,
      PlatformPartnerID: this.PlatformPartnerIDCtrl,
      ServiceSolicitation: this.ServiceSolicitationCtrl,
      ErisaCenter: this.ErisaCenterCtrl
    });

    this.actRoute.params.subscribe( params =>
      this.id = params['id']
    );

    if (this.isPartner) {
      this.id = this.userData.roleprofile.PartnerID;
    }

    let partId = this.id;
    if (partId === undefined) {
      partId = 0;
    }
    this.partnerSer.getPlatformPartners().subscribe((platformData: any) => {
      this.contentSer.getContentData().subscribe( (contentData: any) => {
        this.codeSer.getServices().subscribe( (serviceData: any) => {
          this.codeSer.getCodeByType('SP-' + partId + '-SERVICE').subscribe( (specialData) => {

            this.masterToolsList = contentData.filter( x => x.Type === 1 );

            this.masterServiceList = serviceData;
            this.masterServiceList = this.masterServiceList.concat( specialData );

            this.platPartners = platformData;

            if (this.id === undefined) {
              this.newPartnerInit();
            } else {
              this.title = 'Edit Partner';
              this.partnerSer.getPartner(this.id).subscribe((partnerData: any) => {
                this.form.patchValue({
                      CompanyName: partnerData.CompanyName,
                      ContactName: partnerData.ContactName,
                      BillingName: partnerData.BillingName,
                      BillingEmail: partnerData.BillingEmail,
                      Address1: partnerData.Address1,
                      Address2: partnerData.Address2,
                      City: partnerData.City,
                      State: partnerData.State,
                      Zip: partnerData.Zip,
                      Notes: partnerData.Notes,
                      Phone: partnerData.Phone,
                      Status: partnerData.Status,
                      PlatformPartner: partnerData.PlatformPartner,
                      PlatformPartnerID: partnerData.PlatformPartnerID,
                      ServiceSolicitation: partnerData.ServiceSolicitation,
                      ErisaCenter: partnerData.ErisaCenter
                  });
                  this.showloader = false;
                  this.status = (partnerData.Status === 1 ? 'Yes' : 'No');
                  this.platform = (partnerData.PlatformPartner === 1 ? 'Yes' : 'No');
                  this.erisa = (partnerData.ErisaCenter === null ? 'Yes' : (partnerData.ErisaCenter === 1 ? 'Yes' : 'No' ));
                  this.parseServices( partnerData );
                  this.parseTools( partnerData );
              });
            }
          });
        });
      });
    });
  }

  newPartnerInit() {
    this.id = 0;
    this.title = 'New Partner';
    this.form.patchValue({
      CompanyName: '',
      ContactName: '',
      BillingName: '',
      BillingEmail: '',
      Address1: '',
      Address2: '',
      Phone: '',
      City: '',
      State: '',
      Zip: '',
      Notes: '',
      Status: 1,
      PlatformPartner: 0,
      PlatformPartnerID: null
    });
    this.status = 'Yes';
    this.platform = 'No';
    this.partnerServices = [];

    this.showloader = false;
  }

  parseServices( partner ) {
    try {
       this.partnerServices = JSON.parse( partner.PartnerServices );
    } catch (e) {
       this.partnerServices = this.masterServiceList.slice();
    }
    if (this.partnerServices !== null && this.partnerServices !== undefined) {
    } else {
      this.partnerServices = this.masterServiceList.slice();
    }
  }

  parseTools( partner ) {
    try {
       this.toolsList = JSON.parse( partner.ToolsList );
    } catch (e) {
       this.toolsList = this.masterToolsList.slice();
    }
    if (this.toolsList !== null && this.toolsList !== undefined) {
    } else {
      // this.toolsList = this.masterToolsList.slice();
      this.toolsList = [];
    }
  }


  savePartner( reload: any) {
    // this.submitted = true;
    this.showloader = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showloader = false;
      return;
    }

    if (this.form.valid) {
      const fd = new FormData();

      fd.append('CompanyName', this.form.value.CompanyName);
      fd.append('ContactName', this.form.value.ContactName);
      if (this.form.value.BillingName) {
        fd.append('BillingName', this.form.value.BillingName );
      } else {
        fd.append('BillingName', '');
      }
      if (this.form.value.BillingEmail) {
        fd.append('BillingEmail', this.form.value.BillingEmail );
      } else {
        fd.append('BillingEmail', '' );
      }
      fd.append('Address1', this.form.value.Address1);
      if (this.form.value.Address2) {
        fd.append('Address2', this.form.value.Address2 );
      } else {
        fd.append('Address2', '' );
      }
      if (this.form.value.Notes) {
         fd.append('Notes', this.form.value.Notes);
      } else {
        fd.append('Notes', '');
      }
      fd.append('City', this.form.value.City);
      fd.append('State', this.form.value.State);
      fd.append('Zip', this.form.value.Zip);
      fd.append('Phone', this.form.value.Phone);
      fd.append('Status', (this.status === 'Yes' ? '1' : '0'));
      fd.append('PlatformPartner', (this.platform === 'Yes' ? '1' : '0'));
      fd.append('PartnerServices', JSON.stringify( this.partnerServices ) );
      fd.append('ServiceSolicitation', this.form.value.ServiceSolicitation);
      fd.append('ErisaCenter', (this.erisa === 'Yes' ? '1' : '0'));
      fd.append('ToolsList', JSON.stringify( this.toolsList ));

      // logic to clear out the platformId is API side as well
      if (this.platform === 'Yes') {
        fd.append('PlatformPartnerID', '0');
      } else {
        fd.append('PlatformPartnerID', this.form.value.PlatformPartnerID);
      }

      if (this.id === 0) {
         this.partnerSer.createPartner(fd).subscribe((res: any) => {
            if (res.status) {
              this.successful_result = true;
              this.id = res.data.ID;
              if (reload) {
                window.location.reload();
              }
            } else {
              this.error_result = true;
              console.log('unable to save');
            }
            this.setTimer();
        });
      } else {
        this.partnerSer.savePartner(this.id, fd ).subscribe((res: any) => {
          if (res) {
             this.successful_result = true;
             if (reload) {
              window.location.reload();
             }
        } else {
            this.error_result = true;
            console.log('unable to save');
          }
          this.setTimer();
        });
      }
      this.showloader = false;
      this.submitted = false;
    }
  }

  public setTimer() {
    this.timer = Observable.timer(2500);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
    });
  }

  toggleChange(event: any) {
    this.status = (event.target.checked ? 'Yes' : 'No');
  }

  toggleErisa(event: any) {
    this.erisa = (event.target.checked ? 'Yes' : 'No');
  }

  togglePlatform(event: any) {
    this.platform = (event.target.checked ? 'Yes' : 'No');
    if (this.platform === 'Yes') {
      $('#platformPartnerDiv').addClass('collapse');
    } else {
      $('#platformPartnerDiv').removeClass('collapse');
    }
  }
  expandTable(rowId: any) {
    rowId = '#' + rowId;
    $(rowId).collapse( !$(rowId).hasClass('show') );
  }

  swapIcon( event: any ) {
    const ele = event.target;
    const expanded = $(ele).hasClass('fa-expand-alt');
    if (expanded) {
      $(ele).removeClass('fa-expand-alt').addClass('fa-compress-alt');
    } else {
      $(ele).removeClass('fa-compress-alt').addClass('fa-expand-alt');
    }
  }

  clearService() {
    $('#newService').val('');
    $('#newServiceRow').collapse('hide');
  }

  deleteService( id: any ) {
    const serRow = $('#service' + id);
    if (serRow.length === 0) {
      alert('Unable to find Service');
      return;
    }
    const serviceName = $('#service' + id).val().trim();

    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to remove ' +
    '<span class="text-primary">"' + serviceName + '"</span> record?</strong></p>' +
    '</p>';
    modalRef.componentInstance.title = 'Service Record';
    modalRef.result.then((res: any) => {
      let cId = -1;
      for (var idx=0; idx < this.partnerServices.length; idx++) {
        if (this.partnerServices[idx].Code === serviceName) {
          cId = idx;
        }
      }
      if (cId >= 0) {
        this.partnerServices.splice( cId, 1 );
      }
    })
    .catch( (res: any) => {
    });

  }

  saveService() {
    let newService = $('#newService').val();
    newService = parseInt( newService, 10 );
    if (newService > 0 ) {
      const service = $('#newService option:selected').text().trim();
      const obj = this.partnerServices.find( x => x.Code === service );
      const ser = this.masterServiceList.find( x => x.Code === service );
      if (obj === undefined && ser !== undefined) {
        this.partnerServices.push( ser );
        this.partnerServices.sort(function(a, b){
          if (a.ID < b.ID) { return -1; }
          if (a.ID > b.ID) { return 1; }
          return 0;
        });
        this.savePartner( false );
        this.clearService();
      }
    } else {
      alert('Duplicate service');
    }
  }

  clearTool() {
    $('#newTool').val('');
    $('#newToolRow').collapse('hide');
  }


  saveTool() {
    let newTool = $('#newTool').val();
    newTool = parseInt( newTool, 10 );
    if (newTool > 0 ) {
      const service = $('#newTool option:selected').text().trim();
      const obj = this.toolsList.find( x => x.Title === service );
      const ser = this.masterToolsList.find( x => x.Title === service );
      if (obj === undefined && ser !== undefined) {
        this.toolsList.push( ser );
        this.toolsList.sort(function(a, b){
          if (a.ID < b.ID) { return -1; }
          if (a.ID > b.ID) { return 1; }
          return 0;
        });
        this.savePartner( false );
        this.clearService();
      } else {
        alert('Duplicate tool');
      }
    }
  }

  deleteTool( id: any ) {
    const serRow = $('#tool' + id);
    if (serRow.length === 0) {
      alert('Unable to find Tool');
      return;
    }
    const serviceName = $('#tool' + id).val().trim();

    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to remove ' +
    '<span class="text-primary">"' + serviceName + '"</span> record?</strong></p>' +
    '</p>';
    modalRef.componentInstance.title = 'Tool Record';
    modalRef.result.then((res: any) => {
      let cId = -1;
      for (var idx=0; idx < this.toolsList.length; idx++) {
        if (this.toolsList[idx].Title === serviceName) {
          cId = idx;
        }
      }
      if (cId >= 0) {
        this.toolsList.splice( cId, 1 );
      }
    })
    .catch( (res: any) => {
    });

  }

}
