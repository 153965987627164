import {Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { UserService } from '../../services/user.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as jquery from 'jquery';
declare var $: any;

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})

export class AdminMenuComponent implements OnInit {
  isFilterTrue = true;
  user_id: any;

  constructor(
    private userSer: UserService,
    private modalService: NgbModal,
    private ngZone: NgZone) {
  }

  ngOnInit() {
    const userData = localStorage.getItem('UserData');
    this.userSer.selectMenu('admin-sidebar');

    this.user_id = this.userSer.getUserId();
  }

}


