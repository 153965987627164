import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RoutingModule} from '../routing.module';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SharedModule } from '../shared/shared.module';
import { PaginationComponent } from './pagination/pagination.component';
import { Ng2CompleterModule } from 'ng2-completer';
import { SearchOptionComponent } from './search-option/search-option.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    SharedModule,
    Ng2CompleterModule
  ],
  exports: [
    HeaderComponent,
    PaginationComponent,
    FooterComponent,
    ToolbarComponent,
    SearchOptionComponent
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    ToolbarComponent,
    PaginationComponent,
    SearchOptionComponent
  ]
})
export class CoreModule { }
