import {Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { UserService } from '../../services/user.service';
import { IUser } from '../../model/user';
import { IPage } from '../../model/page';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from '../../shared/info-modal.component';
import { ClientService } from 'src/app/services/client.service';
declare var require: any;

// export interface IUserValue {
//   Text: string;
//   value: number;
//   status: any;
// }

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})

export class UserListComponent implements OnInit {
  @Input() client_id: number = null;
  @Input() partner_id: number = null;

  users: any;
  isFilterTrue = true;
  // userValue: IUserValue;
  paging: IPage;
  totalUsers: number;
  per_page = 10;
  selected_status: number;
  filter: any = { 'clientname': '', 'name': '', 'email': ''};
  clients: any;
  curr_user_id: any;
  total: number = 0;
  public row_header: any = [];
  public header_index: any;
  public assignee_array: any = [];
  private allItems: any[];
  showloader: boolean;
  pager: any = {};
  pagedItems: any[];
  RoleTypes: any[];
  crumbList: any[];
  uploadFile: any;
  embedded: any = false;

  constructor(
    private pagerS: PagerService,
    private adminSer: AdminTiketService,
    private userSer: UserService,
    private clientSer: ClientService,
    private modalService: NgbModal,
    private infoModalService: NgbModal,
    private ngZone: NgZone) {
      this.showloader = true;
  }

  ngOnInit() {
    if (typeof(this.client_id) !== 'undefined' && this.client_id !== null) {
      this.embedded = true;
    }
    if (typeof(this.partner_id) !== 'undefined' && this.partner_id !== null) {
      this.embedded = true;
    }

    const userData = localStorage.getItem('UserData');
    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' }
    ];
    this.curr_user_id = JSON.parse(userData);
    let that = this;
    this.selected_status = null;
    this.clientSer.getClients().subscribe((data: any) => {
      this.clients = data;
    });

    this.RoleTypes = [
      { RoleType: 2, RoleName: 'Client User' },
      { RoleType: 3, RoleName: 'Partner User' },
      { RoleType: 6, RoleName: 'Agent' },
      { RoleType: 8, RoleName: 'System Admin' },
    ];

    if (this.embedded) {
      this.row_header  = [
        {text: 'Name', value: 'LastName', 'sort': null},
        {text: 'Email', value: 'EMail', 'sort': null},
        {text: 'Role', value: 'RoleType', 'sort': null},
        {text: 'Active', value: 'Active', 'sort': null }
      ];
    } else {
      this.row_header  = [
        {text: 'Client', value: 'client', 'sort': 'asc'},
        {text: 'Name', value: 'LastName', 'sort': null},
        {text: 'Email', value: 'EMail', 'sort': null},
        {text: 'Role', value: 'RoleType', 'sort': null},
        {text: 'Active', value: 'Active', 'sort': null }
      ];
    }
    this.header_index = 1;

    this.tableSort(0);
  }

  getRoleType( utype: number ) {
    const role = this.RoleTypes.find( x => x.RoleType === utype );
    if (role === undefined) {
      return 'Unknown User Type ' + utype;
    } else {
      return role.RoleName;
    }
  }

  findLocalUser( id: number ) {
     return this.users.find( x => x.UserID === id );
  }

  deleteUser(id: number) {
    const user = this.findLocalUser( id );
    if (user === null) {
      alert('Unable to find User');
      return;
    }
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to delete ' +
    '<span class="text-primary">"' + user.FirstName + ' ' + user.LastName + '"</span> record?</strong></p>' +
    '<p>All information associated to this user record will be permanently deleted.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'User Record';
    modalRef.result.then((res: any) => {
      this.userSer.deleteUser(id).subscribe((data: any) => {
        this.setPage(1);
      });
    })
    .catch( (res: any) => {
    });

  }

  filterByClient(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.clientname = id;
    // this.userSer.getUsersByClient(id).subscribe((data: any) => {
    //   this.users = data;
    // });
    this.setPage(1);
  }

  filterByLastName(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.name = id;
    this.setPage(1);
  }

  filterByEmail(event) {
    this.showloader = true;
    const searchStr = event.target.value;
    this.filter.email = searchStr;
    this.setPage(1);
  }

  setPage(page: number = 1) {
        const that = this;
        if (this.embedded) {
          this.filter.client_id = this.client_id;
          this.filter.partner_id = this.partner_id;
        }

        this.showloader = true;
        this.userSer
          .getAllUsers( page, this.per_page, this.row_header[this.header_index], this.filter)
          .subscribe( {
              next(data: any) {
                that.showloader = false;
                that.users = data.data; // return athe all data
                that.paging = data.page; // return all the paginationinfo
                that.total = that.paging.total;
                that.pager = that.pagerS.getPager(that.paging.total, page, that.per_page);
          },
          error(msg) {
            that.ngZone.run( () => {
              that.showloader = false;
            });
          }
        });
  }

  tableSort(index) {
    this.showloader = true;
    if (this.header_index === index) {
      (this.row_header[index].sort === 'asc') ? this.row_header[index].sort = 'desc' : this.row_header[index].sort = 'asc';
    } else {
      this.row_header[index].sort = 'asc';
      this.row_header[this.header_index].sort = null;
      this.header_index = index;
    }
    this.setPage(1);
  }


  // embedded functions
  expandTable() {
    $('#newUser').addClass('show');
  }

  swapIcon(event: any) {
    const expanded = $('#user-expand').hasClass('fa-expand-alt');
    if (expanded) {
      $('#user-expand').removeClass('fa-expand-alt').addClass('fa-compress-alt');
    } else {
      $('#user-expand').removeClass('fa-compress-alt').addClass('fa-expand-alt');
    }
    // window.scroll({
    //   top: event.pageY + 100,
    //   behavior: 'smooth'
    // });
  }

  collapseUser() {
    $('.user_edit.collapse.show').removeClass('show');
    $('#newUser.show').removeClass('show');

    this.setPage(1);
  }

  scrollView(event: any, id: any) {
    // window.scroll({
    //   top: event.pageY + 100,
    //   behavior: 'smooth'
    // });
  }

  downloadUsers() {
    const that = this;
    this.userSer
    .getUserDownload( this.row_header[this.header_index], this.filter)
    .subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const fileName = 'user-list.csv';

      const FileSaver = require('file-saver');
      // const blob2 = new Blob( blob, {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, fileName);

    });

  }

  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Information';
    modalRef.componentInstance.trustHtml = true;
    modalRef.result.then((res: any) => {
    });

  }


  getFile(event) {
    // this.files = <File>event.target.files[0];
    this.uploadFile = <File>event.target.files;
    this.overwriteClients();
  }

  overwriteClients() {
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Overwrite or update existing records. ' +
    '<p>We <strong>strongly</strong> recommend to <span class="text-danger">Add Only</span>.' +
    ' All information associated to those User record(s) will be permanently altered.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'User Upload';
    modalRef.componentInstance.okBtnTitle = 'Overwrite';
    modalRef.componentInstance.cancelBtnTitle = 'Add Only';
    modalRef.result.then((res: any) => {
       this.uploadUsers( '1' );
       return;
    }).catch((res: any) => {
      this.uploadUsers( '0' );
    });
  }

  uploadUsers( overwrite ) {
    const that = this;
    const data = new FormData();
    data.append('file', this.uploadFile[0], this.uploadFile[0].name);

    this.userSer.uploadUsers(data, overwrite).subscribe( (resp: any) => {
      const results = resp.results;
      let msg = '<p><strong>Imported ' + results[0] + '</br>' +
              'Duplicate records ' + results[1] +  '</br>';
      if (overwrite === '1') {
        msg += 'Overwritten records ' + results[2] + '</br>';
      }
      msg += 'Rows with invalid data ' + results[3] +
             '</strong></p>';
      this.displayMessage( msg );
    });
  }

}


