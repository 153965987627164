import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {map, catchError } from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  tickets: any;
  constructor(private http: HttpClient) { }

  //
  //
  getTickets(status = null, page, sorting, filtering, per_page = 8): Observable<any> {
    let add_url = '';
    if (filtering.subject_matter !== '') {
      add_url = '&subject_matter=' + filtering.subject_matter;
    }
    if (filtering.client !== '') {
      add_url = add_url + '&client=' + filtering.client;
    }
    if (filtering.requester !== '') {
      add_url = add_url + '&requester=' + filtering.requester;
    }
    if (filtering.assigned_to !== '') {
      add_url = add_url + '&assigned_to=' + filtering.assigned_to;
    }
    if (filtering.subject !== '') {
      add_url = add_url + '&subject=' + filtering.subject;
    }
    if (filtering.search !== '') {
      add_url = add_url + '&search=' + filtering.search;
    }
    if (filtering.ticket_number !== '' && typeof filtering.ticket_number !== 'undefined') {
      add_url = add_url + '&ticket_number=' + filtering.ticket_number;
    }

    if (status == null) {
      return this.http.get( environment.apiUrl + 'ticket/?page=' + page
                  + '&per_page=' + per_page + '&sort_by=' + sorting.value + '&sort_type='
                  + sorting.sort + '' + add_url)
        .pipe(map((res: any) => {
          // console.log(res);
          return res;
        }));
    } else {
        return this.http.get(environment.apiUrl + 'ticket/?page=' + page + '&per_page=' + per_page + '&status='
                    + status + '&sort_by=' + sorting.value + '&sort_type=' + sorting.sort
                    + '' + add_url)
        .pipe(map((res: any) => {
          return res;
        }));
    }
  }


  //
  // getSingleTicket -- need ID
  //
  getSingleTickets( id ) {
    return this.http.get( environment.apiUrl + 'ticket/' + id)
    .pipe(
      map( res => {
        return res;
      })
    );
  }


  //
  // Save Tickets
  //
  saveTickets(tikets, token) {
     this.http.post(environment.apiUrl + 'ticket/', this.tickets
     ).subscribe(data => {
     });
  }

  updateTickets(tickets, token, id) {
    // var xhr = new XMLHttpRequest;
    // xhr.open('POST', '/', true);
    // xhr.send(tickets);

    return this.http.post(environment.apiUrl + 'ticket/' + id, tickets);
    // .pipe(
    //       catchError(this.handleError)
    //   );
  }

  // handleError(error) {
  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     // client-side error
  //     errorMessage = `Error: ${error.error.message}`;
  //   } else {
  //     // server-side error
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   //window.alert(errorMessage);
  //   return throwError(errorMessage);
  // }

  deleteTicket(token, id) {
    return this.http.delete(environment.apiUrl + 'ticket/' + id);
  }

}
