import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { IClients } from '../model/clients';
import { rolesStatus} from '../model/roles';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class ClientService {

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }

  //
  // use jwt to look up client and return client record
  //
  getLoggedInClient() {
    return this.http.get(`${environment.apiUrl}client/client-by-jwt/`)
       .pipe(map(res => { return res; }))
       .subscribe((data: any) => {
          if (data.status) {

          }
       }
    );
  }

  getClients(): Observable<IClients> {
    return this.http.get(`${environment.apiUrl}client/all-clients/` )
       .pipe(map((res: any) => {
         return res.data;
       }));

  }

  //
  // Get Clients
  //
  getAllClients( page, per_page, sorting, filtering): Observable<any> {
    let add_url = '';
    if (filtering.clientname !== '') {
      add_url = add_url + '&clientname=' + filtering.clientname;
    }
    if (filtering.name !== '') {
      add_url = add_url + '&name=' + filtering.name;
    }
    if (filtering.email !== '') {
      add_url = add_url + '&active=' + filtering.active;
    }
    if (filtering.partner !== '') {
      add_url = add_url + '&partner=' + filtering.partner;
    }

    return this.http.get(`${environment.apiUrl}client/clients/` + '?page=' + page
                    + '&per_page=' + per_page + '&sort_by=' + sorting.value + '&sort_type='
                    + sorting.sort + add_url )
       .pipe(map(res => { return res; }));
  }


  //
  // Get Client Download
  //
  getClientDownload( sorting, filtering): Observable<ArrayBuffer> {
    console.log('in client download');
    let add_url = '';
    if (filtering.clientname !== '') {
      add_url = add_url + '&clientname=' + filtering.clientname;
    }
    if (filtering.name !== '') {
      add_url = add_url + '&name=' + filtering.name;
    }
    if (filtering.email !== '') {
      add_url = add_url + '&active=' + filtering.active;
    }
    if (filtering.partner !== '') {
      add_url = add_url + '&partner=' + filtering.partner;
    }

    let headers = new HttpHeaders();

    const options: {
        headers?: HttpHeaders;
        observe?: 'body';
        reportProgress?: boolean;
        responseType: 'arraybuffer';
        withCredentials?: boolean;
    } = {
        responseType: 'arraybuffer'
    };

    return this.http.get(`${environment.apiUrl}client/clients-csv/` + '?sort_by=' + sorting.value +
           '&sort_type=' + sorting.sort + add_url, options )
       .pipe( map((file: ArrayBuffer) => {
          return file;
       }));
  }

  //
  // Upload and import csv file
  //
  uploadClients(file: any, overwrite, withusers: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'client/clients-import-csv/?' +
                 'overwrite=' + overwrite + '&withusers=' + withusers, file )
           .pipe( map((resp: any) => {
                       return resp;
    }));
  }

  //
  // Creates a new Client record
  //
  createClient( form ) {

    return this.http.post(`${environment.apiUrl}client/`, form)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

  //
  // Update a client record
  //
  saveClient( client_id, form ) {
    return this.http.post(`${environment.apiUrl}client/` + client_id, form
    )
    .pipe(
      map((res: any) => {
        if (res.status) {
          return res.status;
        }
      })
    );
  }

  //
  // Retrieve Client with given client-id
  //
  getClient( client_id ): Observable <IClients> {
    return this.http.get(`${environment.apiUrl}client/` + client_id)
        .pipe(
           map((res: any) => {
             if (res.status) {
               return res.data;
             }
           })
        );
  }


  //
  // Delete a new client record
  //
  deleteClient( id ): Observable <any> {

    return this.http.delete(`${environment.apiUrl}client/` + id)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

}
