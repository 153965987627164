import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../auth/auth.service';
import { IUser } from '../../model/user';
import { CompleterService, CompleterData, RemoteData } from 'ng2-completer';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {'class': 'navbar navbar-dark navbar-expand-lg appbackground'}
})
export class HeaderComponent implements OnInit {
  public searchStr: string;
  // protected dataService: CompleterData;
  public dataService: CompleterData;
  modal: NgbModalRef;
  show: any = false;
  needClient: any = false;
  clients: any[] = [];
  currentClientId: any;

  protected searchData = [
    { color: 'red', id: '27' },
    { color: 'green', id: '26' },
    { color: 'blue', id: '25' },
    { color: 'cyan', id: '24' },
    { color: 'magenta', id: '23' },
    { color: 'yellow', id: '22' },
    { color: 'black', id: '21' }
  ];

  constructor( private modalService: NgbModal,
               private config: NgbDropdownConfig,
               private authService: AuthService,
               private completerService: CompleterService,
               private router: Router,
               private userSer: UserService) {
    config.placement = 'bottom-right';
    this.dataService = completerService.local(this.searchData, 'color', 'color');
  }

  permissionData: IUser = JSON.parse(localStorage.getItem(('UserData')));

  ngOnInit() {
    this.currentClientId = this.userSer.getClientId();
    if (this.userSer.getMultiClientStatus()) {
      this.needClient = true;
      this.clients = this.userSer.getMultiClientData();
    }
  }

  openModal(content, conentClass) {
    this.modal = this.modalService.open(content, {windowClass: conentClass});
  }

  searchSubject(event) {
    this.router.navigate(['/ticket/' + event.originalObject.id + '/edit']);
    this.modal.close();
    this.searchStr = '';
  }

  closeModal() {
    this.modal.close();
  }

  changeClients() {
    let cId = $('#client_id').val();
    cId = parseInt( cId.toString(), 10 ); // it is already a string but complile is super strict
    const cObj = this.clients.find( x => x.client.ID === cId );
    if (cObj !== undefined) {
      this.userSer.getUser( cObj.UserID ).subscribe( (res: any) => {
        this.authService.getLogin(res.EMail, res.Password).subscribe((data: any) => {
          if (data.status) {
            localStorage.clear();
            localStorage.setItem('token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('ClientID', data.ClientID);
            localStorage.setItem('UserData', JSON.stringify(data.UserData));
            // this.userSer.getLoggedInUser();
            localStorage.setItem('info', JSON.stringify(data));
            if (window.location.href.indexOf('dashboard') > 0) {
                window.location.href = window.location.href + '/';
            } else {
              this.router.navigate(['/dashboard']);
            }

          } else {
              // this.serverError = data.error;
          }
        });
      });
    } else {
      alert('Unable to locate client record. Please logout and back into the selected client');
    }

  }

  logout() {
    this.authService.logout();
  }

  userProfile() {
    this.router.navigate(['/admins/user', { 'personal' : true }]);
  }

  showSearch() {
    this.show = true;
  }
}

