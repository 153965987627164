import {Component, OnInit, Input, NgZone, EventEmitter, Output, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { RoleProfileService } from '../../services/roleprofile.service';
import { IRoleProfile } from '../../model/roleprofile';
import { IPage } from '../../model/page';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from 'src/app/services/client.service';
import { PartnerService } from 'src/app/services/partner.service';
import { UserService } from '../../services/user.service';

import * as jquery from 'jquery';
import { state } from '@angular/animations';
declare var $: any;

@Component({
  selector: 'app-roleprofile-list',
  templateUrl: './roleprofile-list.component.html',
  styleUrls: ['./roleprofile-list.component.scss'],
})

export class RoleProfileListComponent implements OnInit {
  @Input() client_id: number = null;
  @Input() partner_id: number = null;
  @Output() submitted: EventEmitter<boolean> = new EventEmitter<boolean>();


  isFilterTrue = true;
  // userValue: IUserValue;
  paging: IPage;
  totalRoleProfiles: number;
  per_page = 10;
  selected_status: number;
  filter: any = { 'name': '', 'role': ''};
  roleprofiles: any;
  partners: any;
  clients: any;
  userData: any;
  total: number = 0;
  public row_header: any = [];
  public header_index: any;
  public assignee_array: any = [];
  private allItems: any[];
  showloader: boolean;
  pager: any = {};
  pagedItems: any[];
  RoleTypes: any[];
  crumbList: any[];
  embedded: any = false;

  constructor(
    private pagerS: PagerService,
    private adminSer: AdminTiketService,
    private roleProfileSer: RoleProfileService,
    private clientSer: ClientService,
    private partnerSer: PartnerService,
    private modalService: NgbModal,
    private userSer: UserService,
    private ngZone: NgZone) {
      this.showloader = true;
  }

  ngOnInit() {
    this.userData = this.userSer.getUserData();

    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' }
    ];
    this.selected_status = null;

    this.RoleTypes = [
      { RoleType: 2, RoleName: 'Client User' },
      { RoleType: 3, RoleName: 'Partner User' },
      { RoleType: 6, RoleName: 'Agent' },
      { RoleType: 8, RoleName: 'System Admin' },
    ];

    this.row_header  = [
      {text: 'Client', value: 'ClientID', 'sort': null},
      {text: 'Name', value: 'Name', 'sort': 'asc' },
      {text: 'Role Type', value: 'Role', 'sort': null}
    ];
    this.header_index = 1;

    if (typeof(this.client_id) !== 'undefined' && this.client_id !== null) {
      this.embedded = true;
      this.row_header  = [
        {text: 'Client', value: 'ClientID', 'sort': null},
        {text: 'Name', value: 'Name', 'sort': 'asc' },
        {text: 'Role Type', value: 'Role', 'sort': null}
      ];
    } else { this.client_id = null; }

    if (typeof(this.partner_id) !== 'undefined' && this.partner_id !== null) {
      this.embedded = true;
      this.row_header  = [
        {text: 'Partner', value: 'PartnerID', 'sort': null},
        {text: 'Name', value: 'Name', 'sort': 'asc' },
        {text: 'Role Type', value: 'Role', 'sort': null}
      ];
    } else { this.partner_id = null; }


    this.partnerSer.getPartners().subscribe( (pData: any) => {
      this.partners = pData;

      this.clientSer.getClients().subscribe( (cData: any) => {
          this.clients = cData;
          this.tableSort(0);
          this.showloader = false;
        });
    });

  }

  getRoleType( utype: number ) {
    const role = this.RoleTypes.find( u => u.RoleType === utype);
    if (!role) {
      return 'Unknown Role Type ' + utype;
    }

    return role.RoleName;
  }

  findLocalRoleProfile( id: number ) {
    return this.roleprofiles.find( p => p.ID === id);
  }

  deleteRoleProfile(id: number) {
    const roleprofile = this.findLocalRoleProfile( id );
    if (roleprofile === null) {
      alert('Unable to find Role Profile');
      return;
    }
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to delete ' +
    '<span class="text-primary">"' + roleprofile.Name + '"</span> record?</strong></p>' +
    '<p>All information associated to this record will be permanently deleted.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'Role Profile Record';
    modalRef.result.then((res: any) => {
      this.roleProfileSer.deleteRoleProfile(roleprofile.ID).subscribe((data: any) => {
        if (this.embedded) {
          this.showloader = true;
          this.submitted.emit( true );
        } else {
          this.setPage(1);
        }
      });
    })
    .catch( (res: any) => {
    });

  }


  filterByName(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.name = id;
    this.setPage(1);
  }

  filterByClient(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.client_id = id;
    this.setPage(1);
  }


  populateProfile() {
    if (!this.clients || !this.partners) {
      return;
    }

    this.roleprofiles.forEach( (profile) => {
      if (profile.Role !== 3) {
        profile.client = this.clients.find( x => x.ID === profile.ClientID );
      } else {
        profile.partner = this.partners.find( x => x.ID === profile.PartnerID );
      }
      profile.RoleTypeName = this.getRoleType( profile.Role );
    });
  }

  setPage(page: number = 1) {
        const that = this;
        if (this.embedded) {
          if (this.client_id != null) {
            this.filter.client_id = this.client_id;
          } else if (this.partner_id != null) {
            this.filter.partner_id = this.partner_id;
          }
        }

        this.showloader = true;
        this.roleProfileSer
          .getAllRoleProfiles( page, this.per_page, this.row_header[this.header_index], this.filter)
          .subscribe( {
              next(data: any) {
                that.showloader = false;
                that.roleprofiles = data.data; // return athe all data
                that.populateProfile();
                that.paging = data.page; // return all the paginationinfo
                that.total = that.paging.total;
                that.pager = that.pagerS.getPager(that.paging.total, page, that.per_page);
          },
          error(msg) {
            that.ngZone.run( () => {
              that.showloader = false;
            });
          }
        });
  }

  tableSort(index) {
    this.showloader = true;
    if (this.header_index === index) {
      (this.row_header[index].sort === 'asc') ? this.row_header[index].sort = 'desc' : this.row_header[index].sort = 'asc';
    } else {
      this.row_header[index].sort = 'asc';
      this.row_header[this.header_index].sort = null;
      this.header_index = index;
    }
    this.setPage(1);
  }


  // embedded functions
  expandTable() {
    $('#newRoleProfile').addClass('show');
  }

  swapIcon(event: any) {
    const expanded = $('#roleprofile-expand').hasClass('fa-expand-alt');
    console.log('in swap' + expanded);
    if (expanded) {
      $('#roleprofile-expand').removeClass('fa-expand-alt').addClass('fa-compress-alt');
    } else {
      $('#roleprofile-expand').removeClass('fa-compress-alt').addClass('fa-expand-alt');
    }
    // window.scroll({
    //   top: event.pageY + 100,
    //   behavior: 'smooth'
    // });
  }

  collapseRoleProfile() {
    $('.roleprofile_edit.collapse.show').removeClass('show');
    $('#newRoleProfile.show').removeClass('show');

    // need to push event upstream so the whole page can get refreshed
    if (this.embedded) {
       this.submitted.emit( true );
    } else {
      this.setPage(1);
    }
  }

  scrollView(event: any, id: any) {
    // window.scroll({
    //   top: event.pageY + 100,
    //   behavior: 'smooth'
    // });
  }

}


