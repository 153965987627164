import {Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-hiring',
  templateUrl: './hiring.component.html',
  styleUrls: ['./hiring.component.scss'],
})

export class HiringComponent implements OnInit {
  buttonSelected: any;
  currentUser: any;
  constructor(
    private adminSer: AdminTiketService,
    private modalService: NgbModal,
    private actRoute: ActivatedRoute,
    private ngZone: NgZone) {
  }

  ngOnInit() {
    const userData = localStorage.getItem('UserData');
    this.currentUser = JSON.parse(userData);

    this.actRoute.params.subscribe( params => {
      const page = params['id'];
      if (page === '1') {
        this.buttonSelected = 'overview';
      } else if (page === '2') {
        this.buttonSelected = 'job-postings';
      } else if (page === '3') {
        this.buttonSelected = 'interview-process';
      } else {
        this.buttonSelected = 'overview';
      }
    });
  }

  selectButton($event) {
    this.buttonSelected = $event.target.id;
  }


}


