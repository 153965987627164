import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { ICode } from '../model/code';
import { rolesStatus} from '../model/roles';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class CodeService {

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }


  getAllCodes(): Observable<ICode> {
    return this.http.get(`${environment.apiUrl}code` )
       .pipe(map((res: any) => {
         return res.data;
       }));

  }

  getCodeByType( codeType: any ): Observable<ICode> {
    return this.http.get(`${environment.apiUrl}code/by-type?type=` + codeType )
       .pipe(map((res: any) => {
         return res.data;
       }));
  }

  getSubjectMatter(): Observable<ICode> {
    return this.getCodeByType( 'SUBJECT_MATTER' );
  }

  getServices(): Observable<ICode> {
    return this.getCodeByType('SERVICES');
  }

  //
  // Get Partners
  //
  // getAllPartners( page, per_page, sorting, filtering): Observable<any> {
  //   let add_url = '';
  //   if (filtering.companyname !== '') {
  //     add_url = add_url + '&companyname=' + filtering.companyname;
  //   }
  //   if (filtering.contactname !== '') {
  //     add_url = add_url + '&contactname=' + filtering.contactname;
  //   }
  //   if (filtering.status !== '') {
  //     add_url = add_url + '&status=' + filtering.status;
  //   }

  //   return this.http.get(`${environment.apiUrl}partner/partners/` + '?page=' + page
  //                   + '&per_page=' + per_page + '&sort_by=' + sorting.value + '&sort_type='
  //                   + sorting.sort + add_url )
  //      .pipe(map(res => { return res; }));
  // }

  //
  // Creates a new Partner record
  //
  // createCode( form ) {

  //   return this.http.post(`${environment.apiUrl}code/`, form)
  //     .pipe(
  //       map((res: any) => {
  //         if (res.status) {
  //           return res;
  //         }
  //       })
  //     );
  // }

  //
  // Update a Code record
  //
  // saveCode( code_id, form ) {
  //   return this.http.post(`${environment.apiUrl}code/` + code_id, form
  //   )
  //   .pipe(
  //     map((res: any) => {
  //       if (res.status) {
  //         return res.status;
  //       }
  //     })
  //   );
  // }

  //
  // Retrieve Partner with given partner-id
  //
  // getCode( code_id ): Observable <ICode> {
  //   return this.http.get(`${environment.apiUrl}code/` + code_id)
  //       .pipe(
  //          map((res: any) => {
  //            if (res.status) {
  //              return res.data;
  //            }
  //          })
  //       );
  // }


  //
  // Delete a code record
  //
  // deleteCode( id ): Observable <any> {

  //   return this.http.delete(`${environment.apiUrl}code/` + id)
  //     .pipe(
  //       map((res: any) => {
  //         if (res.status) {
  //           return res;
  //         }
  //       })
  //     );
  // }

}
