import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';


@Component({
  selector: 'app-modal-component',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body" [innerHTML]="confirmationStr" >
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" ngbAutofocus  (click)="modal.dismiss('cancel click')">{{cancelBtnTitle}}</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">{{okBtnTitle}}</button>
  </div>
  `
})
export class ModalComponent implements OnInit {
  @Input() confirmationStr = '<p><strong>Are you sure you want to delete ' +
  '<span class="text-primary">"John Doe"</span> profile?</strong></p>' +
  '<p>All information associated to this user profile will be permanently deleted.' +
  '<span class="text-danger">This operation can not be undone.</span>' +
  '</p>';
  @Input() title = 'Profile Deletion';
  @Input() cancelBtnTitle = 'Cancel';
  @Input() okBtnTitle = 'Ok';

  html_body: any;

  constructor(public modal: NgbActiveModal) {
  }

  ngOnInit() {

  }

  dismiss() {
    this.modal.dismiss( 'cancel' );
  }
}
