import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { InfoModalComponent } from '../../shared/info-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  host: {'class': 'login'}
})
export class ForgotPasswordComponent implements OnInit {
  isSubmit: boolean = false;
  submitted: boolean = false;
  emailFocus = false;
  form: FormGroup;
  isError: any = '';
  needClient: any = false;
  clients: any[] = [];

  constructor(private fb: FormBuilder, 
    private router: Router,
    private authS: AuthService,
    private infoModalService: NgbModal
    ) {
  }

  ngOnInit() {
   this.form = this.fb.group({
    emailadd: ['', [Validators.required, Validators.email]]
   });
  }

  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Warning';
    modalRef.result.then((res: any) => {
    });

  }


  get f() { return this.form.controls; }
  /**
   * Submit
   */
  forgotPasswordSubmit() {
    this.submitted = true;
   // stop here if form is invalid
   if (this.form.invalid) {
       return;
   }
   if (this.form.valid) {
      const data = new FormData();
      data.append('email', this.form.value.emailadd);
      if (this.needClient) {
        const preId = $('#client_id').val();
        // work around for compiler error = stupid compliler
        const cId = preId.toString();
        data.append('client_id', cId );
      }
      this.authS.forgotPassword(data).subscribe((data: any) => {
                console.log(data);
                if (data.status === true) {
                  this.isSubmit = true;
                } else {
                  if (data.multiple) {
                    this.displayMessage( data.message );
                    this.clients = data.clients;
                    this.needClient = true;
                  } else {
                     this.isError = data.message;
                  }
                }
              });
    }

  }

  autofocus() {
      this.emailFocus = true;
  }
}
