import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { IAuth } from '../model/auth';
import { rolesStatus} from '../model/roles';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }


  getAllAuths(): Observable<IAuth> {
    return this.http.get(`${environment.apiUrl}authorizations/` )
       .pipe(map((res: any) => {
         return res.data;
       }));

  }


}
