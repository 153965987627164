import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
// import { DataTablesModule } from 'angular-datatables';
// import { DataTableModule } from 'angular-6-datatable';
import { BreadCrumbComponent } from './breadcrumb.component';
import { RouterModule, Router } from '@angular/router';
import { CallbackPipe } from './callback.pipe';
import { SafeHtml, SafeUrl } from './safehtml';
import { PostFilterPipe } from './filter.pipe';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // DataTablesModule,
    // DataTableModule,
    RouterModule,
    // NgbModule.forRoot()
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports:[
    FormsModule,
    NgbModule ,
    ReactiveFormsModule,
    // DataTablesModule,
    // DataTableModule,
    BreadCrumbComponent,
    RouterModule,
    CallbackPipe,
    PostFilterPipe,
    SafeHtml,
    SafeUrl
  ],
  declarations: [
    BreadCrumbComponent,
    CallbackPipe,
    PostFilterPipe,
    SafeHtml,
    SafeUrl
  ]
})
export class SharedModule {}


