import {Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { IPage } from '../../model/page';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
})

export class TemplateListComponent implements OnInit {
  users: any;
  isFilterTrue = true;
  // userValue: IUserValue;
  paging: IPage;
  totalUsers: number;
  per_page = 10;
  selected_status: number;
  filter: any = { 'clientname': '', 'name': '', 'email': ''};
  templates: any;
  curr_user_id: any;
  total: number = 0;
  public row_header: any = [];
  public header_index: any;
  showloader: boolean;
  pager: any = {};
  pagedItems: any[];
  crumbList: any[];

  constructor(
    private pagerS: PagerService,
    private templateSer: TemplateService,
    private modalService: NgbModal,
    private ngZone: NgZone) {
      this.showloader = true;
  }

  ngOnInit() {
    const userData = localStorage.getItem('UserData');
    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' }
    ];
    this.curr_user_id = JSON.parse(userData);
    let that = this;
    this.selected_status = null;
    // this.templateSer.getTemplates().subscribe((data: any) => {
    //   this.templates = data;
    // });

    this.row_header  = [
      {text: 'Name', value: 'Template Name', 'sort': null}
    ];
    this.header_index = 0;

    this.tableSort(0);
  }

  findLocalTemplate( name: number ) {
     for ( var idx = 0; idx < this.templates.length; idx++ ) {
        const tmpl = this.templates[idx];
        if (tmpl.Name === name) {
          return tmpl;
        }
     }
     return null;
  }

  filterByName(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.name = id;
    this.setPage(1);
  }

  setPage(page: number = 1) {
        const that = this;

        this.showloader = true;
        this.templateSer
          .getTemplates()
          .subscribe( {
              next(data: any) {
                that.showloader = false;
                that.templates = data.data; // return athe all data
                that.paging = data.page; // return all the paginationinfo
                that.total = that.paging.total;
                that.pager = that.pagerS.getPager(that.paging.total, page, that.per_page);
          },
          error(msg) {
            that.ngZone.run( () => {
              that.showloader = false;
            });
          }
        });
  }

  tableSort(index) {
    this.showloader = true;
    if (this.header_index === index) {
      (this.row_header[index].sort === 'asc') ? this.row_header[index].sort = 'desc' : this.row_header[index].sort = 'asc';
    } else {
      this.row_header[index].sort = 'asc';
      this.row_header[this.header_index].sort = null;
      this.header_index = index;
    }
    this.setPage(1);
  }


}


