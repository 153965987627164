import { Component, OnInit, AfterViewInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { UserService } from '../../services/user.service';
import { IPost } from '../../model/post';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { PostService } from '../../services/post.service';
import { DocumentService } from '../../services/document.service';
import { PartnerService } from '../../services/partner.service';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { InfoModalComponent } from '../../shared/info-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../shared/modal.component';
declare var $: any;

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  @Input() id: any;
  @Input() parent_id: any;
  @Input() parent_name: any;
  @Output() submitted: EventEmitter<boolean> = new EventEmitter<boolean>();
  // @ViewChild('froalaEditor', null) froalaEditor;

  post: IPost;
  posts: any;
  partners: any;
  active: any;
  showloader = true;
  successful_result = false;
  error_result = false;
  dropdownList = [];
  dropdownSettings: {};
  form: FormGroup;
  title: string;
  post_results: string;
  private subscription: Subscription;
  private timer: Observable<any>;
  crumbList: any[];
  fEditor: any;
  sectionTable: any[] = [];
  docTable: any[] = [];
  faqTable: any[] = [];
  overview: any[] = [];
  documents: any;
  trainTable: any[] = [];

  NameCtrl: FormControl;
  OrderCtrl: FormControl;
  JSCtrl: FormControl;
  StyleCtrl: FormControl;
  BodyText: any;
  // private _element: HTMLElement;

  public options: Object = {
    // original key
    // key: 'dC10B5D5A2A-9H3E2J2A4C6C3C2B5B1E1pj1wgbrD-11fzfsB-22B3zuv==',
    // 2.8.5 key
    key: 'eC6D6C2E3E2H2xC4B3A3C2B5A1B1E4F1A2sd1Te1LXe1URVJe1DWXG==',
    // 3+ key
    // key: 'eHE5C-11B2C1F2I2A5C4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4D3E4C2G2E3C1B2==',
    attribution: false,
    placeHolderText: 'something here',
    // toolbarInline: true,
    height: 190
  };

  constructor(
    private http: HttpClient,
    private userSer: UserService,
    private postSer: PostService,
    private adminSer: AdminTiketService,
    private docSer: DocumentService,
    private fb: FormBuilder,
    private infoModalService: NgbModal,
    private modalService: NgbModal,
    private actRoute: ActivatedRoute,
    private router: Router ) {

      this.showloader = true;
      // this._element = element.nativeElement;
  }

  ngOnInit() {
    this.showloader = true;
    this.NameCtrl = new FormControl('Name', [Validators.required]);
    this.OrderCtrl = new FormControl('Order', [Validators.required]);
    this.JSCtrl = new FormControl('JS' );
    this.StyleCtrl = new FormControl('Style' );

    this.form = this.fb.group({
      Name: this.NameCtrl,
      Order: this.OrderCtrl,
      JS: this.JSCtrl,
      Style: this.StyleCtrl
    });

    // this.actRoute.params.subscribe( params =>
    //   this.id = params['id']
    // );
    if (this.id === undefined || this.id === 0) {
      this.id = 0;
      this.title = 'New Post';
      this.form.patchValue({
        Name: '',
        Order: '',
        JS: '',
        Style: 1
      });

      this.showloader = false;
      // let editor = new FroalaEditor('#post' + this.id,
      //     this.options
      // );
    } else {
      this.title = 'Edit Post';
      this.docSer.getDocumentData().subscribe((docData: any) => {
        this.documents = docData;
        this.documents = this.documents.filter( x => x.Category === this.parent_name );

        this.postSer.getPost(this.id).subscribe((postData: any) => {
          if (postData.Style === null) {
            postData.Style = 1;
          }
          this.form.patchValue({
                Name: postData.Name,
                Order: postData.Order,
                JS: postData.JS,
                Style: postData.Style
          });
          this.parseJsonFields( postData );
          const bodyText = postData.Body;
          $('#post' + this.id).froalaEditor('html.set', bodyText );

          this.showloader = false;
        });
      });
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    const userData = localStorage.getItem('UserData');
  }

  parseJsonFields( post ) {
    try {
       this.sectionTable = JSON.parse( post.Resource );
       if (this.sectionTable === null) {
         this.sectionTable = [];
       } else {
          for (var i = 0; i < this.sectionTable.length; i++ ) {
            let sect = this.sectionTable[i];
            this.reorderList( sect.docs );
            for (var dIdx = 0; dIdx < sect.docs.length; dIdx++ ) {
              let rec = sect.docs[dIdx];
              const docId = parseInt( rec.docId, 10 );
              const idx = this.documents.findIndex( x => x.ID === docId);
              if (idx > -1) {
                rec.name = this.documents[idx].Name;
              }
            }
          }
       }
       this.reorderList( this.sectionTable );
    } catch (e) {
       this.sectionTable = [];
       console.log( 'error with section table');
    }


    try {
      this.overview = JSON.parse( post.Form );
      if (this.overview === null) {
        this.overview = [];
      } else {
         for (var i = 0; i < this.overview.length; i++ ) {
            let rec = this.overview[i];
            const docId = parseInt( rec.docId, 10 );
            const idx = this.documents.findIndex( x => x.ID === docId);
            if (idx > -1) {
              rec.name = this.documents[idx].Name;
            }
         }

      }
    } catch(e) {
      this.overview = [];
      console.log('error for overview');
    }

    try {
      this.faqTable = JSON.parse( post.FAQ );
      if (this.faqTable === null) {
        this.faqTable = [];
      } else {
        //  for (var i = 0; i < this.overview.length; i++ ) {
        //     let rec = this.overview[i];
        //     const docId = parseInt( rec.docId, 10 );
        //     const idx = this.documents.findIndex( x => x.ID === docId);
        //     if (idx > -1) {
        //       rec.name = this.documents[idx].Name;
        //     }
        //  }
      }
    } catch(e) {
      this.faqTable = [];
      console.log('error for faq');
    }

    try {
      this.trainTable = JSON.parse( post.Training );
      if (this.trainTable === null) {
        this.trainTable = [];
      } else {
        //  for (var i = 0; i < this.overview.length; i++ ) {
        //     let rec = this.overview[i];
        //     const docId = parseInt( rec.docId, 10 );
        //     const idx = this.documents.findIndex( x => x.ID === docId);
        //     if (idx > -1) {
        //       rec.name = this.documents[idx].Name;
        //     }
        //  }
      }
    } catch(e) {
      this.trainTable = [];
      console.log('error for Training');
    }

  }

  reorderList( list: any[] ) {
    for (let idx = 0; idx < list.length; idx++) {
      list[idx].id = idx;
    }
  }

  findUniqueIndex( list: any[] ) {
    let highest = -1;
    for (let idx = 0; idx < list.length; idx++) {
      if (list[idx].id > highest) {
        highest = list[idx].id;
      }
    }
    ++highest;
    return highest;
  }

  checkForAutoSave() {
    const newSectionTitle = $('#newSectionTitle-' + this.id).val();
    const newSectionStyle = $('#newSectionStyle-' + this.id).val();
    const newSectionCollapse = $('#newSectionCollapse-' + this.id).val();
    if (newSectionStyle !== '0' && newSectionTitle) {
      this.sectionTable.push( {
        id: this.findUniqueIndex( this.sectionTable ),
        title: newSectionTitle,
        style: newSectionStyle,
        collapse: newSectionCollapse,
        docs: []
      });
      this.clearSection();
    }
  }

  updateSection( sectId: any ) {
    const sId = this.sectionTable.findIndex( x => x.id === sectId );
    if (sId >= 0) {
      const obj = this.sectionTable[sId];
      const title = $('#title-' + this.id + '-' + sectId).val();
      const style = $('#style-' + this.id + '-' + sectId).val();
      const collapse = $('#collapse-' + this.id + '-' + sectId).val();
      obj.title = title;
      obj.style = style;
      obj.collapse = collapse;
    }
  }

  clearSection() {
    $('#newSectionTitle-' + this.id).val('');
    $('#newSectionStyle-' + this.id).val(1);
    $('#newSectionRow-' + this.id).collapse('hide');
  }

  saveDoc(sectId) {
    const docId = $('#newDoc-' + this.id + '-' + sectId).val();
    const sId = this.sectionTable.findIndex( x => x.id === sectId );
    if (sId === -1) {
      alert('Unable to locate sub section');
      return;
    }

    this.sectionTable[sId].docs.push( {
      id: this.findUniqueIndex( this.sectionTable[sId].docs ),
      docId: docId,
      name: $('#newDoc-' + this.id + '-' + sectId + ' option:selected').text().trim()
    });
    $('#newDoc-' + this.id + '-' + sectId).val('0');

  }

  saveOverDoc() {
    const docId = $('#newOverDoc-' + this.id).val();
    if (docId !== '0') {
      this.overview.push( {
        id: this.findUniqueIndex( this.overview ),
        docId: docId,
        name: $('#newOverDoc-' + this.id + ' option:selected').text().trim()
      });
      $('#newOverDoc-' + this.id + '-').val('0');
    }
  }

  // FAQ helpers
  checkForFAQAutoSave() {
    const newFAQQuestion = $('#newFAQQuestion-' + this.id).val();
    const newFAQAnswer = $('#newFAQAnswer-' + this.id).val();
    if (newFAQQuestion.length > 0 && newFAQAnswer.length > 0) {
      this.faqTable.push( {
        id: this.findUniqueIndex( this.faqTable ),
        question: newFAQQuestion,
        answer: newFAQAnswer
      });
      this.clearFAQ();
    }
  }

  updateFAQ( faqId: any ) {
    if (this.faqTable.length > faqId - 1) {
      const obj = this.faqTable[faqId];
      const question = $('#question-' + this.id + '-' + faqId).val();
      const answer = $('#answer-' + this.id + '-' + faqId).val();
      obj.question = question;
      obj.answer = answer;
    }
  }

  clearFAQ() {
    $('#newFAQQuestion-' + this.id).val('');
    $('#newFAQAnswer-' + this.id).val('');
    $('#newFAQRow-' + this.id).collapse('hide');
  }

  // Training helpers
  checkForTrainAutoSave() {
    const newTrainTitle = $('#newTrainTitle-' + this.id).val();
    const newTrainOverview = $('#newTrainOverview-' + this.id).val();
    const newTrainLink = $('#newTrainLink-' + this.id).val();
    if (newTrainTitle.length > 0 && newTrainOverview.length > 0 && newTrainLink.length > 0) {
      this.trainTable.push( {
        id: this.findUniqueIndex( this.trainTable ),
        title: newTrainTitle,
        overview: newTrainOverview,
        link: newTrainLink
      });
      this.clearTrain();
    }
  }

  updateTrain( tId: any ) {
    if (this.trainTable.length > tId - 1) {
      const obj = this.trainTable[tId];
      const title = $('#title-' + this.id + '-' + tId).val();
      const overview = $('#overview-' + this.id + '-' + tId).val();
      const link = $('#link-' + this.id + '-' + tId).val();
      obj.title = title;
      obj.overview = overview;
      obj.link = link;
    }
  }

  clearTrain() {
    $('#newTrainTitle-' + this.id).val('');
    $('#newTrainOverview-' + this.id).val('');
    $('#newTrainLink-' + this.id).val('');
    $('#newTrainRow-' + this.id).collapse('hide');
  }



  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Warning';
    modalRef.result.then((res: any) => {
    });

  }


  showFields() {
    const controls = this.form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            console.log( name );
        }
    }

  }

  toggleSection() {
    const style = this.form.value.Style;
    $('#postForm' + this.id).find('.collapse.show').collapse('hide');
    if (style === '2') {
      $('#training' + this.id).collapse('show');
    } else if (style === '3') {
      $('#faq' + this.id).collapse('show');
    } else if (style === '4') {
      $('#section' + this.id).collapse('show');
      $('#newSectionRow-' + this.id).collapse('show');
    } else if (style === '5') {
      $('#overview' + this.id).collapse('show');
    }

    //  else {
    //   $('#section' + this.id).collapse('hide');
    //   $('#newSectionRow-' + this.id).collapse('hide');
    // }
  }

  savePost() {
    // this.submitted = true;
    this.showloader = true;

    this.showFields();

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showloader = false;
      return;
    }

    if (this.form.valid) {
      const fd = new FormData();

      fd.append('Name', this.form.value.Name);
      fd.append('Order', this.form.value.Order);
      fd.append('Body', $('#post'+this.id).froalaEditor('html.get'));
      fd.append('PageId', this.parent_id);
      fd.append('Style', this.form.value.Style);
      fd.append('Resource', JSON.stringify(this.sectionTable ));
      fd.append('Form', JSON.stringify(this.overview));
      fd.append('FAQ', JSON.stringify(this.faqTable));
      fd.append('Training', JSON.stringify(this.trainTable));
      if (this.form.value.JS) {
        fd.append('JS', this.form.value.JS );
      }

      if (this.id === 0) {
         this.postSer.createPost(fd).subscribe((res: any) => {
            if (res.status) {
              this.successful_result = true;
            } else {
              this.error_result = true;
              console.log('unable to save');
            }
            this.setTimer();
        });
      } else {
        this.postSer.savePost(this.id, fd ).subscribe((res: any) => {
          if (res) {
             this.successful_result = true;
          } else {
            this.error_result = true;
            console.log('unable to save');
          }
          this.setTimer();
        });
      }
      this.showloader = false;
    }
  }

  public setTimer() {
    this.timer = Observable.timer(1000);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
        if (this.id === 0) {
          this.form.patchValue({
            Name: '',
            Style: 1,
            Order: '',
            JS: ''
          });
        }
        $('#post' + this.id).froalaEditor('html.set', '' );
        this.submitted.emit( true );
      });
  }

  toggleChange(event: any) {
    this.active = (event.target.checked ? 'Yes' : 'No');
  }


  deleteSection( sectId: any ) {
    const sIdx = this.sectionTable.findIndex( x => x.id === sectId );
    if (sIdx < 0) {
      alert('Unable to find Section');
      return;
    }
    const sectionTitle = this.sectionTable[sIdx].title;

    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to remove ' +
    '<span class="text-primary">"' + sectionTitle + '"</span> record?</strong></p>' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'Section';
    modalRef.result.then((res: any) => {
      this.sectionTable.splice( sIdx, 1 );
    })
    .catch( (res: any) => {
    });

  }


  deleteDoc( sectId: any, docId: any ) {
    const sIdx = this.sectionTable.findIndex( x => x.id === sectId );
    if (sIdx < 0) {
      alert('Unable to locate the Section');
      return;
    }
    const dIdx = this.sectionTable[sIdx].docs.findIndex( x => x.id === docId );
    if (dIdx < 0) {
      alert('Unable to find Document');
      return;
    }
    const docName = this.sectionTable[sIdx].docs[dIdx].name;

    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to remove ' +
    '<span class="text-primary">"' + docName + '"</span> record?</strong></p>' +
    '</p>';
    modalRef.componentInstance.title = 'Document';
    modalRef.result.then((res: any) => {
      this.sectionTable[sIdx].docs.splice( dIdx, 1 );
    })
    .catch( (res: any) => {
    });
  }

  deleteOverDoc( docId: any ) {
    const dIdx = this.overview.findIndex( x => x.id === docId );
    if (dIdx < 0) {
      alert('Unable to find Document');
      return;
    }
    const docName = this.overview[dIdx].name;

    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to remove ' +
    '<span class="text-primary">"' + docName + '"</span> record?</strong></p>' +
    '</p>';
    modalRef.componentInstance.title = 'Document';
    modalRef.result.then((res: any) => {
      this.overview.splice( dIdx, 1 );
    })
    .catch( (res: any) => {
    });

  }

  deleteFAQ( faqId: any ) {
    const fIdx = this.faqTable.findIndex( x => x.id === faqId );
    if (fIdx < 0) {
      alert('Unable to find FAQ');
      return;
    }
    const faqName = this.faqTable[fIdx].question;

    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to remove ' +
    '<span class="text-primary">"' + faqName + '"</span> record?</strong></p>' +
    '</p>';
    modalRef.componentInstance.title = 'FAQ';
    modalRef.result.then((res: any) => {
      this.faqTable.splice( fIdx, 1 );
    })
    .catch( (res: any) => {
    });
  }

  deleteTrain( trainId: any ) {
    const tIdx = this.trainTable.findIndex( x => x.id === trainId );
    if (tIdx < 0) {
      alert('Unable to find Training');
      return;
    }
    const trainName = this.trainTable[tIdx].title;

    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to remove ' +
    '<span class="text-primary">"' + trainName + '"</span> record?</strong></p>' +
    '</p>';
    modalRef.componentInstance.title = 'Training';
    modalRef.result.then((res: any) => {
      this.trainTable.splice( tIdx, 1 );
    })
    .catch( (res: any) => {
    });
  }


}
