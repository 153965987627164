import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { IClients } from '../model/clients';
import { IUser } from '../model/user';
import { rolesStatus} from '../model/roles';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class MailService {
  private userApi = 'email/';

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }

  //
  // Send an Email
  //
  sendEmail( template: any, email: any[], data: object, subject: any, cc: any[], bcc: any[]  ) {

    let params = 'template=' + template;
    for (let idx = 0; idx < email.length; idx++) {
      params += '&email=' + email[idx];
    }
    params += '&data=' + JSON.stringify( data );
    params += '&subject=' + subject;

    return this.http.get(`${environment.apiUrl}email/` + '?' + params )
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }


}
