import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { MessageService } from '../../services/message.service';
import { Subscription } from 'rxjs';
import { PartnerService } from 'src/app/services/partner.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    host: { 'class': 'login main-screen' }
})
export class LoginComponent implements OnInit {
    registerForm: FormGroup;
    submitted = false;
    emailFocus = false;
    passwordFocus = false;
    serverError: any = '';
    token;
    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    form: FormGroup;
    message: any;
    subscription: Subscription;

    constructor(
        private authS: AuthService,
        private fb: FormBuilder,
        private router: Router,
        private userSer: UserService,
        private partnerSer: PartnerService,
        private msg: MessageService
    ) {
        if (localStorage.getItem('token') && false ) {
            this.router.navigate(['/dashboard']);
        }

        this.subscription = this.msg.getMessage().subscribe(message => { this.message = message; });
    }

    ngOnInit() {
        this.registerForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            check: ''
        });
    }


    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            this.submitted = true;
            return;
        } else {
            this.authS.getLogin(this.registerForm.value.email, this.registerForm.value.password).subscribe((data: any) => {
                if (data.status) {
                    localStorage.clear();
                    localStorage.setItem('token', data.access_token);
                    localStorage.setItem('refresh_token', data.refresh_token);
                    localStorage.setItem('ClientID', data.UserData.ClientID);
                    localStorage.setItem('UserData', JSON.stringify(data.UserData));
                    // this.userSer.getLoggedInUser();
                    localStorage.setItem('info', JSON.stringify(data));

                    localStorage.setItem('notSubscribed', 'Your subscription does not include access to this feature. ' +
                          'If you are interested in learning more, please email info@myhrcounsel.com or call (612) 339-1421');
                    localStorage.setItem('showErisa', 'yes');
                    if (data.UserData.client && data.UserData.client.PartnerID) {
                        this.partnerSer.getPartner(data.UserData.client.PartnerID).subscribe((partnerData: any) => {
                            if (partnerData.ServiceSolicitation !== null &&
                                partnerData.ServiceSolicitation.trim().length > 0) {
                                  localStorage.setItem('notSubscribed', partnerData.ServiceSolicitation);
                            }
                            if (partnerData.ErisaCenter != null) {
                                localStorage.setItem('showErisa', (partnerData.ErisaCenter === 1 ? 'yes' : 'no'));
                            }
                            if (partnerData.ToolsList != null) {
                                localStorage.setItem('toolsList', partnerData.ToolsList );
                            } else {
                                localStorage.removeItem( 'toolsList' );
                            }
                        });
                    }


                    this.router.navigate(['/dashboard']);
                } else {
                    this.serverError = data.error;
                }
            });
        }

    }

  autofocus(text) {
      if (text === 'email') {
        this.emailFocus = true;
        this.passwordFocus = false;
      } else {
        this.passwordFocus = true;
        this.emailFocus = false;
      }
  }

    /*
     ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }
    */
}

