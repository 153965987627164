import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../../services/template.service';
import { IPage } from '../../model/page';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
// import { formControlBinding } from '@angular/forms/src/directives/ng_model';
// import { validateConfig } from '@angular/router/src/config';
declare var $: any;

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {
  page: IPage;
  name: any;
  status: any;
  showloader = true;
  successful_result = false;
  error_result = false;
  dropdownList = [];
  dropdownSettings: {};
  form: FormGroup;
  title: string;
  submitted = false;
  post_results: string;
  private subscription: Subscription;
  private timer: Observable<any>;
  crumbList: any = [];
  // editor: any;

  public options: Object = {
    key: 'eC6D6C2E3E2H2xC4B3A3C2B5A1B1E4F1A2sd1Te1LXe1URVJe1DWXG==',
    // key: 'dC10B5D5A2A-9H3E2J2A4C6C3C2B5B1E1pj1wgbrD-11fzfsB-22B3zuv==',
    // key: 'eHE5C-11B2C1F2I2A5C4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4D3E4C2G2E3C1B2==',
    attribution: false,
  };

  constructor(
    private http: HttpClient,
    private templateSer: TemplateService,
    private fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private router: Router ) {

      this.showloader = true;
  }

  ngOnInit() {
    this.showloader = true;
    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' },
      { title: 'Template List', url: '/admins/template-list' }
    ];

    this.form = this.fb.group({
      Template: new FormControl()
    });
    this.actRoute.params.subscribe( params =>
      this.name = params['name']
    );
    this.title = 'Edit Template';
    this.templateSer.getTemplate(this.name).subscribe((data: any) => {
      this.form.patchValue({
           Template: this.convertToString( data.Template )
       });
       this.showloader = false;

       const templateSt =  this.convertToString( data.Template );

      //  let edit = new FroalaEditor('#template',
      //     this.options,
      //     function() {
      //        edit.html.set( templateSt );
      // }
      //  );
      //  this.editor = edit;
       $('#template').froalaEditor( 'html.set', templateSt );
    });

    const userData = localStorage.getItem('UserData');
  }

  updateText() {
    console.log( 'made it' );
  }
  convertToString( arr: any[] ) {
    let result = '';
    for (var idx = 0; idx < arr.length; idx++ ) {
      result += arr[idx];
    }
    return result;
  }

  savePage() {
    // this.submitted = true;
    this.showloader = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showloader = false;
      return;
    }

    if (this.form.valid) {
      const fd = new FormData();

      fd.append('Name', this.name);
      // fd.append('Template', this.editor.html.get() );
      fd.append('Template', $('#template').froalaEditor('html.get') );

      this.templateSer.saveTemplate(this.name, fd ).subscribe((res: any) => {
        if (res) {
            this.successful_result = true;
        } else {
          this.error_result = true;
          console.log('unable to save');
        }
        this.setTimer();
      });

      this.showloader = false;
      this.submitted = false;
    }
  }

  public setTimer() {
    this.timer = Observable.timer(2500);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
    });
  }

  toggleChange(event: any) {
    this.status = (event.target.checked ? 'Yes' : 'No');
  }

}
