import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { InfoModalComponent } from '../shared/info-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceConstants, UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements  CanActivateChild {

  constructor(
       private router: Router,
       private modalService: NgbModal,
       private userSer: UserService) {

  }

  askModal( msg: string) {
    const modalRef = this.modalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = '<p>' + msg + '</p>';
    modalRef.componentInstance.title = 'ASK';
    modalRef.result.then((res: any) => {
    })
    .catch( (res: any) => {
    });
  }


  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      const userData = this.userSer.getUserData();
      // const notSubscribed: any = 'Your subscription does not include access to this feature.' +
      // '  If you are interested in learning more, please email info@myhrcounsel.com or call (612) 339-1421';
      const notSubscribed = localStorage.getItem('notSubscribed');

      const expectedRoles = route.data.expectedRoles;
      if (expectedRoles !== undefined) {
         if (userData.roleprofile) {
           if (!expectedRoles.includes( userData.roleprofile.Role)) {
              this.router.navigate(['login']);
              return false;
           }
         } else {
           this.router.navigate(['login']);
           return false;
         }
      }

      if (userData.RoleType === 2) {
        // services paths
        if (state.url.includes('ticket')) {
          if (!this.userSer.haveService( ServiceConstants.ASK ) &&
              !this.userSer.haveService( ServiceConstants.ASKPRO ) &&
              !this.userSer.haveService( ServiceConstants.FMLA ) &&
              !this.userSer.haveService( ServiceConstants.ERISA_WRAP) &&
              !this.userSer.haveService( ServiceConstants.ERISA_COMPLETE)) {
            this.askModal( notSubscribed );
            this.router.navigate(['/dashboard']);
            return false;
          } else {
          }
        }

        if (state.url.includes('business-center')) {
          if (!this.userSer.haveService( ServiceConstants.ASKPRO )) {
            this.askModal( notSubscribed );
            this.router.navigate(['/dashboard']);
            return false;
          }
        }

        if (state.url.includes('erisa-center')) {
          if (!this.userSer.haveService( ServiceConstants.ERISA_COMPLETE ) &&
              !this.userSer.haveService( ServiceConstants.ERISA_WRAP )) {
            this.askModal( notSubscribed );
            this.router.navigate(['/dashboard']);
            return false;
          }
        }

        // lock down the admin paths
        if (state.url.includes('admins')) {

          if (state.url.includes('client') ||
              state.url.includes('admin-menu') ||
              state.url.includes('user')) {
            if (state.url.includes('client-list') ||
               (state.url.includes('user-list'))) {
                this.askModal('You lack permissions to this feature.');
                this.router.navigate(['/dashboard']);
                return false;
            }
            if (state.url.includes('client') && !this.userSer.haveAccess('client-profile', false)) {
              this.askModal('You lack permissions to this feature.');
              this.router.navigate(['/dashboard']);
              return false;
            }

          } else {
            this.askModal('You lack permission to this feature. ' +
                  'If you are interested in learning more please contact us at (612) 339-1421');
            this.router.navigate(['/dashboard']);
            return false;
          }
        }
      }

      // User is a Partner
      if (userData.RoleType === 3) {
        if (state.url.includes('ticket')) {
          this.askModal('You lack permission to this feature. ' +
               'If you are interested in learning more please contact us at (612) 339-1421');
          this.router.navigate(['/dashboard']);
          return false;
        }
        if (state.url.includes('admins')) {
          if (state.url.includes('partner') ||
              state.url.includes('client') ||
              state.url.includes('user') ||
              state.url.includes('admin-menu')) {
            if (state.url.includes('partner-list') ||
               (state.url.includes('user-list'))) {
              this.askModal('You lack permissions to this feature.');
              this.router.navigate(['/dashboard']);
              return false;
            }

            if (state.url.includes('partner')) {
              if (!this.userSer.haveAccess( 'Manage Partner Profile', true)) {
                this.askModal('You lack permissions to this feature.');
                this.router.navigate(['/dashboard']);
                return false;
              }
            }

            if (state.url.includes('client')) {
              if (!this.userSer.haveAccess( 'Manage Client Setup', true)) {
                this.askModal('You lack permissions to this feature.');
                this.router.navigate(['/dashboard']);
                return false;
              }
            }

          } else {
            this.askModal('You lack permissions to this feature.');
            this.router.navigate(['/dashboard']);
            return false;
          }
        }
      }

      if (userData.RoleType === 6) {
        if (state.url.includes('admins')) {
          if (state.url.includes('user') && !state.url.includes('user-list')) {
            return true;
          }
          if (state.url.includes('admin-menu')) {
            return true;
          }

          this.askModal('You lack permission to this feature. ' );
          this.router.navigate(['/dashboard']);
          return false;
        }

      }

      if (userData.RoleType === 8) {
        if (state.url.includes('ticket') && !state.url.includes('template')) {
          this.askModal('You lack permission to this feature. ' );
          this.router.navigate(['/dashboard']);
          return false;
        }
      }

      if (localStorage.getItem('token')) {
        // logged in so return true

        return true;
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login']);
      return false;

  }


}


