import {Component, OnInit, Input, NgZone, EventEmitter, Output, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { ContentService } from '../../services/content.service';
import { IContent, CONTENT_TYPE } from '../../model/content';
import { IPage } from '../../model/page';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';

import * as jquery from 'jquery';
import { state } from '@angular/animations';
declare var $: any;

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss'],
})

export class ContentListComponent implements OnInit {

  isFilterTrue = true;
  paging: IPage;
  totalStatess: number;
  per_page = 10;
  selected_status: number;
  filter: any = { 'title': '' };
  allContent: any[];
  userData: any;
  total: number = 0;
  public row_header: any = [];
  public header_index: any;
  public assignee_array: any = [];
  private allItems: any[];
  showloader: boolean;
  pager: any = {};
  pagedItems: any[];
  crumbList: any[];
  typeList: any[];

  constructor(
    private pagerS: PagerService,
    private contentSer: ContentService,
    private modalService: NgbModal,
    private userSer: UserService,
    private ngZone: NgZone) {
      this.showloader = true;
  }

  ngOnInit() {
    this.userData = this.userSer.getUserData();


    this.typeList = [{type: 0, value: 'All types'}];
    CONTENT_TYPE.forEach( el => this.typeList.push(el) );

    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' }
    ];
    this.selected_status = null;

    this.row_header  = [
      {text: 'Type', value: 'Type', 'sort': null},
      {text: 'Title', value: 'Title', 'sort': null},
      {text: 'EffDate', value: 'EffDate', 'sort': null}
    ];

    this.header_index = 0;

    this.setPage( 1 );
  }


  deleteContent(id: number) {
    console.log( id );
    const content = this.allContent.find( x => x.ID === id);
    if (content === undefined) {
      alert('Unable to find Content record');
      return;
    }
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to delete ' +
    '<span class="text-primary">"' + content.Title + '"</span> record?</strong></p>' +
    '<p>All information associated to this record will be permanently deleted.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'State Update Record';
    modalRef.result.then((res: any) => {
      this.contentSer.deleteContent(content.ID).subscribe((data: any) => {
         this.setPage(1);
      });
    })
    .catch( (res: any) => {
    });

  }


  filterByType(event) {
    this.showloader = true;
    const id = $('#typeId option:selected').val();
    console.log( event.target );
    if (id === 0 || id === '0') {
      this.filter.type = undefined;
    } else {
      this.filter.type = id;
    }
    this.setPage(1);
  }

  filterByTitle(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.title = id;
    this.setPage(1);
  }

  fillInType() {
    this.allContent.forEach( el => {
      const cType = CONTENT_TYPE.find( x => x.type === el.Type );
      if (cType === undefined) {
        el.TypeName = 'Unknown';
      } else {
        el.TypeName = cType.value;
      }
    });
  }

  setPage(page: number = 1) {
        const that = this;

        this.showloader = true;
        this.contentSer
          .getAllContent( page, this.per_page, this.row_header[this.header_index], this.filter)
          .subscribe( {
              next(data: any) {
                that.showloader = false;
                that.allContent = data.data; // return athe all data
                that.fillInType();
                that.paging = data.page; // return all the paginationinfo
                that.total = that.paging.total;
                that.pager = that.pagerS.getPager(that.paging.total, page, that.per_page);
          },
          error(msg) {
            that.ngZone.run( () => {
              that.showloader = false;
            });
          }
        });
  }

  tableSort(index) {
    this.showloader = true;
    if (this.header_index === index) {
      (this.row_header[index].sort === 'asc') ? this.row_header[index].sort = 'desc' : this.row_header[index].sort = 'asc';
    } else {
      this.row_header[index].sort = 'asc';
      this.row_header[this.header_index].sort = null;
      this.header_index = index;
    }
    this.setPage(1);
  }


}


