import { Component, OnInit } from '@angular/core';
import {FormGroup,Validators,FormBuilder}from '@angular/forms';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  host:{'class': 'login'}
})
export class SignupComponent implements OnInit {

  form:FormGroup;
  constructor( private fb:FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      fullName:['',Validators.required],
      email:['',Validators.required],
      password:['', Validators.required],
      confirmPassword:['',Validators.required]
    });
  }

  login(){
    console.log('login')
  }

}
