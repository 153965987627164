import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { CodeService } from '../../services/code.service';
import { IDocument } from '../../model/document';
import { DocumentService } from '../../services/document.service';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { ModalComponent } from '../../shared/modal.component';
import { InfoModalComponent } from '../../shared/info-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as jquery from 'jquery';
import { state } from '@angular/animations';
import { ClickOutsideDirective } from 'ng-multiselect-dropdown/click-outside.directive';
import { PageService } from 'src/app/services/page.service';

declare var $: any;

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit, AfterViewInit {
  document: IDocument;
  id: any;
  active: any;
  showloader = true;
  successful_result = false;
  error_result = false;
  dropdownList = [];
  dropdownSettings: {};
  form: FormGroup;
  title: string;
  states: any;
  submitted = false;
  post_results: string;
  private subscription: Subscription;
  private timer: Observable<any>;
  crumbList: any[];
  updateList: any[];
  userData: any;
  state: any = '';
  category: any =  '';
  pages: any = [];
  hasState: any = 0;
  hasDate: any = 0;

  // public options: Object = {
  //   key: 'eC6D6C2E3E2H2xC4B3A3C2B5A1B1E4F1A2sd1Te1LXe1URVJe1DWXG==',
  //   // key: 'dC10B5D5A2A-9H3E2J2A4C6C3C2B5B1E1pj1wgbrD-11fzfsB-22B3zuv==',
  //   // key: 'eHE5C-11B2C1F2I2A5C4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4D3E4C2G2E3C1B2==',
  //   height: 370,
  //   attribution: false,
  //   charCounterCount: false
  // };

  NameCtrl: FormControl;
  LastAuthorCtrl: FormControl;
  RevisionDateCtrl: FormControl;
  RevisionCtrl: FormControl;
  OrderCtrl: FormControl;
  LinkCtrl: FormControl;

  constructor(
    private http: HttpClient,
    private userSer: UserService,
    private codeSer: CodeService,
    private pageSer: PageService,
    private documentSer: DocumentService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private infoModalService: NgbModal,
    private actRoute: ActivatedRoute,
    private ngZone: NgZone,
    private router: Router ) {

      this.showloader = true;
  }

  ngOnInit() {
    this.showloader = true;

    this.userData = this.userSer.getUserData();
    if (!this.userData || !this.userData.roleprofile) {
      this.router.navigate(['/dashboard']);
    }

    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' },
      { title: 'Document List', url: '/admins/document-list' }
    ];


    this.NameCtrl = new FormControl('Name', [Validators.required]);
    const regex = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/);
    this.LinkCtrl = new FormControl('Link', [Validators.required, Validators.pattern(regex) ]);
    this.RevisionDateCtrl = new FormControl('RevisionDate', [Validators.required]);
    this.RevisionCtrl = new FormControl('Revision', [Validators.required]);

    this.form = this.fb.group({
      Name: this.NameCtrl,
      Link: this.LinkCtrl,
      Revision: this.RevisionCtrl,
      RevisionDate: this.RevisionDateCtrl,
      LastAuthor: new FormControl('LastAuthor'),
      Order: this.OrderCtrl
    });

    // this.dropdownSettings = {
    //   singleSelection: false,
    //   idField: 'id',
    //   textField: 'Code',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 3,
    //   allowSearchFilter: false
    // };

    this.actRoute.params.subscribe( params =>
      this.id = parseInt( params['id'], 10 )
    );

    this.pageSer.getPages('HR').subscribe( (pageData: any) => {
      this.pages = pageData;
      this.pageSer.getPages('PRO').subscribe( (proPageData: any) => {
        this.pages = this.pages.concat( proPageData );
        console.log( this.pages );
        this.codeSer.getCodeByType('STATE').subscribe( (data: any ) => {
          this.states = data;

          if (this.id === undefined || this.id === 0 || isNaN(this.id)) {
            this.id = 0;
            this.title = 'New Document';
            this.form.patchValue({
              Name: '',
              Link: '',
              RevisionDate: this.today(),
              Revision: 1,
              LastAuthor: '',
            });
            this.hasState = 'No';
            this.hasDate = 'No';
            this.state = '';
            this.showloader = false;
          } else {
            this.documentSer.getDocument( this.id ).subscribe((documentData: any) => {
              this.document = documentData;
              this.title = 'Edit Document';

              const eDate = documentData.RevisionDate;
              this.form.patchValue({
                Name: documentData.Name,
                Link: documentData.Link,
                RevisionDate: { 'year': this.getYear(eDate) , 'month': this.getMonth(eDate), 'day': this.getDay(eDate) },
                LastAuthor: documentData.LastAuthor,
                Order: documentData.Order,
                Revision: documentData.Revision
              });
              this.state = documentData.State;
              this.category = documentData.Category;
              this.hasState = documentData.HasState === 1 ? 'Yes' : 'No';
              this.hasDate = documentData.HasDate === 1 ? 'Yes' : 'No';

              this.showloader = false;
            });
          }
        });
      });
    });
  }

  ngAfterViewInit() {
  }

  getYear( dt: any ) {
    if (dt.length < 10) {
      return 2020;
    }
    const s = dt.substr(0, 4);
    return parseInt( s, 10 );
  }

  getMonth( dt: any ) {
    if (dt.length < 10) {
      return 1;
    }
    const s = dt.substr(5, 2);
    return parseInt( s, 10 );
  }

  getDay( dt: any ) {
    if (dt.length < 10) {
      return 1;
    }
    const s = dt.substr(8, 2);
    return parseInt( s, 10 );
  }

  showFields() {
    const controls = this.form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            console.log( name );
        }
    }

  }

  saveDocument( editForm: any ) {
    // this.submitted = true;
    this.showloader = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showloader = false;
      this.showFields();

      return;
    }

    if (this.form.valid) {
      const fd = new FormData();

      fd.append('Name', this.form.value.Name);
      fd.append('Link', this.form.value.Link);
      let revisionDate = this.form.value.RevisionDate;
      revisionDate = revisionDate.year + '-' + revisionDate.month + '-' + revisionDate.day;
      fd.append('RevisionDate', revisionDate);
      fd.append('HasState', this.hasState === 'Yes' ? '1' : '0');
      fd.append('HasDate', this.hasDate === 'Yes' ? '1' : '0');
      fd.append('Category', $('#category option:selected').text().trim() );
      const stateName = $('#state' + ' option:selected').text().trim();
      if (stateName) {
        fd.append('State', stateName);
      }
      if (this.form.value.LastAuthor) {
        fd.append('LastAuthor', this.form.value.LastAuthor);
      }
      fd.append('Revision', this.form.value.Revision);

      const that = this;
      if (this.id === 0) {
         this.documentSer.createDocument(fd).subscribe(
           {

            next(res: any) {
              if (res.status) {
                that.successful_result = true;
                that.id = res.data.ID;
                that.showloader = false;
                that.setTimer();
              } else {
                that.error_result = true;
                console.log('unable to save');
              }
              that.setTimer();
            },
            error(msg) {
              that.ngZone.run( () => {
                that.showloader = false;
                that.displayMessage( 'Error saving record. Most likely the document name matches and existing record' );
              });
            }
          });

      } else {
        this.documentSer.saveDocument(this.id, fd ).subscribe((res: any) => {
          if (res) {
             this.successful_result = true;
          } else {
            this.error_result = true;
            this.showloader = false;
            console.log('unable to save');
          }
          this.setTimer();
        });
      }
      this.submitted = false;
    }
  }

  public setTimer() {
    this.timer = Observable.timer(2500);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
        this.showloader = false;
      });
  }

  toggleChange(event: any, objName: any) {
    if (objName === 'State') {
       this.hasState = (event.target.checked ? 'Yes' : 'No');
    } else {
      this.hasDate = (event.target.checked ? 'Yes' : 'No');
    }
  }

  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Warning';
    modalRef.result.then((res: any) => {
    });

  }

  today() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  }


}

