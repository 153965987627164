import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
// import {AuthGuard} from './auth/auth.guard';

/**
 * Global Routing
 */
const app_routing: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'user', loadChildren: './user-default/user-default.module#UserDefaultModule'},
  {path: 'admin', loadChildren: './admin/admin-base.module#AdminBaseModule'}
];

@NgModule({
  imports: [
   RouterModule.forRoot(app_routing)
  ],
  exports:[
    RouterModule
  ]
})
export class RoutingModule { }
