import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { rolesStatus} from '../model/roles';
import { MessageService } from './message.service';
import { ITalentCourse } from '../model/talentlms';


@Injectable({
  providedIn: 'root'
})
export class TalentService {

  // the ":" is added because the auth is supposed to pass in the user id and password, the :
  // must be the delimiter
  TALENT_API: any = 'xKj4sdNaXDj5qAYVBYDK7ZvA9DfDaY:';

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }

  getCourses(): Observable<ITalentCourse> {
    return this.http.get(`${environment.apiUrl}talentlms/courses/`)
       .pipe(map((res: any) => {
         return res;
       }));

  }

  getBranches(): Observable<ITalentCourse> {
    return this.http.get(`${environment.apiUrl}talentlms/branches/`)
       .pipe(map((res: any) => {
         return res;
       }));

  }

  getBranch(id: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}talentlms/branch/?Id=` + id)
       .pipe(map((res: any) => {
         return res;
       }));

  }

  //
  // Creates a new Talent record
  //
  createTalent( form ) {

    return this.http.post(`${environment.apiUrl}talent/`, form)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

  //
  // Update a talent record
  //
  saveTalent( name, form ) {
    return this.http.post(`${environment.apiUrl}talent/` + name, form
    )
    .pipe(
      map((res: any) => {
        if (res.status) {
          return res.status;
        }
      })
    );
  }

  //
  // Retrieve Talent
  //
  getTalent( name ): Observable <ITalentCourse> {
    return this.http.get(`${environment.apiUrl}talent/` + name)
        .pipe(
           map((res: any) => {
             if (res.status) {
               return res;
             }
           })
        );
  }


  //
  // Delete a new talent record
  //
  deleteTalent( name ): Observable <any> {

    return this.http.delete(`${environment.apiUrl}talent/` + name)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

}
