import {Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { TicketService } from '../../services/ticket.service';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { UserService } from '../../services/user.service';
import { ClientService } from '../../services/client.service';
import { ITicket } from '../../model/ticket';
import { IPage } from '../../model/page';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { CodeService } from 'src/app/services/code.service';
import { Router } from '@angular/router';

export interface ITicketValue {
  Text: string;
  value: number;
  status: any;
}

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})

export class TicketComponent implements OnInit {
  is_deleted_selected = false;
  tickets: any;
  ticketTitle: string;
  ticketsExtraInfo: any;
  allTickets: number;
  solvedTicket: number;
  unassignedTicket: number;
  unsolvedTicket: number;
  deletedTicket: number = 0;
  isFilterTrue = true;
  ticketValue: ITicketValue;
  paging: IPage;
  totalTickets: number;
  per_page: number = 8;
  selected_status: number;
  users: any;
  filter: any = { 'ticket_number': '', 'subject_matter': '', 'client': '',
                  'requester': '', 'assigned_to': '', 'subject': '', 'search': ''};
  clients: any;
  subject: any;
  curr_user_id: any;
  token: any;
  assignedUser: any;
  assignedUserId: any = 0;
  public row_header: any = [];
  public header_index: any;
  public assignee_array: any = [];

  private allItems: any[];
  showloader: boolean;

  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];

  /**
   * Get values
   */
  getValue: any = {
    all_tickets: { 'Text': 'All', value: 0, status: null },
    solved_ticket: { 'Text': 'Closed', value: 0, status: 3 },
    unsolved_ticket: { 'Text': 'Active', value: 0, status: 2 },
    unassigned_ticket: { 'Text': 'New', value: 0, status: 1 },
    deleted_ticket: { 'Text': 'Deleted', value: 0, status: 4 }
  };



  // dtOptions: DataTables.Settings = {};
  constructor( private ticketS: TicketService,
    private pagerS: PagerService,
    private adminSer: AdminTiketService,
    private clientSer: ClientService,
    private codeSer: CodeService,
    private router: Router,
    private userSer: UserService,
    private ngZone: NgZone) {
      this.showloader = false;
  }

  ngOnInit() {
    const userData = localStorage.getItem('UserData');
    this.curr_user_id = JSON.parse(userData);
    this.token = localStorage.getItem('token');
    this.userSer.selectMenu('askanattorney');

    let that = this;
    this.selected_status = null;
    this.ticketValue = this.getValue.all_tickets;
    this.clientSer.getClients().subscribe((data: any) => {
      this.clients = data;
    });
    this.codeSer.getSubjectMatter().subscribe((data: any) => {
      this.subject = data;
    });
    this.userSer.getAgents().subscribe((data: any) => {
      this.assignedUser = data;
      data.forEach(function (value) {
        that.assignee_array[value.UserID] = value.FirstName + ' ' + value.LastName;
      });
    });

    // if client user
    if (this.curr_user_id.RoleType === 2) {
      this.row_header  = [
        {text: 'Ticket #', value: 'ticket_number', 'sort': null },
        {text: 'Subject', value: 'subject', 'sort': null},
        {text: 'Subject Matter', value: 'subject_matter', 'sort': null},
        {text: 'Requestor', value: 'requester', 'sort': null},
        {text: 'Assigned To', value: 'assigned_to', 'sort': null},
        {text: 'Created At', value: 'created_at', 'sort': 'desc'}
      ];
      this.header_index = 0;
    } else {
      this.per_page = 10;
      this.row_header = [
        {text: 'Ticket #', value: 'ticket_number', 'sort': null },
        {text: 'Subject', value: 'subject', 'sort': null},
        {text: 'Subject Matter', value: 'subject_matter', 'sort': null},
        {text: 'Client', value: 'client', 'sort': null},
        {text: 'Requestor', value: 'requester', 'sort': null},
        {text: 'Assigned To', value: 'assigned_to', 'sort': null},
        {text: 'Created At', value: 'created_at', 'sort': 'desc'},
        {text: 'Updated At', value: 'updated_at', 'sort': null}
      ];

      this.header_index = 6;
    }

    const selStatus: string = localStorage.getItem('selected_status');
    if (selStatus) {
      this.selected_status = parseInt( selStatus, 10 );
      if (this.selected_status === 0) {
        this.selected_status = null;
      }
    } else {
      this.selected_status = 2;
    }

    const headObj = JSON.parse(localStorage.getItem('header_index'));
    if (headObj) {
       for ( var idx = 0; idx < this.row_header.length; idx++ ) {
          if (this.row_header[idx].text === headObj.text) {
             this.header_index = idx;
             this.row_header[idx].sort = headObj.sort;
          }
       }
    }

    const savedPage: string = localStorage.getItem('page');
    let page = 1;
    if (savedPage) {
      page = parseInt( savedPage, 10 );
    }

    const filterObj = JSON.parse(localStorage.getItem('filter'));
    if (filterObj) {
      this.filter = filterObj;
    }


    this.setPage(page);
  }

  openTicket( id: any ) {
    this.router.navigate(['/ticket/' + id + '/edit']);
  }

  deleteTicket(id: number) {
    this.ticketS.deleteTicket(this.token, id).subscribe((data: any) => {
      this.setPage(1);
    });
  }

  selectMe( event: any ) {
      $('.tab').removeClass('selected');
      $(event.target).addClass('selected');
      const dTarget = $(event.target).attr('data-target');
      if (dTarget.indexOf('closed') > -1) {
        this.selected_status = 3;
        this.setPage(1);
      } else {
        this.selected_status = 2;
        this.setPage(1);
      }
}

  // getSelectedValue(event: string) {
  //   this.showloader = false;
  //   if (this.curr_user_id.UserType === 1) {
  //     if (event === 'deleted_ticket') {
  //       this.is_deleted_selected = true;
  //       this.row_header = [
  //         {text: 'Subject Matter', value: 'subject_matter', 'sort': null},
  //         {text: 'Subject', value: 'subject', 'sort': null},
  //         {text: 'Client', value: 'client', 'sort': null},
  //         {text: 'Requestor', value: 'requester', 'sort': null},
  //         {text: 'Assigned To', value: 'assigned_to', 'sort': null},
  //         {text: 'Created At', value: 'created_at', 'sort': 'desc'},
  //         {text: 'Deleted At', value: 'deleted_at', 'sort': null}
  //       ];
  //     } else {
  //       this.is_deleted_selected = false;
  //       this.row_header = [
  //         {text: 'Subject Matter', value: 'subject_matter', 'sort': null},
  //         {text: 'Subject', value: 'subject', 'sort': null},
  //         {text: 'Client', value: 'client', 'sort': null},
  //         {text: 'Requestor', value: 'requester', 'sort': null},
  //         {text: 'Assigned To', value: 'assigned_to', 'sort': null},
  //         {text: 'Created At', value: 'created_at', 'sort': 'desc'},
  //         {text: 'Updated At', value: 'updated_at', 'sort': null}
  //       ];
  //     }
  //   }

  //   this.ticketValue = this.getValue[event];
  //   this.selected_status = this.getValue[event].status;
  //   this.setPage(1);

  // }

  changeTicStatus( newStatus ) {
    this.selected_status = newStatus;
    this.setPage(1);
  }

  filterByTicketNumber(event) {
    this.showloader = false;
    const id = event.target.value;
    this.filter.ticket_number = id;
    this.setPage(1);
  }

  filterByClient(event) {
    this.showloader = false;
    const id = event.target.value;
    this.filter.client = id;
    this.userSer.getUsersByClient(id).subscribe((data:any)=>{
      this.users = data;
    });
    this.setPage(1);
  }

  filterBySubjectMatter(event) {
    this.showloader = false;
    const id = event.target.value;
    this.filter.subject_matter = id;
    this.setPage(1);
  }

  filterSearchSubject(event) {
    this.showloader = false;
    const searchStr = event.target.value;
    this.filter.subject = searchStr;
    this.setPage(1);
  }

  searchTickets(event) {
    this.showloader = false;
    const searchStr = event.target.value;
    this.filter.search = searchStr;
    this.setPage(1);
  }


  filterByRequester(event) {
    this.showloader = false;
    const id = event.target.value;
    this.filter.requester = id;
    this.setPage(1);
  }

  filterByAssigned(event: any) {
    this.showloader = false;
    const id = event.target.value;
    this.filter.assigned_to = id;
    this.setPage(1);
  }

  changeStatus(id, status_id) {
    const data = new FormData();
    data.append('status', status_id);
    this.ticketS.updateTickets(data, this.token, id).subscribe(( data: any) => {
      this.setPage(1);
    });
  }


  setPage(page: number = 1) {
        const that = this;

        this.showloader = false;
        this.ticketS
          .getTickets(this.selected_status, page, this.row_header[this.header_index], this.filter, this.per_page)
          .subscribe( {
              next(data: any) {
                that.showloader = true;
                that.tickets = data.data; // return athe all data
                that.ticketsExtraInfo = data.extra_data; // return the all status info
                that.paging = data.page; // return all the paginationinfo
                that.allTickets = that.ticketsExtraInfo.all_tickets;
                that.solvedTicket = that.ticketsExtraInfo.solved_ticket;
                that.unassignedTicket = that.ticketsExtraInfo.unassigned_ticket;
                that.unsolvedTicket = that.ticketsExtraInfo.unsolved_ticket;
                that.deletedTicket = that.ticketsExtraInfo.deleted_ticket;
                that.getValue.all_tickets.value = that.allTickets;
                that.getValue.solved_ticket.value = that.solvedTicket;
                that.getValue.unsolved_ticket.value = that.unsolvedTicket;
                that.getValue.unassigned_ticket.value = that.unassignedTicket;
                that.getValue.deleted_ticket.value = that.deletedTicket;
                that.pager = that.pagerS.getPager(that.paging.total, page, that.per_page);
          },
          error(msg) {
            that.ngZone.run( () => {
              that.showloader = true;
            });
          }
        });

        localStorage.setItem('selected_status', (this.selected_status ? this.selected_status.toString() : '0') );
        localStorage.setItem('page', page.toString() );
        localStorage.setItem('header_index', JSON.stringify( this.row_header[this.header_index]) );
        localStorage.setItem('filter', JSON.stringify( this.filter ) );

        // get pager object from service

        // get current page of items

       // this.pagedItems = this.tickets.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  tableSort(index) {
    this.showloader = false;
    if (this.header_index === index) {
      (this.row_header[index].sort === 'asc') ? this.row_header[index].sort = 'desc' : this.row_header[index].sort = 'asc';
    } else {
      this.row_header[index].sort = 'asc';
      this.row_header[this.header_index].sort = null;
      this.header_index = index;
    }
    this.setPage(1);
  }

  /*
  getHeader(except) {
    header_row_index = ['subject_matter', 'subject', 'client', 'requester', 'assigned_to', 'created_at', 'updated_at', 'deleted_at']
    this.row_header = [
      {text: 'Subject Matter', value: 'subject_matter', 'sort': null},
      {text: 'Subject', value: 'subject', 'sort': null},
      {text: 'Client', value: 'client', 'sort': null},
      {text: 'Requester', value: 'requester', 'sort': null},
      {text: 'Assigned To', value: 'assigned_to', 'sort': null},
      {text: 'Created At', value: 'created_at', 'sort': 'desc'},
      {text: 'Updated At', value: 'updated_at', 'sort': null},
      {text: 'Deleted At', value: 'deleted_at', 'sort': null},
      {text: '', value: '', 'sort': null},
    ];
  }
  */

}


