import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { CodeService } from '../../services/code.service';

import { IClients } from '../../model/clients';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { ClientService } from '../../services/client.service';
import { PartnerService } from '../../services/partner.service';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { ModalComponent } from '../../shared/modal.component';
import { InfoModalComponent } from '../../shared/info-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import { formControlBinding } from '@angular/forms/src/directives/ng_model';
// import { validateConfig } from '@angular/router/src/config';

import { ClientServiceService } from '../../services/clientservice.service';

import * as jquery from 'jquery';
import { state } from '@angular/animations';
import { ClickOutsideDirective } from 'ng-multiselect-dropdown/click-outside.directive';

declare var $: any;

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {
  client: IClients;
  clients: any;
  partners: any;
  states: any;
  id: any;
  isNewClient = false;
  active: any;
  showloader = true;
  successful_result = false;
  error_result = false;
  dropdownList = [];
  dropdownSettings: {};
  form: FormGroup;
  title: string;
  submitted = false;
  post_results: string;
  private subscription: Subscription;
  private timer: Observable<any>;
  crumbList: any[];
  services: any[];
  clientStates: any[];
  isPartner: any = false;
  isClient: any = false;
  userData: any;
  servicesOffered: any[];
  newServiceDescription: any = '';

  ClientNameCtrl: FormControl;
  ContactNameCtrl: FormControl;
  Address1Ctrl: FormControl;
  Address2Ctrl: FormControl;
  BusinessSummaryCtrl: FormControl;
  CityCtrl: FormControl;
  StateCtrl: FormControl;
  PostalCodeCtrl: FormControl;
  ActiveCtrl: FormControl;
  TelephoneCtrl: FormControl;
  PartnerIDCtrl: FormControl;

  constructor(
    private http: HttpClient,
    private userSer: UserService,
    private clientSer: ClientService,
    private partnerSer: PartnerService,
    private adminSer: AdminTiketService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private infoModalService: NgbModal,
    private actRoute: ActivatedRoute,
    private clientServiceSer: ClientServiceService,
    private codeSer: CodeService,
    private ngZone: NgZone,
    private router: Router ) {

      this.showloader = true;
  }

  ngOnInit() {
    this.showloader = true;

    this.userData = this.userSer.getUserData();
    if (!this.userData || !this.userData.roleprofile) {
      this.router.navigate(['/dashboard']);
    }
    this.isPartner = (this.userData.RoleType === 3);
    this.isClient = (this.userData.RoleType === 2);

    if (this.isClient) {
      this.crumbList = [
        { title: 'Home', url: '/dashboard' },
        { title: 'Admin Menu', url: '/admins/admin-menu' }
      ];
    } else {
      this.crumbList = [
        { title: 'Home', url: '/dashboard' },
        { title: 'Admin Menu', url: '/admins/admin-menu' },
        { title: 'Client List', url: '/admins/client-list' }
      ];
    }

    this.ClientNameCtrl = new FormControl('ClientName', [Validators.required, Validators.minLength(2)]);
    this.ContactNameCtrl = new FormControl('ContactName', [Validators.required, Validators.minLength(2)]);
    this.Address1Ctrl = new FormControl('Address1', [Validators.required]);
    // const regex = new RegExp(/^(?=.*[a-z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,20}/);
    this.Address2Ctrl = new FormControl('Address2' );
    this.BusinessSummaryCtrl = new FormControl('BusinessSummary');
    this.CityCtrl = new FormControl('City', [Validators.required]);
    this.StateCtrl = new FormControl('State', [Validators.required]);
    this.PostalCodeCtrl = new FormControl('PostalCode', [Validators.required]);
    const regex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
    this.TelephoneCtrl = new FormControl('Telephone', [Validators.required, Validators.pattern( regex )]);
    this.ActiveCtrl = new FormControl('Active');
    this.PartnerIDCtrl = new FormControl('ID', [Validators.required]);

    this.form = this.fb.group({
      ClientName: this.ClientNameCtrl,
      ContactName: this.ContactNameCtrl,
      Address1: this.Address1Ctrl,
      Address2: this.Address2Ctrl,
      BusinessSummary: this.BusinessSummaryCtrl,
      City: this.CityCtrl,
      State: this.StateCtrl,
      PostalCode: this.PostalCodeCtrl,
      Telephone: this.TelephoneCtrl,
      Active: this.ActiveCtrl,
      ID: this.PartnerIDCtrl
    }, { updateOn: 'submit' });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    this.actRoute.params.subscribe( params =>
      this.id = parseInt( params['id'], 10 )
    );

    if (this.isClient) {
      this.id = this.userData.client.ID;
    }

    if (this.id === undefined || this.id === 0 || isNaN(this.id)) {
      this.isNewClient = true;
      this.id = 0;
      this.title = 'New Client';
      this.form.patchValue({
        ClientName: '',
        ContactName: '',
        Address1: '',
        Address2: '',
        Telephone: '',
        City: '',
        State: '',
        PostalCode: '',
        BusinessSummary: '',
        Active: 1,
        PartnerId: 0
      });
      this.active = 'Yes';
      this.clientStates = [];
      this.services = [];
    }


    this.partnerSer.getPartners().subscribe((partnerData: any) => {
      this.partners = partnerData;

      this.codeSer.getCodeByType('STATE').subscribe( (data: any ) => {
        this.states = data;

        // this.codeSer.getServices().subscribe( (serviceList: any ) => {
        //   this.servicesOffered = serviceList;

          if (this.isNewClient) {
            if (this.isPartner) {
              this.form.patchValue( {
                ID: this.userData.roleprofile.PartnerID
              });
            }
            this.showloader = false;
          } else {
            this.title = 'Edit Client';

            this.clientSer.getClient(this.id).subscribe((clientData: any) => {
              if (this.isPartner &&
                  this.userData.roleprofile.PartnerID !== clientData.PartnerID) {
                    this.router.navigate(['/dashboard']);
              }

              this.form.patchValue({
                    ClientName: clientData.ClientName,
                    ContactName: clientData.ContactName,
                    Address1: clientData.Address1,
                    Address2: clientData.Address2,
                    City: clientData.City,
                    State: clientData.State,
                    PostalCode: clientData.PostalCode,
                    BusinessSummary: clientData.BusinessSummary,
                    Telephone: clientData.Telephone,
                    Active: clientData.Active,
                    ID: clientData.PartnerID
              });

              this.active = (clientData.Active === 1 ? 'Yes' : 'No');
              this.parseStates( clientData );
              this.setServiceList( clientData.PartnerID );
              this.parseServices( clientData );
              this.showloader = false;
            });
          }
        // });
      });
    });

  }

  setServiceList( pId: any ) {
    const partner = this.partners.find( x => x.ID === pId );
    if (partner !== undefined) {
      this.servicesOffered = JSON.parse( partner.PartnerServices );
    }
  }

  changeServices() {
    let pId = $('#ID option:selected').val();
    pId = parseInt( pId, 10 );
    this.setServiceList( pId );
  }

  parseStates( client ) {
    try {
       this.clientStates = JSON.parse( client.EmpsByState );
    } catch (e) {
       this.clientStates = [];
    }
    if (this.clientStates !== null && this.clientStates !== undefined) {
      for (var i = 0; i < this.clientStates.length; i++ ) {
        let rec = this.clientStates[i];
        rec.ID = i+1;
        rec.state = rec.state.toUpperCase();
        if (isNaN(rec.count)) {
          rec.count = 0;
        }
      }
    } else {
      this.clientStates = [];
    }
  }

  parseServices( client ) {
    try {
       this.services = JSON.parse( client.ClientServices );
    } catch (e) {
       this.services = [];
    }

    if (this.services !== null && this.services !== undefined) {
      for (var i = 0; i < this.services.length; i++ ) {
        let rec = this.services[i];
        rec.ID = i + 1;
        const serv = this.servicesOffered.find( x => x.Code === rec.Code );
        if (serv) {
          rec.Description = serv.Value;
        }
        if (!rec.EffectiveDate) {
          rec.EffectiveDate = Date.now();
        }
      }
    } else {
      this.services = [];
    }
  }

  showFields() {
    const controls = this.form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            console.log( name );
        }
    }

  }

  onClickedOutside( event: any ) {
  //  console.log('on outside');
  }

  saveClient( reload: any ) {
    // this.submitted = true;
    this.showloader = true;
    this.submitted = true;

    this.showFields();

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showloader = false;

      return;
    }

    if (this.form.valid) {
      const fd = new FormData();

      fd.append('ClientName', this.form.value.ClientName);
      fd.append('ContactName', this.form.value.ContactName);
      fd.append('Address1', this.form.value.Address1);
      if (this.form.value.Address2) {
        fd.append('Address2', this.form.value.Address2 );
      }
      fd.append('BusinessSummary', this.form.value.BusinessSummary);
      fd.append('City', this.form.value.City);
      fd.append('State', this.form.value.State);
      fd.append('PostalCode', this.form.value.PostalCode);
      fd.append('Telephone', this.form.value.Telephone);
      fd.append('Active', (this.active === 'Yes' ? '1' : '0'));
      fd.append('PartnerID', this.form.value.ID );
      fd.append('EmpsByState', this.getStates() );
      fd.append('ClientServices', JSON.stringify( this.services ) );

      const that = this;
      if (this.id === 0) {
         this.clientSer.createClient(fd).subscribe( 
           {

            next(res: any) {
              if (res.status) {
                that.successful_result = true;
                this.id = res.data.ID;
                window.location.href = window.location.href + '/' + this.id;
                this.form.reset();
              } else {
                that.error_result = true;
                console.log('unable to save');
              }
              that.setTimer();
            },
            error(msg) {
              that.ngZone.run( () => {
                alert( 'Error saving record. Most likely the Name is a duplicate of an existing record' );
              });
            }
          });

      } else {
        this.clientSer.saveClient(this.id, fd ).subscribe((res: any) => {
          if (res) {
             this.successful_result = true;
             if (reload) {
               window.location.reload();
             }
          } else {
            this.error_result = true;
            console.log('unable to save');
          }
          this.setTimer();
        });
      }
      this.showloader = false;
      this.submitted = false;
    }
  }

  public setTimer() {
    this.timer = Observable.timer(2500);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
    });
  }

  toggleChange(event: any) {
    this.active = (event.target.checked ? 'Yes' : 'No');
  }

  expandTable(rowId: any) {
    rowId = '#' + rowId;
    $(rowId).collapse( !$(rowId).hasClass('show') );
  }

  swapIcon( event: any ) {
    const ele = event.target;
    const expanded = $(ele).hasClass('fa-expand-alt');
    if (expanded) {
      $(ele).removeClass('fa-expand-alt').addClass('fa-compress-alt');
    } else {
      $(ele).removeClass('fa-compress-alt').addClass('fa-expand-alt');
    }
  }

  getStates() {
     return JSON.stringify( this.clientStates );
  }

  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Warning';
    modalRef.result.then((res: any) => {
    });

  }

  updateState( id: any) {
    if (this.clientStates.length > id-1) {
      const obj = this.clientStates[id-1];
      const empCount = $('#stateEmp' + id).val();
      const stateName = $('#state' + id + ' option:selected').text().trim();
      obj.state = stateName;
      obj.count = empCount;
      this.saveClient( false );
    }
  }

  checkForAutoSave() {
    let newState = $('#newState').val();
    newState = parseInt( newState, 10 );
    const newStateCount = $('#newStateCount').val();
    if (newState > 0 && newStateCount > 0) {
      var stateName = $('#newState option:selected').text().trim();
      var obj = this.findStateByName( stateName );
      if (obj === null) {
        this.clientStates.push( { ID: this.clientStates.length,
              state: stateName,
              count: newStateCount
        });
        this.saveClient( false );
        this.clearState();
      } else {
        this.displayMessage( 'Duplicate State. Please edit existing record.' );
      }
    }
  }

  findActiveService( code: any ) {
    for ( const serv of this.services ) {
       if (serv.Code === code && serv.ExpirationDate === undefined) {
         return serv;
       }
    }
    return undefined;
  }

  serviceAutoSave() {
    const newService = $('#newService').val();
    const obj = this.servicesOffered.find( x => x.Code === newService );
    const exists = this.findActiveService( newService );
    const newServiceDate = $('#newServiceDate').val();
    if (obj !== undefined) {
      this.newServiceDescription = obj.Value;
    }

    if (obj === undefined || newServiceDate === '') {
      return;
    }

    if (exists === undefined) {
      const obj = this.servicesOffered.find( x => x.Code === newService );
      if (obj !== undefined) {
        this.newServiceDescription = obj.Value;
        const servObj = { ID: this.services.length,
              Code: obj.Code,
              Description: obj.Value,
              EffectiveDate: newServiceDate
        };
        this.activateService( servObj );
      }
    } else {
      this.displayMessage( 'Service already active' );
    }
  }

  clearService() {
    $('#newService').val('');
    $('#newServiceRow').collapse('hide');
  }

  findServiceByName( name: any ) {
    if (!this.services) {
      return null;
    }

    return this.services.find( x => x.Code === name );
  }

  activateService( servObj: any ) {
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Ready to activate the ' +
    '<span class="text-primary">"' + servObj.Code + '"</span> service?</strong></p>' +
    '<p>If the service has been active for more than one (1) day the client will be charged.' +
    '</p>';
    modalRef.componentInstance.title = 'Service Activation';
    modalRef.result.then((res: any) => {
      this.services.push( servObj );
      this.clearService();
      this.saveClient( false );
    })
    .catch( (res: any) => {
    });

  }

  today() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  }

  deleteService( id: any ) {
    const servRow = $('#service-row' + id);
    if (servRow.length === 0) {
      alert('Unable to find Service');
      return;
    }

    const serviceName = $(servRow).find('td:first').html();
    const cId = this.services.find( x => x.ID === id );
    if (cId.ExpirationDate !== undefined) {
      return;
    }

    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to deactivate ' +
    '<span class="text-primary">"' + serviceName + '"</span> service?</strong></p>' +
    '<p>If the service has been active for more than one (1) day the client will be charged.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'Service Deactivation';
    modalRef.result.then((res: any) => {
      const cId = this.services.find( x => x.ID === id );
      cId.ExpirationDate = this.today();
    })
    .catch( (res: any) => {
    });

  }



  findStateByName( name: any ) {
    if (!this.clientStates) {
      return null;
    }

    for (var idx = 0; idx < this.clientStates.length; ++idx) {
      var obj = this.clientStates[idx];
      if (obj.state === name) {
        return obj;
      }
    }

    return null;
  }

  findState( id: any ) {
    if (!this.clientStates) {
      return null;
    }

    for ( var idx = 0; idx < this.clientStates.length; idx++ ) {
      if (this.clientStates[idx].ID === id) {
        return this.clientStates[idx];
      }
    }

    return null;
  }


  clearState() {
    $('#newState').val('');
    $('#newStateCount').val('');
    $('#newStateRow').collapse('hide');
  }

  deleteState( id: any ) {
    const stateRow = $('#state' + id);
    if (stateRow.length === 0) {
      alert('Unable to find State');
      return;
    }
    const stateName = $('#state' + id + ' option:selected').text().trim();

    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to remove ' +
    '<span class="text-primary">"' + stateName + '"</span> record?</strong></p>' +
    '<p>All information associated to this State will be permanently deleted.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'State Record';
    modalRef.result.then((res: any) => {
      const cId = this.clientStates.find( x => x.state === stateName );
      if (cId) {
        this.clientStates.splice( cId, 1 );
      }
    })
    .catch( (res: any) => {
    });

  }

}

