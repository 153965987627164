import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../auth/auth.service';
import { IUser } from '../../model/user';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { CompleterService, CompleterData, RemoteData } from 'ng2-completer';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-search-option',
  templateUrl: './search-option.component.html',
  styleUrls: ['./search-option.component.scss']
})
export class SearchOptionComponent implements OnInit {
  public searchStr: string;
  // protected dataService: CompleterData;
  public dataService: CompleterData;
  modal: NgbModalRef;
  show: any = false;
  needClient: any = false;
  private timer: Observable<any>;
  private subscription: Subscription;
  clientAccess: any = false;
  clients: any[] = [];
  currentClientId: any;

  constructor( private modalService: NgbModal,
               private config: NgbDropdownConfig,
               private authService: AuthService,
               private completerService: CompleterService,
               private router: Router,
               private userSer: UserService) {
    config.placement = 'bottom-right';
    // this.dataService = completerService.local(this.searchData, 'color', 'color');
  }

  permissionData: IUser = JSON.parse(localStorage.getItem(('UserData')));

  ngOnInit() {
    this.currentClientId = this.userSer.getClientId();
    if (this.userSer.getMultiClientStatus()) {
      this.needClient = true;
      this.clients = this.userSer.getMultiClientData();
    }
    this.clientAccess = this.userSer.haveAccess('client-profile', true) && 
                  (this.userSer.getUserData().RoleType === 2);
  }

  openModal(content, conentClass) {
    this.modal = this.modalService.open(content, {windowClass: conentClass});
  }

  searchSubject(event) {
    this.router.navigate(['/ticket/' + event.originalObject.id + '/edit']);
    this.modal.close();
    this.searchStr = '';
  }

  closeModal() {
    this.modal.close();
  }

  changeClients( cId ) {
    // let cId = $('#client_id').val();
    // cId = parseInt( cId.toString(), 10 ); // it is already a string but complile is super strict

    const cObj = this.clients.find( x => x.client.ID === cId );
    if (cObj !== undefined) {
      this.userSer.getUser( cObj.UserID ).subscribe( (res: any) => {
        this.authService.getLogin(res.EMail, res.Password).subscribe((data: any) => {
          if (data.status) {
            localStorage.clear();
            localStorage.setItem('token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('ClientID', data.ClientID);
            localStorage.setItem('UserData', JSON.stringify(data.UserData));
            // this.userSer.getLoggedInUser();
            localStorage.setItem('info', JSON.stringify(data));
            if (window.location.href.indexOf('dashboard') > 0) {
                window.location.href = window.location.href + '/';
            } else {
              this.router.navigate(['/dashboard']);
            }

          } else {
              // this.serverError = data.error;
          }
        });
      });
    } else {
      alert('Unable to locate client record. Please logout and back into the selected client');
    }

  }

  logout() {
    this.authService.logout();
  }

  userProfile() {
    this.router.navigate(['/admins/user', { 'personal' : true }]);
  }

  clientSettings() {
    this.router.navigate( ['/admins/client'] );
  }

  showSearch() {
    this.show = true;
  }

  showClients( onOff: any) {
    return ;
    if (onOff) {
      (<any>$('#accountMenu')).collapse( 'show' );
    } else {
      (<any>$('#accountMenu')).collapse( 'hide' );
      // this.setTimer();
      // $('#accountMenu').collapse( 'hide' );
    }
  }

  toggleOptions(event: any) {
    (<any>$("#optionsMenu")).collapse('show');
    console.log('toggled');
  }

  public setTimer() {
    this.timer = Observable.timer(500);
    this.subscription = this.timer.subscribe(() => {
      // $('#accountMenu').collapse( 'hide' );
    });
  }

  searchDocs() {
      this.router.navigate(['/documents/', $('#search-box').val()]);
  }

  openDocs(event) {
    this.router.navigate(['/documents/']);
  }

}

