import {Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { ClientService } from '../../services/client.service';
import { IClients } from '../../model/clients';
import { IPage } from '../../model/page';
import { PartnerService } from '../../services/partner.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { InfoModalComponent } from '../../shared/info-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saver } from 'file-saver';
import { nextTick } from 'process';
declare var require: any;

// export interface IUserValue {
//   Text: string;
//   value: number;
//   status: any;
// }

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})

export class ClientListComponent implements OnInit {
  clients: any;
  partners: any;
  isFilterTrue = true;
  uploadFile: any;
  // userValue: IUserValue;
  paging: IPage;
  per_page = 10;
  selected_status: number;
  filter: any = { 'clientname': '', 'name': '', 'active': '', 'partner': ''};
  curr_user_id: any;
  total: number = 0;
  public row_header: any = [];
  public header_index: any;
  public assignee_array: any = [];
  private allItems: any[];
  showloader: boolean;
  pager: any = {};
  pagedItems: any[];
  crumbList: any[];

  constructor(
    private pagerS: PagerService,
    private adminSer: AdminTiketService,
    private clientSer: ClientService,
    private modalService: NgbModal,
    private infoModalService: NgbModal,
    private partnerSer: PartnerService,
    private ngZone: NgZone) {
      this.showloader = true;
  }

  ngOnInit() {
    const userData = localStorage.getItem('UserData');
    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' }
    ];
    this.curr_user_id = JSON.parse(userData);
    let that = this;
    this.selected_status = null;
    // this.clientSer.getClients().subscribe((data: any) => {
    //   this.clients = data;
    // });

    if (this.curr_user_id.RoleType === 3) {
      this.row_header  = [
        {text: 'Client', value: 'client', 'sort': 'asc'},
        {text: 'Name', value: 'ContactName', 'sort': null},
        {text: 'Active', value: 'Active', 'sort': null},
        {text: 'Business Summary', value: 'BusinessSummary', 'sort': 'none'}
      ];
    } else {
      this.row_header  = [
        {text: 'Client', value: 'client', 'sort': 'asc'},
        {text: 'Name', value: 'ContactName', 'sort': null},
        {text: 'Partner', value:'PartnerID', 'sort': null},
        {text: 'Active', value: 'Active', 'sort': null},
        {text: 'Business Summary', value: 'BusinessSummary', 'sort': 'none'}
      ];
    }
    this.header_index = 1;
    this.partnerSer.getPartners().subscribe((partners: any) => {
      this.partners = partners;
      this.tableSort(0);
    });
  }

  findLocalClient( id: number ) {
     for ( var idx = 0; idx < this.clients.length; idx++ ) {
        const obj = this.clients[idx];
        if (obj.ID === id) {
          return obj;
        }
     }
     return null;
  }

  deleteUser(id: number) {
    const client = this.findLocalClient( id );
    if (client === null) {
      alert('Unable to find Client');
      return;
    }
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Are you sure you want to delete ' +
    '<span class="text-primary">"' + client.ClientName + '"</span> record?</strong></p>' +
    '<p>All information associated to this Client record will be permanently deleted.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'Client Record';
    modalRef.result.then((res: any) => {
      this.clientSer.deleteClient(id).subscribe((data: any) => {
        this.setPage(1);
      });
    })
    .catch( (res: any) => {
    });

  }

  filterByClient(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.clientname = id;
    // this.userSer.getUsersByClient(id).subscribe((data: any) => {
    //   this.users = data;
    // });
    this.setPage(1);
  }

  filterByContactName(event) {
    this.showloader = true;
    const id = event.target.value;
    this.filter.name = id;
    this.setPage(1);
  }

  filterByActive(event) {
    this.showloader = true;
    const searchStr = event.target.value;
    this.filter.active = searchStr;
    this.setPage(1);
  }

  filterByPartner(event) {
    this.showloader = true;
    const partId = event.target.value;
    this.filter.partner = partId;
    this.setPage(1);
  }

  findPartner( pId ) {
    for(var idx = 0; idx < this.partners.length; idx++ ) {
      if (this.partners[idx].ID === pId) {
        return this.partners[idx].CompanyName;
      }
    }
    return '';
  }

  populatePartners() {
    for(var idx = 0; idx < this.clients.length; idx++) {
       const pId = this.clients[idx].PartnerID;
       this.clients[idx].PartnerName = this.findPartner( pId );
    }
  }

  setPage(page: number = 1) {
        const that = this;

        this.showloader = true;
        this.clientSer
          .getAllClients( page, this.per_page, this.row_header[this.header_index], this.filter)
          .subscribe( {
              next(data: any) {
                that.showloader = false;
                that.clients = data.data; // return all data
                that.populatePartners();
                that.paging = data.page; // return all the paginationinfo
                that.total = that.paging.total;
                that.pager = that.pagerS.getPager(that.paging.total, page, that.per_page);
          },
          error(msg) {
            that.ngZone.run( () => {
              that.showloader = false;
            });
          }
        });
  }

  tableSort(index) {
    if (this.row_header[index].sort === 'none') {
       return;
    }

    this.showloader = true;
    if (this.header_index === index) {
      (this.row_header[index].sort === 'asc') ? this.row_header[index].sort = 'desc' : this.row_header[index].sort = 'asc';
    } else {
      this.row_header[index].sort = 'asc';
      this.row_header[this.header_index].sort = null;
      this.header_index = index;
    }
    this.setPage(1);
  }

  downloadClients() {
    const that = this;
    this.clientSer
    .getClientDownload( this.row_header[this.header_index], this.filter)
    .subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const fileName = 'client-list.csv';

      const FileSaver = require('file-saver');
      // const blob2 = new Blob( blob, {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, fileName);

    });

  }

  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Information';
    modalRef.componentInstance.trustHtml = true;
    modalRef.result.then((res: any) => {
    });

  }


  getFile(event) {
    // this.files = <File>event.target.files[0];
    this.uploadFile = <File>event.target.files;
    this.overwriteClients();
  }

  overwriteClients() {
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Overwrite or update existing records. ' +
    '<p>We <strong>strongly</strong> recommend to <span class="text-danger">Add Only</span>.' +
    ' All information associated to those Client record(s) will be permanently altered.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'Client Upload';
    modalRef.componentInstance.okBtnTitle = 'Overwrite';
    modalRef.componentInstance.cancelBtnTitle = 'Add Only';
    modalRef.result.then((res: any) => {
       this.importUsers( '1' );
       return;
    }).catch((res: any) => {
      this.importUsers( '0' );
    });
  }

  importUsers(overwrite) {
    const modalRef = this.modalService.open( ModalComponent );
    modalRef.componentInstance.confirmationStr = '<p><strong>Overwrite or update existing records. ' +
    '<p>We <strong>strongly</strong> recommend to <span class="text-danger">Add Only</span>.' +
    ' All information associated to those Client record(s) will be permanently altered.' +
    '<span class="text-danger"> This operation can not be undone.</span>' +
    '</p>';
    modalRef.componentInstance.title = 'Upload Users';
    modalRef.componentInstance.okBtnTitle = 'Yes';
    modalRef.componentInstance.cancelBtnTitle = 'No';
    modalRef.result.then((res: any) => {
       this.uploadClients( overwrite, '1' );
       return;
    }).catch((res: any) => {
      this.uploadClients( overwrite, '0' );
    });
  }


  uploadClients( overwrite, users ) {
    const that = this;
    const data = new FormData();
    data.append('file', this.uploadFile[0], this.uploadFile[0].name);

    this.showloader = true;
    this.clientSer.uploadClients(data, overwrite, users).subscribe( { 
      next(resp: any) {
        that.showloader = false;
        const results = resp.results;
        let msg = '<p><strong>Imported ' + results[0] + '</br>' +
                'Duplicate records ' + results[1] +  '</br>';
        if (overwrite === '1') {
          msg += 'Overwritten records ' + results[2] + '</br>';
        }
        msg += 'Rows with invalid data ' + results[3] + '</br>';
        if (users === '1') {
          msg += 'Imported users ' + results[4] + '</br>';
          if (overwrite === '1') {
            msg += 'Overwritten users ' + results[5] + '</br>';
          }
          msg += 'User rows with invalid data ' + results[6] + '</br>';
        }
        msg += '</strong></p>';

        that.displayMessage( msg );
      },
      error(msg) {
        that.ngZone.run( () => {
          that.showloader = false;
          that.displayMessage('Error processing file <br/> ' + msg );
        });
      }
    });


  }

}


