import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { IContent, CONTENT_TYPE, STYLE_TYPE } from '../../model/content';
import { ContentService } from '../../services/content.service';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { ModalComponent } from '../../shared/modal.component';
import { InfoModalComponent } from '../../shared/info-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as jquery from 'jquery';
import { state } from '@angular/animations';
import { ClickOutsideDirective } from 'ng-multiselect-dropdown/click-outside.directive';

declare var $: any;

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, AfterViewInit {
  content: IContent;
  content_type: any[] = CONTENT_TYPE;
  style_type: any[] = STYLE_TYPE;

  id: any;
  active: any;
  showloader = true;
  successful_result = false;
  error_result = false;
  dropdownList = [];
  dropdownSettings: {};
  form: FormGroup;
  title: string;
  submitted = false;
  post_results: string;
  private subscription: Subscription;
  private timer: Observable<any>;
  crumbList: any[];
  updateList: any[];
  userData: any;
  public options: Object = {
    key: 'eC6D6C2E3E2H2xC4B3A3C2B5A1B1E4F1A2sd1Te1LXe1URVJe1DWXG==',
    // key: 'dC10B5D5A2A-9H3E2J2A4C6C3C2B5B1E1pj1wgbrD-11fzfsB-22B3zuv==',
    // key: 'eHE5C-11B2C1F2I2A5C4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4D3E4C2G2E3C1B2==',
    height: 370,
    attribution: false,
    charCounterCount: false
  };

  TypeCtrl: FormControl;
  TitleCtrl: FormControl;
  EffDateCtrl: FormControl;
  StyleCtrl: FormControl;
  LinkCtrl: FormControl;
  // editor: any;

  constructor(
    private http: HttpClient,
    private userSer: UserService,
    private contentSer: ContentService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private infoModalService: NgbModal,
    private actRoute: ActivatedRoute,
    private ngZone: NgZone,
    private router: Router ) {

      this.showloader = true;
  }

  ngOnInit() {
    this.showloader = true;

    this.userData = this.userSer.getUserData();
    if (!this.userData || !this.userData.roleprofile) {
      this.router.navigate(['/dashboard']);
    }

    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' },
      { title: 'Content List', url: '/admins/content-list' }
    ];

    this.TypeCtrl = new FormControl('Type', [Validators.required]);
    this.TitleCtrl = new FormControl('Title', [Validators.required]);
    this.EffDateCtrl = new FormControl('EffDate', [Validators.required]);
    this.StyleCtrl = new FormControl('Style');
    this.LinkCtrl = new FormControl('Link');

    this.form = this.fb.group({
      Type: this.TypeCtrl,
      Title: this.TitleCtrl,
      EffDate: this.EffDateCtrl,
      Style: this.StyleCtrl,
      Link: this.LinkCtrl
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'Code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    this.actRoute.params.subscribe( params =>
      this.id = parseInt( params['id'], 10 )
    );

    if (this.id === undefined || this.id === 0 || isNaN(this.id)) {
      this.id = 0;
      this.title = 'New Content';
      this.form.patchValue({
        Type: 0,
        Title: '',
        EffDate: this.today(),
        Style: 1,
        Link: ''
      });
      // this.editor = new FroalaEditor('#body',
      //   this.options
      // );
      this.showloader = false;
    } else {
      this.contentSer.getContent( this.id ).subscribe((contentData: any) => {
        this.content = contentData;
        this.title = 'Edit Conent';

        const eDate = contentData.EffDate;
        this.form.patchValue({
          Type: contentData.Type,
          Title: contentData.Title,
          EffDate: { 'year': this.getYear(eDate) , 'month': this.getMonth(eDate), 'day': this.getDay(eDate) },
          Style: contentData.Style,
          Link: contentData.Link
          //  Body: contentData.Body
        });
        const bodySt = contentData.Body;
        $('#body').froalaEditor('html.set', bodySt );
        // froala 3.0 code
        // let edit = new FroalaEditor('#body',
        //   this.options,
        //   function() {
        //     edit.html.set( bodySt );
        //   }
        // );
        // this.editor = edit;
 
        this.showloader = false;
      });
    }
  }

  ngAfterViewInit() {
  }

  getYear( dt: any ) {
    if (dt.length < 10) {
      return 2020;
    }
    const s = dt.substr(0, 4);
    return parseInt( s, 10 );
  }

  getMonth( dt: any ) {
    if (dt.length < 10) {
      return 1;
    }
    const s = dt.substr(5, 2);
    return parseInt( s, 10 );
  }

  getDay( dt: any ) {
    if (dt.length < 10) {
      return 1;
    }
    const s = dt.substr(8, 2);
    return parseInt( s, 10 );
  }

  saveContent() {
    // this.submitted = true;
    this.showloader = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showloader = false;

      return;
    }

    if (this.form.valid) {
      const fd = new FormData();

      fd.append('Type', this.form.value.Type);
      fd.append('Title', this.form.value.Title);
      let effDate = this.form.value.EffDate;
      effDate = effDate.year + '-' + effDate.month + '-' + effDate.day;
      fd.append('EffDate', effDate);
      // fd.append('Body', this.editor.html.get() );
      fd.append('Body', $('#body').froalaEditor('html.get'));
      if (this.form.value.Style) {
        fd.append('Style', this.form.value.Style);
      }
      if (this.form.value.Link) {
        fd.append('Link', this.form.value.Link );
      }

      const that = this;
      if (this.id === 0) {
         this.contentSer.createContent(fd).subscribe(
           {

            next(res: any) {
              if (res.status) {
                that.successful_result = true;
                that.id = res.data.ID;
                that.showloader = false;
                that.setTimer();
              } else {
                that.error_result = true;
                console.log('unable to save');
              }
              that.setTimer();
            },
            error(msg) {
              that.ngZone.run( () => {
                that.showloader = false;
                that.displayMessage( 'Error saving record.' );
              });
            }
          });

      } else {
        this.contentSer.saveContent(this.id, fd ).subscribe((res: any) => {
          if (res) {
             this.successful_result = true;
          } else {
            this.error_result = true;
            this.showloader = false;
            console.log('unable to save');
          }
          this.setTimer();
        });
      }
      this.submitted = false;
    }
  }

  public setTimer() {
    this.timer = Observable.timer(2500);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
        this.showloader = false;
      });
  }

  toggleChange(event: any) {
    this.active = (event.target.checked ? 'Yes' : 'No');
  }

  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Warning';
    modalRef.result.then((res: any) => {
    });

  }

  today() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  }


}

