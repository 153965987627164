import { AfterViewInit, Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { ClientService } from '../../../services/client.service';
import { AdminTiketService } from '../../../services/admin-tiket.service';
import { environment } from '../../../../environments/environment';
import { TicketService } from '../../../services/ticket.service';
import {NgbModal, NgbModalRef, ModalDismissReasons, NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from '../../../shared/info-modal.component';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';
import { CodeService } from 'src/app/services/code.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DomSanitizer } from '@angular/platform-browser';

import * as jquery from 'jquery';
import { analyzeAndValidateNgModules } from '@angular/compiler';

declare var $: any;



class MetaFile {
  public name: string;
  public filename: string;
  public uploaded_at: number;
  public userName: string;
  constructor(filename: string) {
    this.filename = filename;
  }
}

@Component({
  selector: 'app-edit-ticket',
  templateUrl: './edit-ticket.component.html',
  styleUrls: ['./edit-ticket.component.scss']
})

export class EditTicketComponent implements  OnInit {
  id: any;
  form: FormGroup;
  submitted = false;
  subject: any;
  username: any;
  useremail: any;
  created_at: any;
  conversation: any;
  file_data: Array<any>;
  metaFiles: MetaFile[] = [];
  files: Array<any> = null;
  curr_user_id: any;
  token: any;
  clients: any;
  users: any;
  subjects: any;
  assignedUser: any;
  type: any = 1;
  btnText: any = 'Submit';
  default_status: any = 1;
  default: any = 0;
  company_name: any;
  company_info: any;
  avatar: any;
  modal: NgbModalRef;
  custom_image_title: any = '';
  showloader: boolean;
  userType: number;
  keywords: any;
  dropdownList = [];
  CCemailList = [];
  agentCCemailList = [];
  dropdownSettings = {};
  CCdropdownSettings = {};
  agentCCdropdownSettings = {};
  agentCC = '';
  clientCC = '';
  clientCCSelected = {};
  agentCCSelected = {};
  ticket: any;
  baseUrl: any;
  serviceList: any = '';
  userIDCtrl: FormControl;
  empTable: any = [];

  // editor: any;
  public options: Object = {
    key: 'eC6D6C2E3E2H2xC4B3A3C2B5A1B1E4F1A2sd1Te1LXe1URVJe1DWXG==',
    // key: 'dC10B5D5A2A-9H3E2J2A4C6C3C2B5B1E1pj1wgbrD-11fzfsB-22B3zuv==',
    // key: 'eHE5C-11B2C1F2I2A5C4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4D3E4C2G2E3C1B2==',
    charCounterCount: true,
    attribution: false,
    height: 145,
    toolbarButtons: ['bold', 'italic', 'underline', 'align', 'undo', 'redo', 'strikeThrough',
                     'color', 'paragraphFormat', 'paragraphStyle', 'emoticons']
  };



  constructor(private activteRoute: ActivatedRoute,
        private modalService: NgbModal,
        private ticketS: TicketService,
        private fb: FormBuilder,
        private adminSer: AdminTiketService,
        private userSer: UserService,
        private infoModalService: NgbModal,
        private sanitizer: DomSanitizer,
        private codeSer: CodeService,
        private clientSer: ClientService,
        private ngZone: NgZone) {

    this.showloader = false;
    this.baseUrl = environment.apiUrl;

  }

  ngOnInit() {
    this.id =  this.activteRoute.snapshot.params.id;
    const userData = localStorage.getItem('UserData');
    this.curr_user_id = JSON.parse(userData);
    this.token = localStorage.getItem('token');
    this.userSer.selectMenu('askanattorney');

    this.userIDCtrl = new FormControl({value: '', disabled: true});
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    this.CCdropdownSettings = {
      singleSelection: false,
      idField: 'UserID',
      textField: 'EMail',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false
    };

    this.form = this.fb.group ({
      keyword: '',
      assignedTo: '',
      clientId: new FormControl({value: '', disabled: true}),
      userID: this.userIDCtrl,
      statusId: '',
      ticketSubject: new FormControl({value: '', disabled: true}),
      ticketSubjectMatter: new FormControl({value: '', disabled: true}),
      clientCC: new FormControl( {value: ''}),
      agentCC: ''
    });

    this.clientSer.getClients().subscribe((data: any) => {
      this.clients = data;
    });

    this.codeSer.getSubjectMatter().subscribe((data: any) => {
      this.subjects = data;
    });

    this.userSer.getAgents().subscribe((data: any) => {
      this.assignedUser = data;
    });

    this.adminSer.getKeywords().subscribe((data: any) => {
      this.dropdownList = data;
    });

   this.activteRoute.params.subscribe(params => {
      this.id = +params['id'];
      this.ticketS.getSingleTickets(this.id).subscribe((data: any) => {
        this.subject = data.ticket.subject;
        this.username = data.ticket.user.FirstName;
        this.ticket = data.ticket.id;
        this.useremail = data.ticket.user.EMail;
        this.company_name = data.ticket.user.client.ClientName;
        this.company_info = data.ticket.user.client.BusinessSummary;
        this.avatar = data.ticket.user.client.Image;
        this.created_at = data.ticket.created_at;
        this.conversation = data.conversation;
        this.conversation.forEach( c => c.reply = this.sanitizer.bypassSecurityTrustHtml( c.reply ) );
        this.file_data = data.files;
        this.parseFilenames( this.file_data );
        if (data.ticket.user.client.ID) {
          this.userSer.getUsersByClient(data.ticket.user.client.ID).subscribe((fData: any) => {
            this.CCemailList = fData;
            this.users = fData;
            this.clientCCSelected = this.findSelectedUsers( data.ticket.cc, fData );
          });
        }
        this.form.patchValue({
          ticketSubject: data.ticket.subject,
          ticketSubjectMatter: data.ticket.subjects.ID,
          clientId: data.ticket.user.client.ID,
          userID: data.ticket.user.UserID,
          statusId: data.ticket.status,
          keyword: (data.ticket.tag == null) ? '' : data.ticket.tag,
          assignedTo: (data.ticket.assigned_to == null) ? null : data.ticket.assigned_to,
          clientCC: this.clientCCSelected,
          agentCC: this.agentCCSelected,
          empQuestion: data.ticket.EmpQuestion
        });
        this.parseEmps( data.ticket );
        if (data.ticket.EmpQuestion === 1) {
          $('#conflict').collapse( 'show' );
        }

        this.userSer.getAgents().subscribe((agentUsers: any) => {
          this.agentCCemailList = agentUsers;
          this.users = agentUsers;
          this.agentCCSelected = this.findSelectedUsers( data.ticket.cc_agent, agentUsers );
        });

        if (this.userSer.isAgentOrGreater( this.curr_user_id.UserType )) {
          this.form.get('ticketSubjectMatter').enable();
          const services = JSON.parse( data.ticket.user.client.ClientServices );
          let serv: any = '';
          for (var idx = 0; idx < services.length; idx++ ) {
             const service = services[idx];
             if (service.ExpirationDate === undefined) {
               if (serv.length > 0 ) {
                 serv += ' ' ;
               }
               serv += service.Code;
             }
          }
          this.serviceList = serv;
        }
        // this.editor = new FroalaEditor('#response',
        //   this.options,
        //   function () {
        //   }
        // );
        this.showloader = true;
      });
    });
  }

  reopenTicket() {
    const data = new FormData();
    data.append('status', '2');
    const that = this;
    this.ticketS.updateTickets(data, this.token, this.id).subscribe(
      {
        next(fData: any) {
          that.form.value.statusId = 2;
          that.displayMessage('Your Ticket has reopened.');
        }
      }
    );
  }

  parseEmps( ticket ) {
    try {
       this.empTable = JSON.parse( ticket.EmpTable );
    } catch (e) {
       this.empTable = [];
    }
    if (this.empTable !== null && this.empTable !== undefined) {
      for (var i = 0; i < this.empTable.length; i++ ) {
        let rec = this.empTable[i];
        rec.empState = rec.empState.toUpperCase();
      }
    } else {
      this.empTable = [];
    }
  }

  findSelectedUsers( ccStr: string, userList: any ): any {
    let result = new Array();
    if (ccStr === null || ccStr === undefined || ccStr.length === 0) {
      return result;
    }
    const ccList = ccStr.split(',');

    for (var cIdx = 0; cIdx < ccList.length; cIdx++ ) {
       let cc = ccList[cIdx];
       for (var uIdx = 0; uIdx < userList.length; uIdx++ ) {
          if (cc === userList[uIdx].EMail) {
            result.push( userList[uIdx] );
          }
       }
    }
    return result;
  }

  parseFilenames( files: Array<any>) {
    this.metaFiles = [];
    for (let i = 0; i < files.length; i++ ) {
      const meta = new MetaFile( files[i].filename );

      meta.filename = files[i].filename;
      meta.uploaded_at = files[i].uploaded_at;
      let fname: string;
      fname = files[i].filename == null ? '' : files[i].filename;
      const splitStr = fname.split( '=' );
      if (splitStr.length > 1) {
        meta.name = splitStr[1];
      } else {
        meta.name = files[i].filename;
      }

     this.metaFiles.push( meta );
    }
  }


  getFiles(event) {
    // this.files = <File>event.target.files[0];
    this.files = <Array<File>>event.target.files;
    let fNames: any = '';
    for (let i = 0; i < this.files.length; i++ ) {
      if (fNames.length > 0) {
        fNames = fNames + ', ' + this.files[i].name;
      } else {
        fNames = this.files[i].name;
      }
    }
    this.custom_image_title = fNames;
  }

   getRequester(event) {
    const id = event.target.value;
    this.userSer.getUsersByClient(id).subscribe((data: any) => {
      this.CCemailList = data;
    });
    this.userSer.getAgents().subscribe((data: any) => {
      this.agentCCemailList = data;
      this.users = data;
    });
  }

  // all to internal note change
  changeType(val) {
    this.type = val;
  }

  // get f() {
  //   return this.form.controls;
  // }

  updateForm() {
    this.submitted = true;
    this.btnText = 'Replying...';
    if (this.form.invalid) {
      console.log( this.form );
      this.btnText = 'Submit';
      return;
    }
    if (this.form.valid) {
      const data = new FormData();

      let replyText;

      // replyText = this.editor.html.get().trim();
      replyText = $('#response').froalaEditor('html.get');

      if (this.files !== null) {
        for (let i = 0; i < this.files.length; i++) {
          data.append('file', this.files[i], this.files[i].name);
        }
        if (this.files.length > 0) {
          data.append( 'sendEmail', 'true' );
          let len;
          len = replyText.length;
          if (replyText.split('</p>').length > 0 && len > 4) {
            let lastfour;
            lastfour = replyText[len-4] + replyText[len-3] + replyText[len-2] + replyText[len-1];
            if (lastfour === '</p>') {
               replyText = replyText.substr(0, len-4) +
                        '<img class="paperclip" src="assets/images/file.png" alt="File Attached"></p>';
            } else {
              replyText = replyText +
                        '<p><img class="paperclip" src="assets/images/file.png" alt="File Attached"></p>';
            }
          } else {
            replyText = replyText + '<p>File attached--no reply message</p>'
                        + '<div class="paperclip"><img src="assets/images/file.png" alt="File Attached"></div>';
          }
        }
      }
      data.append('reply', replyText);

      data.append('type', this.type);
      if (this.form.value.ticketSubjectMatter) {
        data.append('subject_matter', this.form.value.ticketSubjectMatter);
      }
      if (this.form.value.agentCC) {
        data.append('cc_agent', this.userToEmailStr( this.form.value.agentCC));
      }
      if (this.form.value.clientCC) {
        data.append('cc', this.userToEmailStr( this.form.value.clientCC));
      }
      if (this.form.value.assignedTo) {
        data.append('assigned_to', this.form.value.assignedTo);
      }
      if (this.form.value.keyword) {
        this.form.value.keyword = Array.prototype.map.call(this.form.value.keyword, s => s.name).toString();
        data.append('keyword', this.form.value.keyword);
      }
      if (this.form.value.clientId) {
        data.append('client_id', this.form.value.clientId);
      } else {
        data.append('client_id', this.curr_user_id.client.ID);
      }
      if (this.form.value.userID) {
        data.append('requester', this.form.value.userID);
      } else {
        data.append('requester', this.curr_user_id.UserID);
      }
      if (this.form.value.statusId) {
        data.append('status', this.form.value.statusId);
      } else {
        data.append('status', this.default_status);
      }

      const that = this;

      this.ticketS.updateTickets(data, this.token, this.id).subscribe(
      {
        next(fData: any) {
              that.showloader = false;
              that.conversation = fData.conversation;
              that.conversation.forEach( c => c.reply = that.sanitizer.bypassSecurityTrustHtml( c.reply ));
              that.btnText = 'Submit';
              that.file_data = fData.files;
              that.parseFilenames( that.file_data );
              // that.editor.html.set('');
              $('#response').froalaEditor('html.set', '');

              that.custom_image_title = '';
        },
        error(msg) {
          that.ngZone.run( () => {
            that.btnText = 'Submit';
          });
        }
      });

      this.files = [];
      this.submitted = false;
    }
  }

  userToEmailStr( ccArr ): string {
    let ccStr = '';
    for (var uIdx = 0; uIdx < ccArr.length; uIdx++ ) {
      if (ccStr.length > 0) {
        ccStr = ccStr + ',' + ccArr[uIdx].EMail;
      } else {
        ccStr = ccArr[uIdx].EMail;
      }
     }
     return ccStr;
  }

  openModal(content, conentClass) {
    this.modal = this.modalService.open(content, { windowClass: conentClass });
  }

  closeModal() {
    this.modal.close();
  }


  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Heads Up';
    modalRef.result.then((res: any) => {
    });

  }

}
