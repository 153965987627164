import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { ITemplate } from '../model/template';
import { rolesStatus} from '../model/roles';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }

  getTemplates(): Observable<ITemplate> {
    return this.http.get(`${environment.apiUrl}template/` )
       .pipe(map((res: any) => {
         return res;
       }));

  }


  //
  // Creates a new Template record
  //
  createTemplate( form ) {

    return this.http.post(`${environment.apiUrl}template/`, form)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

  //
  // Update a template record
  //
  saveTemplate( name, form ) {
    return this.http.post(`${environment.apiUrl}template/` + name, form
    )
    .pipe(
      map((res: any) => {
        if (res.status) {
          return res.status;
        }
      })
    );
  }

  //
  // Retrieve Template
  //
  getTemplate( name ): Observable <ITemplate> {
    return this.http.get(`${environment.apiUrl}template/` + name)
        .pipe(
           map((res: any) => {
             if (res.status) {
               return res;
             }
           })
        );
  }


  //
  // Delete a new template record
  //
  deleteTemplate( name ): Observable <any> {

    return this.http.delete(`${environment.apiUrl}template/` + name)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

}
