import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { rolesStatus} from '../model/roles';
@Component({
  selector: 'app-admin-base',
  templateUrl: './admin-base.component.html',
  styleUrls: ['./admin-base.component.scss']
})


export class AdminBaseComponent implements OnInit {

  public roles;
  constructor(private userSer: UserService) {
  }

  ngOnInit() {

  }

}
