import {Component, OnInit, AfterViewInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError, from } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { IPost } from '../../model/post';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDBPage } from '../../model/db-page';

@Component({
  selector: 'app-handbook',
  templateUrl: './handbook.component.html',
  styleUrls: ['./handbook.component.scss'],
})

export class HandbookComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private ngZone: NgZone) {
  }

  ngOnInit() {
    const userData = localStorage.getItem('UserData');
  }

  ngAfterViewInit() {
     $('#daquestion').removeClass('offset-xl-3').removeClass('offset-lg-3')
          .removeClass('col-xl-6').removeClass('col-lg-6');
  }

}


