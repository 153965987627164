import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import 'rxjs/add/observable/fromPromise';
import {map, catchError, switchMap} from 'rxjs/operators';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

export interface IToken {
  access_token: string;
  refresh_token: string;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router) {

  }


  private token;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private loginUrl = 'auth/login';


  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) {
       return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  getToken(): any {
    return localStorage.getItem('token');
  }

  getRefreshToken(): any {
    return localStorage.getItem('refresh_token');
  }

  isTokenExpired(token?: string): boolean {
     if (!token) {
       token = this.getToken();
     }
     if (!token) {
       return true;
     }

     const date = this.getTokenExpirationDate(token);
     if (date === undefined) {
        return true;
     }
     return !(date.valueOf() > new Date().valueOf());
   }

  logout() {
     if (!this.isTokenExpired( this.getRefreshToken() ) &&
         !this.isTokenExpired( this.getToken() ) ) {
        // revoke the token but going to login page so throw away the result
        this.http.post( `${environment.apiUrl}auth/logout`, {} ).subscribe();
     }

     localStorage.clear();
     return this.router.navigate(['/login']);
  }

  //
  // Login Method
  //
  getLogin(email: string, password: string) {
     return this.http.post(`${environment.apiUrl}auth/login`, { email, password },
     {
     }).pipe( map( (res: any) => {
       return res; }));
  }


  refreshToken(): Observable<any> {
    const refreshToken: any = this.getRefreshToken();
    localStorage.setItem('token', refreshToken);
    return this.http.post<IToken>(`${environment.apiUrl}auth/refresh`, { refreshToken} )
      .pipe(
          map( tok => {

           localStorage.setItem('token', tok.access_token);
           localStorage.setItem('refresh_token', tok.refresh_token);
           localStorage.setItem('info', JSON.stringify(tok));

           return <IToken>tok;
         }),
         catchError(err => {
            this.logout();
            throwError(err);
            return Observable.throw( <IToken>null );
         })
      );

  }


  /**
   * Reset Password Method
   */
  sendResetPassword(data, token) {
    return this.http.post(`${environment.apiUrl}auth/reset-password`, data,
      {
        headers: new HttpHeaders(
          {
            'accept': 'application/json',
            'Authorization': `Bearer` +  token
          })
      }).
    pipe(map((res: any) => { return res; }));
  }

  verifyToken(token) {
    return this.http.get(`${environment.apiUrl}auth/verify-token/` + token ,
      {
        headers: new HttpHeaders(
          {
            'accept': 'application/json'
          })
      }).
    pipe(map((res: any) => { return res; }));
  }

  resetPassword(data, token) {
    return this.http.post(`${environment.apiUrl}auth/verify-token/` + token , data,
      {
        headers: new HttpHeaders(
          {
            'accept': 'application/json'
          })
      }).
    pipe(map((res: any) => { return res; }));
  }

  /**
   * Forgot Password Method
   */
  forgotPassword(data) {
    return this.http.post(`${environment.apiUrl}auth/forget-password`, data,
      {
        headers: new HttpHeaders(
          {
            'accept': 'application/json'
          })
      }).
    pipe(map((res: any) => { return res; }));
  }

   /**
    * getSignup
    */
   logoutMethod() {
    localStorage.clear();
    this.router.navigate(['/login']);
   }

}
