import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { TicketService } from '../../services/ticket.service';
import { ITicket } from '../../model/ticket';
import { Observable, of, pipe, throwError } from 'rxjs';
import { mergeMap, map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';
import { InfoModalComponent } from '../../shared/info-modal.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Chart } from 'chart.js';
import { UserService } from 'src/app/services/user.service';
import { StatesService } from 'src/app/services/states.service';
import { CodeService } from 'src/app/services/code.service';
import { ContentService } from 'src/app/services/content.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  tickets: any[];
  openTickets: any[];
  closedTickets: any[];
  chart: any;
  sorting: any = {text: 'Created At', value: 'created_at', 'sort': 'desc'};
  filter: any = { 'ticket_number': '', 'subject_matter': '', 'client': '',
                  'requester': '', 'assigned_to': '', 'subject': '', 'search': ''};

  page: any = 1;
  showloader: boolean;
  unassigned_ticket: any = 0;
  userData: any;
  displayStates: any[] = [];
  allStates: any;
  totalStateUpdates: any = 0;
  contentList: any[];
  newslettersList: any[];
  servicesList: any[];
  newsList: any[];
  webinarsList: any[];
  tipsList: any[];
  blogsList: any[];
  ClientName: any;
  allTickets: any[];
  ticketsExtraInfo: any;
  theNewsletter: any = null;
  theState: any;
  newsletterBrief: any;
  canSeeTickets: any = false;

  ASK: any = 'ASK HR';
  ASKPRO: any = 'ASK Pro';
  FMLA: any = 'FMLA';
  HANDBOOK: any = 'Handbook';  

  constructor( private ticketS: TicketService,
    private ngZone: NgZone,
    private userSer: UserService,
    private codeSer: CodeService,
    private statesSer: StatesService,
    private sanitizer: DomSanitizer,
    private infoModalService: NgbModal,
    private contentSer: ContentService,
    private router: Router
    ) {
    this.showloader = true;
  }

  ngOnInit() {
    const that = this;
    this.showloader = true;
    this.userSer.selectMenu('dashboard');
    this.ClientName = this.userSer.getClientName();
    if (this.userSer.getUserData().RoleType === 2) {
      this.canSeeTickets = this.userSer.haveService( this.ASK ) ||
                          this.userSer.haveService( this.ASKPRO ) ||
                          this.userSer.haveService( this.FMLA );
    }

    this.userData = this.userSer.getUserData();
    this.statesSer.getStatesData().subscribe( (stateData: any) => {
        this.allStates = stateData;
        this.parseDisplayStates();
        this.allStates.forEach( (st) => {
          this.totalStateUpdates += st.NewUpdates;
        });
        this.contentSer.getContentData().subscribe( (contentData: any) => {
          this.contentList = contentData;
          this.servicesList = this.contentList.filter( x => x.Type === 1 );
          if (this.servicesList.length > 6) {
            this.servicesList = this.servicesList.slice(0,6);
          }
          const toolsList = localStorage.getItem('toolsList');
          if (toolsList !== null && toolsList.length > 0 && toolsList !== 'undefined') {
            this.servicesList = JSON.parse( toolsList );
          }

          this.newslettersList = this.contentList.filter( x => x.Type === 2 );
          if (this.newslettersList.length > 0) {
            this.theNewsletter = this.newslettersList[0];
            if (this.theNewsletter.Body.length > 190) {
              this.newsletterBrief = $(this.theNewsletter.Body).text().substring(0, 190) + '  ';
              let foundSpace = false;
              let idx = 189;
              while (!foundSpace && idx > 150) {
                const letter = this.newsletterBrief.substr(idx, 1);
                foundSpace = (letter === ' ');
                if (!foundSpace) {
                  this.newsletterBrief = this.newsletterBrief.substring(0, idx );
                }
                --idx;
              }

            } else {
              this.newsletterBrief = $(this.theNewsletter.Body).text() + '  ';
            }
                  }
          this.blogsList = this.contentList.filter( x => x.Type === 3 );
          this.webinarsList = this.contentList.filter( x => x.Type === 4 );
          this.tipsList = this.contentList.filter( x => x.Type === 5 );
          this.newsList = this.contentList.filter( x => x.Type === 6 );
          this.showloader = false;
        });
        let that = this;
        if (this.canSeeTickets) {
          this.ticketS
          .getTickets(null, 1, this.sorting, this.filter, 500)
          .subscribe( {
              next(data: any) {
                that.tickets = data.data; // return athe all data
                that.ticketsExtraInfo = data.extra_data; // return the all status info
                // that.allTickets = that.ticketsExtraInfo.all_tickets;
                that.openTickets = that.tickets.filter( x => x.status !== 3 ).slice(0, 5);
                that.closedTickets = that.tickets.filter( x => x.status === 3 ).slice(0, 5);
                this.showloader = false;

                if (that.ticketsExtraInfo.unassigned_ticket > 0 || that.ticketsExtraInfo.unsolved_ticket > 0) {
                  (<any>$('#openTickets')).collapse( 'show' );
                  $('.tab[data-target="#openTickets"]').addClass('selected');
                } else {
                  (<any>$('#closedTickets')).collapse( 'show' );
                  $('.tab[data-target="#closedTickets"]').addClass('selected');
                }

              }});
        }
    });
  }

  // weird error binding with html
  theStateGo() {
    this.router.navigate(['/state-update/' + this.theState.ID] );
  }

  tickFilter( status ) {
    if (status === 3) {}
  }

  parseDisplayStates() {
    this.displayStates = [];
    let homeStateName = '';
    let homeStateAbb = '';

    const noState: any = [];
    noState.ID = 0;
    noState.Name = 'Home State Not Defined';
    noState.NewUpdates = 0;
    noState.State = '';

    if (this.userData.client && this.userData.client.State) {
      homeStateAbb = this.userData.client.State.toUpperCase().trim();
      const obj = this.allStates.find( x => x.State === homeStateAbb );
      if (obj !== undefined) {
        homeStateName = obj.LongName;
      } else {
        homeStateName = 'Home State Not Defined';
      }
    }



    const stObj = this.allStates.find( x => x.State === homeStateAbb );
    if (stObj !== undefined) {
      const dispSt: any = [];
      dispSt.ID = stObj.ID;
      dispSt.Name = homeStateName;
      dispSt.NewUpdates = stObj.NewUpdates;
      dispSt.State = homeStateAbb;
      this.displayStates.push( dispSt );
    } else {
      this.displayStates.push( noState );
    }

    if (this.userData.client && this.userData.client.EmpsByState) {
      const empsStates = JSON.parse( this.userData.client.EmpsByState );
      if (empsStates !== undefined) {
        for (let idx = 0; idx < empsStates.length; idx++ ) {
          const eSt = empsStates[idx].state;
          if (eSt !== homeStateAbb) {
            const uobj = this.allStates.find( x => x.State === eSt );
            if (uobj !== undefined) {
              const dispSt: any = [];
              dispSt.Name = uobj.LongName;
              dispSt.NewUpdates = uobj.NewUpdates;
              dispSt.State = eSt;
              dispSt.ID = uobj.ID;
              this.displayStates.push( dispSt );
            }
          }
        }
      }
    }
    if (this.displayStates.length > 0) {
      this.theState = this.displayStates[0];
    } else {
      this.theState = noState;
    }

  }

  selectMe( event: any ) {
    $('.tab').removeClass('selected');
    $(event.target).addClass('selected');
    (<any>$('#openTickets')).collapse('hide');
    (<any>$('#closedTickets')).collapse('hide');
  }

  selectTick( id: any ) {
    this.router.navigate(['/ticket/' + id + '/edit']);
  }

  selectNewsletter() {
    this.router.navigate(['/content/newsletter/' + this.theNewsletter.ID]);
  }


  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Warning';
    modalRef.result.then((res: any) => {
    });

  }

  displayService( title: any, msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent, { size: 'lg' } );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = title;
    modalRef.result.then((res: any) => {
    });

  }

  openUrl(event: any ) {
    // window.open( service.Link, '_blank');

  }

  askModal( id: any ) {
    const service = this.servicesList.find( x => x.ID === id);
    if (service === undefined) {
      this.displayMessage('Unable to locate service offering at this time');
    }
    if (service.Style === 2) {
      window.open( service.Link, '_blank');
    } if (service.Style === 3) {
        const link = this.InsertCompany( service.Link );
        window.open( link, '_blank');
      } else {
        this.displayService( 'Service', this.sanitizer.bypassSecurityTrustHtml( service.Body ) );
    }
  }

  InsertCompany( link: any ) {
    let comp = this.userSer.getClientName();
    comp = comp.trim().toLowerCase();
    let idx = 0;
    while (idx < comp.length) {
      if (comp[idx] === ' ') {
        comp = comp.slice(0, idx) + comp.slice(idx+1, comp.length);
      }
      idx++;
    }
    idx = link.indexOf('https://');
    if (idx !== 0) {
      alert('Invalid setup of the link');
      return link;
    }
    link = 'https://' + comp + link.slice(8, link.length);
    return link;
  }

}

