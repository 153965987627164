import { Injectable } from '@angular/core';
import {
  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, from, BehaviorSubject, of, observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import {tap, switchMap, catchError, filter, take, finalize} from 'rxjs/internal/operators';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { AuthService, IToken } from './auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService ) {

  }

  isRefreshingToken: any = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // add authorization header with jwt token if available
    const token = localStorage.getItem('token');
    return next.handle( this.addTokenToRequest(request, token))
        .catch( (err: any) => {
           if (err instanceof HttpErrorResponse) {
              switch ((<HttpErrorResponse>err).status) {
                case 401: {
                  if (this.authService.isTokenExpired( this.authService.getRefreshToken() )) {
                    console.log( 'access token expired, logging out');
                    this.authService.logout();
                    return ;
                  }
                  return this.handle401Error(request, next);
                }
                case 400:
                  this.authService.logout();
                  return ;
                case 422: {
                  this.authService.logout();
                  return ;
                }
                default: {
                  console.log( 'Caught error in interceptor' + err );
                  const errResult: any = { 'status': false, 'error': err };
                  return Observable.throw( errResult );
                  // return Observable.throw(err);
                }
              }
          } else {
             console.log( 'Caught error in interceptor' + err );
             return Observable.throw(err);
          }
         });
    }


  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}`}});
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshToken()
        .pipe(
           switchMap((user: IToken) => {
              if (user) {
                  this.tokenSubject.next(user.access_token);
                  return next.handle(this.addTokenToRequest(request, user.access_token));
                }
                this.authService.logout();
                return Observable.throw( user );
           }),
           catchError(err => {
             this.authService.logout();
             return Observable.throw( err );
           }),
           finalize(() => {
             this.isRefreshingToken = false;
           })
         );
    } else {
      return this.tokenSubject
        .pipe(filter(token => token != null),
          take(1),
          switchMap(token => {
            return next.handle(this.addTokenToRequest(request, token));
          }),
          catchError(err => {
            this.authService.logout();
            return Observable.throw( err );
          })
        );
    }
  }

}

