import {Component, OnInit, Input, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {IMyDpOptions} from 'mydatepicker';
import { _throw } from 'rxjs/observable/throw';
import { Observable, of, pipe, throwError, from } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { mergeMap, map, switchMap, debounceTime, catchError, filter } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as jquery from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { StatesService } from 'src/app/services/states.service';

declare var $: any;

@Component({
  selector: 'app-state-update',
  templateUrl: './state-update.component.html',
  styleUrls: ['./state-update.component.scss'],
})

export class StateUpdateComponent implements OnInit {
  buttonSelected: any;
  stateId: any;
  currentUser: any;
  gotData: any = false;
  afterContent: any = false;
  crumbList: any[];
  stateRec: any;
  stateUpdates: any[];


  constructor(
    private actRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private statesSer: StatesService,
    private ngZone: NgZone) {
  }

  ngOnInit() {
    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'State Update List', url: '/state-update-list'}
    ];
    this.actRoute.params.subscribe( params =>
      this.stateId = params['id']
    );
    console.log( this.stateId );

    this.statesSer.getStates( this.stateId ).subscribe((stateData: any) => {
      this.stateRec = stateData;
      this.stateUpdates = JSON.parse( this.stateRec.UpdateList );
      if (this.stateUpdates === undefined) {
        this.stateUpdates = [];
      } else {
         this.stateUpdates.forEach( rec => {
           rec.Law = this.sanitizer.bypassSecurityTrustHtml( rec.Law );
           rec.WhatToDo = this.sanitizer.bypassSecurityTrustHtml( rec.WhatToDo );
         });
         this.stateUpdates.sort( (a, b) => {
           if (a.EffectiveDate < b.EffectiveDate) {
             return 1;
           } else if (a.EffectiveDate > b.EffectiveDate) {
             return -1;
           }
           return 0;
         } );
      }

    });

  }




}


