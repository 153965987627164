import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { MessageService } from '../../services/message.service';
import { Subscription } from 'rxjs';
import { PartnerService } from 'src/app/services/partner.service';
import { flat, unflatten } from 'flat';

@Component({
    selector: 'app-sso-login',
    templateUrl: './sso-login.component.html',
    styleUrls: ['./sso-login.component.scss'],
    host: { 'class': 'sso-login main-screen' }
})
export class SSOLoginComponent implements OnInit {
    registerForm: FormGroup;
    submitted = false;
    emailFocus = false;
    passwordFocus = false;
    serverError: any = '';
    token;
    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    form: FormGroup;
    message: any;
    subscription: Subscription;

    constructor(
        private authS: AuthService,
        private fb: FormBuilder,
        private router: Router,
        private actRoute: ActivatedRoute,
        private userSer: UserService,
        private partnerSer: PartnerService,
        private msg: MessageService
    ) {
        if (localStorage.getItem('token') && false ) {
            this.router.navigate(['/dashboard']);
        }

        this.subscription = this.msg.getMessage().subscribe(message => { this.message = message; });
    }

    ngOnInit() {
        this.registerForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            check: ''
        });

        this.actRoute.queryParams.subscribe( params => {

            // const data = this.unflatten( params );
            const unfl = JSON.parse(params['payload']);
            const data = unflatten( unfl );
            console.log( unfl );

            // console.log('flat then unflat it');
            // const userData = {"UserData": {
            //     "Active": 1,
            //     "EMail": "sreeharsha.maddoju@primepay.com",
            //     "EmailCount": null,
            //     "FirstName": "Sreeharsha",
            //     "LastName": "Maddoju",
            //     "PartnerID": 10,
            //     "RoleType": 3,
            //     "Title": null,
            //     "UserID": 1718,
            //     "UserName": null,
            //     "UserType": null,
            //     "client": null,
            //     "conversation": null,
            //     "roleprofile": {
            //       "AuthList": "[{\"ID\":1,\"Status\":1,\"Description\":\"Manage Client Setup\",\"StatusType\":2},{\"ID\":2,\"Status\":1,\"Description\":\"Email Templates\",\"StatusType\":1},{\"ID\":3,\"Status\":1,\"Description\":\"Manage Partner Profile\",\"StatusType\":1},{\"ID\":4,\"Status\":1,\"Description\":\"Review Client Usage\",\"StatusType\":1}]",
            //       "ClientID": null,
            //       "FullAccess": 1,
            //       "ID": 645,
            //       "Name": "Admin",
            //       "PartnerID": 10,
            //       "Role": 3
            //     },
            //     "ticket": null
            //   },
            //   "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MTY2Mjc3MDEsIm5iZiI6MTYxNjYyNzcwMSwianRpIjoiZDQ0YjNiMGYtNDI3Zi00MTMzLThkOGUtMDRjZGI2MmRjZTdhIiwiZXhwIjoxNjE2NjI4NjAxLCJpZGVudGl0eSI6MTcxOCwiZnJlc2giOmZhbHNlLCJ0eXBlIjoiYWNjZXNzIiwidXNlcl9jbGFpbXMiOnsicm9sZSI6MywiY2xpZW50X2lkIjpudWxsLCJpc19leHBpcmVkIjpmYWxzZSwicm9sZXByb2ZpbGUiOnsiRnVsbEFjY2VzcyI6MSwiQ2xpZW50SUQiOm51bGwsIkF1dGhMaXN0IjoiW3tcIklEXCI6MSxcIlN0YXR1c1wiOjEsXCJEZXNjcmlwdGlvblwiOlwiTWFuYWdlIENsaWVudCBTZXR1cFwiLFwiU3RhdHVzVHlwZVwiOjJ9LHtcIklEXCI6MixcIlN0YXR1c1wiOjEsXCJEZXNjcmlwdGlvblwiOlwiRW1haWwgVGVtcGxhdGVzXCIsXCJTdGF0dXNUeXBlXCI6MX0se1wiSURcIjozLFwiU3RhdHVzXCI6MSxcIkRlc2NyaXB0aW9uXCI6XCJNYW5hZ2UgUGFydG5lciBQcm9maWxlXCIsXCJTdGF0dXNUeXBlXCI6MX0se1wiSURcIjo0LFwiU3RhdHVzXCI6MSxcIkRlc2NyaXB0aW9uXCI6XCJSZXZpZXcgQ2xpZW50IFVzYWdlXCIsXCJTdGF0dXNUeXBlXCI6MX1dIiwiUm9sZSI6MywiTmFtZSI6IkFkbWluIiwiSUQiOjY0NSwiUGFydG5lcklEIjoxMH19fQ.1uxsJCJswBB6B8CUcIBNj1MrWIRlFVVqthvfIgFgWZM",
            //   "multi_client": null,
            //   "refresh_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MTY2Mjc3MDEsIm5iZiI6MTYxNjYyNzcwMSwianRpIjoiZDY3NWIwMzktMDIxYS00NWM4LTg0NDItY2UwNTA2MTk5ZTMzIiwiZXhwIjoxNjE2NjMxMzAxLCJpZGVudGl0eSI6MTcxOCwidHlwZSI6InJlZnJlc2gifQ.gtYJfW4dNd4goDcz6NzdMqYn3HpzRFKoGOiDgggGCEg",
            //   "status": true
            // };

            // const fl = require('flat');
            // const flObj = fl.flatten( userData );
            // console.log( JSON.stringify(flObj) );

            if (unfl.status) {
                localStorage.clear();
                localStorage.setItem('token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                localStorage.setItem('ClientID', data.UserData.ClientID);
                localStorage.setItem('UserData', JSON.stringify(data.UserData));
                // this.userSer.getLoggedInUser();
                localStorage.setItem('info', JSON.stringify(data));

                localStorage.setItem('notSubscribed', 'Your subscription does not include access to this feature. ' +
                      'If you are interested in learning more, please email info@myhrcounsel.com or call (612) 339-1421');
                localStorage.setItem('showErisa', 'yes');
                if (data.UserData.client && data.UserData.client.PartnerID) {
                    this.partnerSer.getPartner(data.UserData.client.PartnerID).subscribe((partnerData: any) => {
                        if (partnerData.ServiceSolicitation !== null &&
                            partnerData.ServiceSolicitation.trim().length > 0) {
                              localStorage.setItem('notSubscribed', partnerData.ServiceSolicitation);
                        }
                        if (partnerData.ErisaCenter != null) {
                            localStorage.setItem('showErisa', (partnerData.ErisaCenter === 1 ? 'yes' : 'no'));
                        }
                        if (partnerData.ToolsList != null) {
                            localStorage.setItem('toolsList', partnerData.ToolsList );
                        } else {
                            localStorage.removeItem( 'toolsList' );
                        }
                    });
                }


                this.router.navigate(['/dashboard']);
            } else {
                this.serverError = params['error'];
            }
        });
    }


    // unflatten = function(data) {
    //     if (Object(data) !== data || Array.isArray(data)) {
    //         return data;
    //     }
    //     var result = {}, cur, prop, idx, last, temp;

    //     for(var p in data) {
    //         cur = result, prop = "", last = 0;
    //         do {
    //             idx = p.indexOf(".", last);
    //             temp = p.substring(last, idx !== -1 ? idx : undefined);
    //             cur = cur[prop] || (cur[prop] = (!isNaN(parseInt(temp)) ? [] : {}));
    //             prop = temp;
    //             last = idx + 1;
    //         } while(idx >= 0);
    //         cur[prop] = data[p];
    //     }
    //     return result[""];
    // }

    // flatten = function(data) {
    //     var result = {};
    //     function recurse (cur, prop) {
    //         if (Object(cur) !== cur) {
    //             result[prop] = cur;
    //         } else if (Array.isArray(cur)) {
    //              for(var i=0, l=cur.length; i<l; i++)
    //                  recurse(cur[i], prop ? prop+"."+i : ""+i);
    //             if (l == 0)
    //                 result[prop] = [];
    //         } else {
    //             var isEmpty = true;
    //             for (var p in cur) {
    //                 isEmpty = false;
    //                 recurse(cur[p], prop ? prop+"."+p : p);
    //             }
    //             if (isEmpty)
    //                 result[prop] = {};
    //         }
    //     }
    //     recurse(data, "");
    //     return result;
    // }


    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            this.submitted = true;
            return;
        } else {
            this.authS.getLogin(this.registerForm.value.email, this.registerForm.value.password).subscribe((data: any) => {
                if (data.status) {
                    localStorage.clear();
                    localStorage.setItem('token', data.access_token);
                    localStorage.setItem('refresh_token', data.refresh_token);
                    localStorage.setItem('ClientID', data.UserData.ClientID);
                    localStorage.setItem('UserData', JSON.stringify(data.UserData));
                    // this.userSer.getLoggedInUser();
                    localStorage.setItem('info', JSON.stringify(data));

                    localStorage.setItem('notSubscribed', 'Your subscription does not include access to this feature. ' +
                          'If you are interested in learning more, please email info@myhrcounsel.com or call (612) 339-1421');
                    localStorage.setItem('showErisa', 'yes');
                    if (data.UserData.client && data.UserData.client.PartnerID) {
                        this.partnerSer.getPartner(data.UserData.client.PartnerID).subscribe((partnerData: any) => {
                            if (partnerData.ServiceSolicitation !== null &&
                                partnerData.ServiceSolicitation.trim().length > 0) {
                                  localStorage.setItem('notSubscribed', partnerData.ServiceSolicitation);
                            }
                            if (partnerData.ErisaCenter != null) {
                                localStorage.setItem('showErisa', (partnerData.ErisaCenter === 1 ? 'yes' : 'no'));
                            }
                            if (partnerData.ToolsList != null) {
                                localStorage.setItem('toolsList', partnerData.ToolsList );
                            } else {
                                localStorage.removeItem( 'toolsList' );
                            }
                        });
                    }


                    this.router.navigate(['/dashboard']);
                } else {
                    this.serverError = data.error;
                }
            });
        }

    }

  autofocus(text) {
      if (text === 'email') {
        this.emailFocus = true;
        this.passwordFocus = false;
      } else {
        this.passwordFocus = true;
        this.emailFocus = false;
      }
  }

    /*
     ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }
    */
}

