import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { IPartner } from '../model/partner';
import { rolesStatus} from '../model/roles';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }

  //
  // use jwt to look up partner and return partner record
  //
  // getLoggedInPartner() {
  //   return this.http.get(`${environment.apiUrl}partner/partner-by-jwt/`)
  //      .pipe(map(res => { return res; }))
  //      .subscribe((data: any) => {
  //         if (data.status) {

  //         }
  //      }
  //   );
  // }

  getPartners(): Observable<IPartner> {
    return this.http.get(`${environment.apiUrl}partner/all-partners/` )
       .pipe(map((res: any) => {
         return res.data;
       }));

  }

  getPlatformPartners(): Observable<IPartner> {
    return this.http.get(`${environment.apiUrl}partner/all-platform-partners/` )
       .pipe(map((res: any) => {
         return res.data;
       }));
  }

  //
  // Get Partners
  //
  getAllPartners( page, per_page, sorting, filtering): Observable<any> {
    let add_url = '';
    if (filtering.companyname !== '') {
      add_url = add_url + '&companyname=' + filtering.companyname;
    }
    if (filtering.contactname !== '') {
      add_url = add_url + '&contactname=' + filtering.contactname;
    }
    if (filtering.status !== '') {
      add_url = add_url + '&status=' + filtering.status;
    }

    return this.http.get(`${environment.apiUrl}partner/partners/` + '?page=' + page
                    + '&per_page=' + per_page + '&sort_by=' + sorting.value + '&sort_type='
                    + sorting.sort + add_url )
       .pipe(map(res => { return res; }));
  }

  //
  // Creates a new Partner record
  //
  createPartner( form ) {

    return this.http.post(`${environment.apiUrl}partner/`, form)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

  //
  // Update a partner record
  //
  savePartner( partner_id, form ) {
    return this.http.post(`${environment.apiUrl}partner/` + partner_id, form
    )
    .pipe(
      map((res: any) => {
        if (res.status) {
          return res.status;
        }
      })
    );
  }

  //
  // Retrieve Partner with given partner-id
  //
  getPartner( partner_id ): Observable <IPartner> {
    return this.http.get(`${environment.apiUrl}partner/` + partner_id)
        .pipe(
           map((res: any) => {
             if (res.status) {
               return res.data;
             }
           })
        );
  }


  //
  // Delete a new partner record
  //
  deletePartner( id ): Observable <any> {

    return this.http.delete(`${environment.apiUrl}partner/` + id)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

}
