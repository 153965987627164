import { Component, OnInit, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AdminTiketService } from '../../services/admin-tiket.service';
import { ClientService } from '../../services/client.service';
import { IClients } from '../../model/clients';
import { PartnerService } from '../../services/partner.service';
import { RoleProfileService } from '../../services/roleprofile.service';
import { IUser } from '../../model/user';
import { IRoleProfile } from '../../model/roleprofile';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { ModalComponent } from '../../shared/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailService } from '../../services/mail.service';
import { isNumeric } from 'rxjs/internal-compatibility';
import * as jquery from 'jquery';
declare var $: any;

class CUser {
  public FirstName: string;
  public LastName: string;
  public EMail: string;
  public Password: string;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @Input() user_id: number = null;
  @Input() client_id: number = null;
  @Input() partner_id: number = null;
  @Output() submit_return: EventEmitter<boolean> = new EventEmitter<boolean>();

  user: IUser;
  clients: IClients[];
  myHrClient: IClients;
  partners: [];
  roleProfiles: IRoleProfile[];
  filteredRoles: IRoleProfile[];
  embedded: any = false;
  clientEmbed: any = false;
  showloader = true;
  successful_result = false;
  error_result = false;
  dropdownList = [];
  dropdownSettings: {};
  form: FormGroup;
  token: any;
  title: string;
  userData: any;
  submitted = false;
  active: any;
  emailCount: any = 0;
  realEmail: any = '';
  post_results: string;
  isPersonalProfile: any = true;
  private subscription: Subscription;
  private timer: Observable<any>;

  FirstNameCtrl: FormControl;
  LastNameCtrl: FormControl;
  EMailCtrl: FormControl;
  ClientIDCtrl: FormControl;
  RoleTypeCtrl: FormControl;
  PasswordCtrl: FormControl;
  PartnerIDCtrl: FormControl;
  RoleCtrl: FormControl;
  // PasswordExpirationDateCtrl: FormControl;
  ActiveCtrl: FormControl;
  UserTypes: any;
  RoleTypes: any;
  crumbList: any = [];

  constructor(
    private http: HttpClient,
    private userSer: UserService,
    private adminSer: AdminTiketService,
    private clientSer: ClientService,
    private partnerSer: PartnerService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private ngZone: NgZone,
    private actRoute: ActivatedRoute,
    private mailSer: MailService,
    private roleProfileSer: RoleProfileService,
    private router: Router ) {

      this.showloader = true;
  }

  ngOnInit() {
    this.showloader = true;
    this.userData = this.userSer.getUserData();

    if (typeof(this.partner_id) === 'undefined') {
      this.partner_id = null;
    }

    if (typeof(this.user_id) !== 'undefined' && this.user_id !== null) {
      this.embedded = true;
      this.clientEmbed = (this.partner_id === null || this.partner_id === 0 ||
              typeof(this.partner_id) === 'undefined');
    } else {
      this.actRoute.params.subscribe( params =>
         this.user_id = parseInt( params['id'], 10)
      );
      if (this.user_id === NaN) {
        this.user_id = 0;
      }
    }

    if (this.userData.RoleType === 8) {
      this.crumbList = [
        { title: 'Home', url: '/dashboard' },
        { title: 'Admin Menu', url: '/admins/admin-menu' },
        { title: 'User List', url: '/admins/user-list' }
      ];
      this.isPersonalProfile = false;
    } else {
      this.crumbList = [
        { title: 'Home', url: '/dashboard' },
        { title: 'Admin Menu', url: '/admins/admin-menu' }
      ];
      this.actRoute.params.subscribe( params =>
        this.isPersonalProfile = (params['personal'] === 'true')
      );
      if (this.isPersonalProfile) {
        this.user_id = this.userData.UserID;
      }
    }

    if (isNaN(this.user_id)) {
      this.user_id = 0;
    }
    // if (this.user_id > 0 && !this.userSer.isAdmin) {
    //   this.EMailCtrl.disable();
    //   this.form.get('EMail').disable();
    // }

    this.RoleTypes = [
      { RoleType: 2, RoleName: 'Client User' },
      { RoleType: 3, RoleName: 'Partner User' },
      { RoleType: 6, RoleName: 'Agent' },
      { RoleType: 8, RoleName: 'System Admin' },
    ];

    this.FirstNameCtrl = new FormControl('FirstName', [Validators.required, Validators.minLength(2)]);
    this.LastNameCtrl = new FormControl('LastName', [Validators.required, Validators.minLength(2)]);
    if (this.user_id > 0 && !this.userSer.isAdmin()) {
      this.EMailCtrl = new FormControl({ value: '', disabled: true}, [Validators.required,
                           Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]);
    } else {
      this.EMailCtrl = new FormControl('EMail', [Validators.required,
        Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]);
    }
    this.ClientIDCtrl = new FormControl('ClientId');
    this.RoleTypeCtrl = new FormControl('RoleType', [Validators.required]);
    const regex = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,20}/);
    this.PasswordCtrl = new FormControl('Password', [Validators.required,
                             Validators.pattern( regex )]);
    this.PartnerIDCtrl = new FormControl('PartnerID');
    this.RoleCtrl = new FormControl('Role');

    // this.PasswordExpirationDateCtrl = new FormControl('PasswordExpirationDate', [Validators.required] );
    this.ActiveCtrl = new FormControl('Active');

    if (this.embedded) {
      this.ClientIDCtrl.disable();
      this.ClientIDCtrl.setValue( this.client_id );
    }

    this.form = this.fb.group({
      FirstName: this.FirstNameCtrl,
      LastName: this.LastNameCtrl,
      ID: this.ClientIDCtrl,
      EMail: this.EMailCtrl,
      Password: this.PasswordCtrl,
      RoleType: this.RoleTypeCtrl,
      HireDate: Date.now(),
      Active: this.ActiveCtrl,
      PartnerID: this.PartnerIDCtrl,
      Role: this.RoleCtrl
    });

    if (this.user_id === undefined || this.user_id === 0 || isNaN(this.user_id)) {
      this.user_id = 0;
      this.title = 'New User';
      this.form.patchValue({
        FirstName: '',
        LastName: '',
        EMail: '',
        RoleType: (this.clientEmbed ? '2' : this.embedded ? '3' : ''),
        Password: '',
        Active: 1,
        Role: 0
      });
      this.active = 'Yes';

      if (!this.embedded && this.isPersonalProfile) {
        this.router.navigate(['/dashboard']);
      }

    } else {
      // if editing an User while under client setup and you are a partner
      // then disable the password so the partner can't login as a Client
      // if (this.clientEmbed && this.userData.RoleType === 3) {
      //   this.PasswordCtrl.disable();
      // }

      this.title = 'Edit User';
      this.userSer.getUser(this.user_id).subscribe((usrData: any) => {

        if (!this.embedded && this.isPersonalProfile && this.user_id !== this.userData.UserID) {
          this.router.navigate(['/dashboard']);
        }

        this.form.patchValue({
              FirstName: usrData.FirstName,
              LastName: usrData.LastName,
              EMail: usrData.EMail,
              RoleType: usrData.RoleType,
              Password: usrData.Password,
              ID: usrData.client ? usrData.client.ID : 0,
              Active: usrData.active,
              PartnerID: usrData.PartnerID,
              Role: (usrData.roleprofile ? usrData.roleprofile.ID : 0)
        });
        this.emailCount = (usrData.EmailCount === null ? 0 : usrData.EmailCount);
        this.realEmail = usrData.EMail;
        this.active = (usrData.Active === 1 ? 'Yes' : 'No');
      });
    }

    this.token = localStorage.getItem('token');

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
    const userData = localStorage.getItem('UserData');

    if (!this.embedded) {
      this.clientSer.getClients().subscribe((data: any) => {
        this.clients = data;

        this.myHrClient = this.clients.find( x => x.ClientName === 'myHRcounsel' );

        // no reason to get partner list unless we are in the stand alone
        // user setup
        this.partnerSer.getPartners().subscribe( (pData: any) => {
          this.partners = pData;
          this.showloader = false;
        });

        this.getRoleProfiles();

        this.showloader = false;
      });
    } else {
      this.getRoleProfiles();
      this.showloader = false;
    }
  }

  getRoleProfiles() {
    this.roleProfileSer.getRoleProfiles().subscribe((rpData: any) => {
      this.roleProfiles = rpData;
      this.filterRoleProfiles();

      // embedded into the partner user list screen
      // patch the client to myhr and the id to the one passed in
      // fix the role to partner only
      if (this.embedded && !this.clientEmbed) {
        if (this.myHrClient != null) {
          this.form.patchValue( {ID: this.myHrClient.ID });
        }
        this.form.patchValue( { PartnerID: this.partner_id, RoleType: 3 });

        this.RoleTypes = [
          { RoleType: 3, RoleName: 'Partner User', UserType: 3}
        ];
      }

      // client embedded, patch the roletype, and soon to be depricated UserType
      if (this.clientEmbed) {
        this.RoleTypes = [
          { RoleType: 2, RoleName: 'Client User' }
        ];
        this.form.patchValue( {RoleType: 2, UserType: 5 });
      }
    });
  }

  filterRoleProfiles() {
    let roleType = this.form.value.RoleType;
    if (typeof(roleType) === 'string') {
      roleType = parseInt( roleType, 10 );
    }

    // if partner roleType
    if (roleType === 3) {
      let pID = parseInt( this.form.value.PartnerID, 10);
      if (this.embedded) {
        pID = this.partner_id;
      }
      if (typeof(pID) === 'string') {
        pID = parseInt( pID, 10 );
      }
      this.filteredRoles = this.roleProfiles.filter( x => x.PartnerID === pID );
    } else if (roleType === 8) {  // system admin roletype
      this.filteredRoles = this.roleProfiles.filter( x => x.ClientID === null && x.PartnerID === null);
    } else {   // user is all that's left, agents can't get in
       let cID: any;
       if (this.embedded) {
         cID = this.client_id;
       } else if (roleType === 2) {
         cID = parseInt( this.form.value.ID, 10 );
       } else {
         cID = this.myHrClient.ID;
       }
       if (typeof(cID) === 'string') {
         cID = parseInt( cID, 10 );
       }
       this.filteredRoles = this.roleProfiles.filter( x => x.ClientID === cID );
    }
  }

  showFields() {
    const controls = this.form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            console.log( name );
        }
    }

  }

  saveUser( editForm: any ) {
    // this.submitted = true;
    this.showloader = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this.showFields();
      this.showloader = false;
      return;
    }

    if (this.form.valid) {
      const fd = new FormData();

      fd.append('FirstName', this.form.value.FirstName);
      fd.append('LastName', this.form.value.LastName);
      if (this.user_id === 0 || this.userSer.isAdmin()) {
            fd.append('EMail', this.form.value.EMail);
      }
      if (typeof(this.partner_id) !== 'undefined' && this.partner_id !== null) {
        fd.append('PartnerID', this.partner_id.toString(10) );
      } else if (this.embedded) {
        fd.append('ClientID', this.client_id.toString( 10 ) );
      } else {
        let rType = this.form.value.RoleType;
        if (typeof(rType) === 'string') {
          rType = parseInt( rType, 10 );
        }
        if (rType === 8 || rType === 6) {
          fd.append('ClientID', this.myHrClient.ID.toString(10) );
        } else if (rType !== 3) {
           fd.append('ClientID', this.form.value.ID);
        }
      }
      fd.append('RoleType', this.form.value.RoleType);
      fd.append('Password', this.form.value.Password );
      fd.append('Active', this.active === 'Yes' ? '1' : '0');
      fd.append('Role', this.form.value.Role);
      fd.append('EmailCount', this.emailCount );

      const that = this;
      if (this.user_id === 0) {
         this.userSer.createUser(fd).subscribe(
          {
            next(res: any) {
              if (res.status) {
                that.successful_result = true;
                this.user_id = res.data.UserID;
                editForm.reset();
              } else {
                that.error_result = true;
                console.log('unable to save');
              }
              that.setTimer();
            },
            error(msg) {
              that.ngZone.run( () => {
                alert( 'Error saving record. Most likely the Service is a duplicate of an existing record' );
              });
            }
        });
      } else {
        this.userSer.saveUser(this.user_id, fd ).subscribe((res: any) => {
          if (res) {
             this.successful_result = true;
          } else {
            this.error_result = true;
            alert('Error saving record. Likely using the same email?');
            console.log('unable to save');
          }
          this.setTimer();
        });
      }
      this.showloader = false;
      this.submitted = false;
    }
  }

  public setTimer() {
    this.timer = Observable.timer(1500);
    this.subscription = this.timer.subscribe(() => {
        this.successful_result = false;
        this.error_result = false;
        if (this.user_id === 0) {
          this.form.patchValue({
            FirstName: '',
            LastName: '',
            Password: '',
            Email: '',
            Role: 0
          });
        }
        this.submit_return.emit( true );
    });
  }

  toggleChange(event: any) {
    this.active = (event.target.checked ? 'Yes' : 'No');
  }

  sendEmail(id: number) {
      if (this.user_id === 0) {
        alert('Please save the new user before sending out the welcome email.');
        return;
      }
      const modalRef = this.modalService.open( ModalComponent );
      modalRef.componentInstance.confirmationStr = '<p>Send Welcome Email?' +
      '</p>';
      modalRef.componentInstance.title = 'Welcome Email Confirmation';
      modalRef.result.then((res: any) => {
        let mail = new Array();
        mail.push( this.realEmail );

        const cUser: CUser = new CUser();
        cUser.FirstName = this.form.value.FirstName;
        cUser.LastName = this.form.value.LastName;
        cUser.Password = this.form.value.Password;

        this.mailSer.sendEmail( 'welcome_user.html', mail, cUser, 'Welcome Email', null, null ).subscribe((dt: any) => {
          this.emailCount += 1;
          this.saveUser( this.form );
        });
      })
      .catch( (res: any) => {
      });
  }
 

}
