import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { IAuthKey } from '../model/authkey';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class AuthKeyService {

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }

   getPartnerKeysData( partnerId: any): Observable<IAuthKey> {
     return this.http.get(`${environment.apiUrl}authkey/partner-authkey` + '?Id=' + partnerId )
        .pipe(map((res: any) => {
          return res.data;
        }));

   }


  //
  // Creates a new AuthKey record
  //
  createAuthKey( form ) {

    return this.http.post(`${environment.apiUrl}authkey/`, form)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

  //
  // Update a AuthKey record
  //
  saveAuthKey( id, form ) {
    return this.http.post(`${environment.apiUrl}authkey/` + id, form
    )
    .pipe(
      map((res: any) => {
        if (res.status) {
          return res.status;
        }
      })
    );
  }

  //
  // Retrieve AuthKey with given id
  //
  getAuthKey( id ): Observable <IAuthKey> {
    return this.http.get(`${environment.apiUrl}authkey/` + id)
        .pipe(
           map((res: any) => {
             if (res.status) {
               return res.data;
             }
           })
        );
  }


  //
  // Delete a new AuthKey record
  //
  deleteAuthkey( id ): Observable <any> {

    return this.http.delete(`${environment.apiUrl}authkey/` + id)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

}
