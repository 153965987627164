import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { IDocument } from '../model/document';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }

   getDocumentData(): Observable<IDocument> {
     return this.http.get(`${environment.apiUrl}document/all-documents` )
        .pipe(map((res: any) => {
          return res.data;
        }));

   }

  //
  // Get Document
  //
  getAllDocuments( services, page, per_page, sorting, filtering): Observable<any> {
    let add_url = '';
    if (filtering.name !== undefined && filtering.name !== '' ) {
      add_url = add_url + '&name=' + filtering.name;
    }
    if (filtering.lastauthor !== undefined) {
      add_url = add_url + '&lastauthor=' + filtering.lastauthor;
    }
    if (filtering.hasstate !== undefined) {
      add_url = add_url + '&hasstate=' + filtering.hasstate;
    }
    if (filtering.state !== undefined) {
      add_url = add_url + '&state=' + filtering.state;
    }
    if (filtering.category !== undefined && filtering.category.trim() !== '') {
      add_url = add_url + '&category=' + filtering.category;
    }

    return this.http.get(`${environment.apiUrl}document/documents/` + '?services=' + services 
                    + '&page=' + page
                    + '&per_page=' + per_page + '&sort_by=' + sorting.value + '&sort_type='
                    + sorting.sort + add_url )
       .pipe(map(res => { return res; }));
  }

  //
  // Creates a new Document record
  //
  createDocument( form ) {

    return this.http.post(`${environment.apiUrl}document/`, form)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

  //
  // Update a Document record
  //
  saveDocument( id, form ) {
    return this.http.post(`${environment.apiUrl}document/` + id, form
    )
    .pipe(
      map((res: any) => {
        if (res.status) {
          return res.status;
        }
      })
    );
  }

  //
  // Retrieve Document with given id
  //
  getDocument( id ): Observable <IDocument> {
    return this.http.get(`${environment.apiUrl}document/` + id)
        .pipe(
           map((res: any) => {
             if (res.status) {
               return res.data;
             }
           })
        );
  }


  //
  // Delete a new Document record
  //
  deleteDocument( id ): Observable <any> {

    return this.http.delete(`${environment.apiUrl}document/` + id)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

}
