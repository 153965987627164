import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDefaultComponent } from './user-default.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule} from '../shared/shared.module';
import { CoreModule} from '../core/core.module';
import { Routes, RouterModule } from '@angular/router';
import { TicketComponent } from './ticket/ticket.component';
import { TicketFilterComponent } from './ticket/ticket-filter/ticket-filter.component';
import { AddTicketComponent } from './ticket/add-ticket/add-ticket.component';
import { EditTicketComponent } from './ticket/edit-ticket/edit-ticket.component';
import { HRMenuComponent } from './hr-menu/hr-menu.component';
// import { BizMenuComponent } from './biz-menu/biz-menu.component';
import { HiringComponent } from './hiring/hiring.component';
import { HandbookComponent } from './handbook/handbook.component';
import { StateUpdateComponent } from './state-update/state-update.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AuthGuard } from '../auth/auth.guard';
import { MyDatePickerModule } from 'mydatepicker';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
// import { ShowPageComponent } from './show-page/show-page.component';
import { StatesUpdateListComponent } from './states-update-list/states-update-list.component';
import { ContentComponent } from '../user-default/content/content.component';
import { ContentListComponent } from '../user-default/content-list/content-list.component';
import { DocumentsComponent } from '../user-default/documents/documents.component';

/**
 * User Routing
 */
const user_routing: Routes = [
  {path : '', component : UserDefaultComponent,  canActivateChild: [AuthGuard],
   children: [
     {path: 'dashboard', component: DashboardComponent},
     {path: 'ticket', component: TicketComponent},
     {path: 'ticket/:id/edit', component: EditTicketComponent},
     {path: 'add-ticket', component: AddTicketComponent},
     {path: 'hr-menu/solution-center', component: HRMenuComponent},
     {path: 'hr-menu/solution-center/:id', component: HRMenuComponent},
     {path: 'hr-menu/business-center', component: HRMenuComponent},
     {path: 'hr-menu/business-center/:id', component: HRMenuComponent},
     {path: 'hr-menu/erisa-center', component: HRMenuComponent},
     {path: 'hr-menu/erisa-center/:id', component: HRMenuComponent},
     // {path: 'biz-menu', component: BizMenuComponent},
     {path: 'hiring', component: HiringComponent},
     {path: 'hiring/:id', component: HiringComponent},
     // {path: 'show-page/:id', component: ShowPageComponent},
     {path: 'handbook', component: HandbookComponent},
     {path: 'state-update/:id', component: StateUpdateComponent },
     {path: 'state-update-list', component: StatesUpdateListComponent},
     {path: 'content/newsletter/:id', component: ContentComponent },
     {path: 'content/webinar/:id', component: ContentComponent },
     {path: 'content/tip/:id', component: ContentComponent },
     {path: 'content/new/:id', component: ContentComponent },
     {path: 'content/blog/:id', component: ContentComponent },
     {path: 'content/newsletter-list', component: ContentListComponent },
     {path: 'content/blog-list', component: ContentListComponent },
     {path: 'content/webinar-list', component: ContentListComponent },
     {path: 'content/tip-list', component: ContentListComponent },
     {path: 'content/news-list', component: ContentListComponent },
     {path: 'documents', component: DocumentsComponent },
     {path: 'documents/:search', component: DocumentsComponent }
   ]
 }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    MyDatePickerModule,
    RouterModule.forChild(user_routing),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
   RouterModule,
    MyDatePickerModule
  ],
  declarations: [
    UserDefaultComponent,
    DashboardComponent,
    TicketComponent,
    TicketFilterComponent,
    AddTicketComponent,
    EditTicketComponent,
    ContentComponent,
    ContentListComponent,
    HRMenuComponent,
    // BizMenuComponent,
    HiringComponent,
    // ShowPageComponent,
    StateUpdateComponent,
    StatesUpdateListComponent,
    HandbookComponent,
    DocumentsComponent
  ]
})
export class UserDefaultModule { }
