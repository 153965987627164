import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ticket-filter',
  templateUrl: './ticket-filter.component.html',
  styleUrls: ['./ticket-filter.component.scss']
})
export class TicketFilterComponent implements OnInit {
  selectedRadioButtonValue: string = 'all_tickets';

  curr_user_id: any;
  @Output()
  countRadioButtonSelectionChanged: EventEmitter<string> = new EventEmitter<string>();
  ticketTitle: any;
  ticketCount: number;

  @Input() showFilter: boolean;
  constructor() { }
  @Input()
  all_tickets: number;
  @Input()
  solved_ticket: number;
  @Input()
  unassigned_ticket: number;
  @Input()
  unsolved_ticket: number;
  @Input()
  deleted_ticket: number;


  ngOnInit() {
    jQuery('.ticket-filter input').on('click', function () {
      jQuery(this).parent().addClass('active').siblings().removeClass('active');
    });

    const userData = localStorage.getItem('UserData');
    this.curr_user_id = JSON.parse(userData);
  }

  onRadioButtonSelectionChange() {
    this.countRadioButtonSelectionChanged.emit(this.selectedRadioButtonValue);
   // console.log(this.selectedRadioButtonValue);
  }

}
