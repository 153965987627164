import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { rolesStatus} from '../model/roles';

declare function versionFunction(): any;

@Component({
  selector: 'app-user-default',
  templateUrl: './user-default.component.html',
  styleUrls: ['./user-default.component.scss']
})


export class UserDefaultComponent implements OnInit {

  public roles;
  constructor(private userSer: UserService) {

  }

  ngOnInit() {
    const ver  = versionFunction();
    $('body').mousedown( function( event ) {
      if ($('#optionsMenu').hasClass('show')) {
         if ($(event.target).parents('#optionsMenu').length === 0 &&
             $(event.target).parents('#accountMenu').length === 0) {
           (<any>$('#optionsMenu')).collapse('hide');
           $('#accountMenu').removeClass('show');
         }
      }
    } );
  }

  onResize( event: any) {
    if ($('#tools').length === 0) {
      return;
    }
    const off = $('#tools').offset();
    if (off.left > 500) {
      $('#tools').css('margin-top', '75px');
    } else {
      $('#tools').css('margin-top', '30px');
    }
  }

}
