import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { IPost } from '../model/post';
import { rolesStatus} from '../model/roles';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class PostService {

  // private token;
  constructor(private http: HttpClient, private router: Router, private msgSer: MessageService) {
  }

  getPosts(): Observable<IPost> {
    return this.http.get(`${environment.apiUrl}post/all-posts/` )
       .pipe(map((res: any) => {
         return res.data;
       }));

  }

  //
  // Get Posts
  //
  getAllPosts( parent_id, page, per_page, sorting, filtering): Observable<any> {
    let add_url = '';
    if (filtering.name !== '') {
      add_url = add_url + '&name=' + filtering.name;
    }

    return this.http.get(`${environment.apiUrl}post/posts/` + '?parent_id=' + parent_id + '&page=' + page
                    + '&per_page=' + per_page + '&sort_by=' + sorting.value + '&sort_type='
                    + sorting.sort + add_url )
       .pipe(map(res => { return res; }));
  }

  //
  // Creates a new Post record
  //
  createPost( form ) {

    return this.http.post(`${environment.apiUrl}post/`, form)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

  //
  // Update a post record
  //
  savePost( post_id, form ) {
    return this.http.post(`${environment.apiUrl}post/` + post_id, form
    )
    .pipe(
      map((res: any) => {
        if (res.status) {
          return res.status;
        }
      })
    );
  }

  //
  // Retrieve Post with given post-id
  //
  getPost( post_id ): Observable <IPost> {
    return this.http.get(`${environment.apiUrl}post/` + post_id)
        .pipe(
           map((res: any) => {
             if (res.status) {
               return res.data;
             }
           })
        );
  }


  //
  // Delete a new post record
  //
  deletePost( id ): Observable <any> {

    return this.http.delete(`${environment.apiUrl}post/` + id)
      .pipe(
        map((res: any) => {
          if (res.status) {
            return res;
          }
        })
      );
  }

}
