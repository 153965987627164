import { Component, NgZone, OnInit } from '@angular/core';
import { TalentService } from '../../services/talentlms.service';
import { ITalentCourse } from '../../model/talentlms';
import { IPage } from '../../model/page';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {PagerService} from '../../services/pager.service';
import { FormGroup, FormBuilder , Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { ClientService } from 'src/app/services/client.service';
import { ModalComponent } from '../../shared/modal.component';
import { InfoModalComponent } from '../../shared/info-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import { formControlBinding } from '@angular/forms/src/directives/ng_model';
// import { validateConfig } from '@angular/router/src/config';
declare var $: any;

@Component({
  selector: 'app-talent',
  templateUrl: './talent.component.html',
  styleUrls: ['./talent.component.scss']
})
export class TalentComponent implements OnInit {
  name: any;
  status: any;
  showloader = true;
  error_result = false;
  private subscription: Subscription;
  private timer: Observable<any>;
  crumbList: any = [];
  isFilterTrue = true;
  // userValue: IUserValue;
  paging: IPage;
  totalUsers: number;
  per_page = 10;
  selected_status: number;
  filter: any = { 'clientname': '', 'name': '', 'email': ''};
  templates: any;
  curr_user_id: any;
  total: number = 0;
  totalCourses: any = 0;
  totalBranches: any = 0;
  noMatch: any = 0;
  public row_header: any = [];
  public header_index: any;
  pager: any = {};
  pagedItems: any[];
  courses: any[];
  branches: any[];
  pageBranches: any[];
  unmatchedBranches: any[] = [];
  searchBranches: any[] = [];
  clients: any[];
  all: any = 1;

  constructor(
    private pagerS: PagerService,
    private modalService: NgbModal,
    private infoModalService: NgbModal,
    private http: HttpClient,
    private talentSer: TalentService,
    private clientSer: ClientService,
    private actRoute: ActivatedRoute,
    private ngZone: NgZone,
    private router: Router ) {

      this.showloader = true;
  }

  ngOnInit() {
    this.showloader = true;
    this.crumbList = [
      { title: 'Home', url: '/dashboard' },
      { title: 'Admin Menu', url: '/admins/admin-menu' }
    ];

    this.row_header  = [
      {text: 'Name', value: 'Name', 'sort': null},
      {text: 'Code', value: 'Code', 'sort': null}
    ];
    this.header_index = 0;
    this.showloader = true;
    this.talentSer.getCourses().subscribe( (data: any) => {
      this.courses = data.data;
      this.totalCourses = this.courses.length;

      this.talentSer.getBranches().subscribe( (branData: any) => {
        this.branches = branData.data;
        this.totalBranches = this.branches.length;

        this.clientSer.getClients().subscribe( (cData: any) => {
          this.clients = cData;
          this.matchUpClients();
          this.pager.startIndex = 0;
          this.pager.endIndex = 10;
          this.setPage(1);
          this.showloader = false;
        });
      });
    });

  }

  allMatching( event: any ) {
    if (event.target.id === 'all') {
      this.all = 1;
    } else if (event.target.id === 'nomatch') {
      this.all = 2;
      this.pager.startIndex = 0;
      this.pager.endIndex = 10;
      this.pager.currentPage = 1;
    } else {
      event.target.id = 3;
      this.all = 3;
      this.pager.startIndex = 0;
      this.pager.endIndex = 10;
      this.pager.currentPage = 1;
    }
    $('#nameSearch').val('');
    this.setPage( this.pager.currentPage );
  }

  setPage( page: any ) {
    if (this.all === 1) {
       this.pageBranches = this.branches.slice( this.pager.startIndex, this.pager.endIndex );
       this.total = this.branches.length;
       this.pager = this.pagerS.getPager(this.branches.length, page );
    } else if (this.all === 2) {
      this.pageBranches = this.unmatchedBranches.slice( this.pager.startIndex, this.pager.endIndex );
      this.total = this.unmatchedBranches.length;
      this.pager = this.pagerS.getPager(this.unmatchedBranches.length, page );
    } else {
      this.pageBranches = this.searchBranches.slice( this.pager.startIndex, this.pager.endIndex );
      this.total = this.searchBranches.length;
      this.pager = this.pagerS.getPager(this.searchBranches.length, page );
    }
    this.pullCourses();
  }

  displayMessage( msg: any ) {
    const modalRef = this.infoModalService.open( InfoModalComponent );
    modalRef.componentInstance.confirmationStr = msg;
    modalRef.componentInstance.title = 'Branch Details';
    modalRef.result.then((res: any) => {
    });

  }

  pullCourses(): Observable<ITalentCourse> {
    return Observable.create( this.actuallyGetCourses() );
  }

  actuallyGetCourses() {
    if (! this.pageBranches[0] ) {
      return;
    }
    if (this.pageBranches[0].courses) {
      return;
    }

    this.pageBranches.forEach( branch => {
       this.talentSer.getBranch( branch.id ).subscribe( (data) => {
         branch.courses = data.data.courses;
         branch.users = data.data.users;
       });
    });
  }


  matchUpClients() {
    this.branches.forEach( function(branch) {
      branch.matched = false;
    });

    const that = this;
    this.clients.forEach( function(client) {
       const name = client.ClientName.toLowerCase().replace( / |\.|,|-|&|%|=|!|#|@|'|_|\*|\+|\$|\^|\)|\(|\/|\\\//g, '');
       const branch = that.branches.find( x => x.name === name );
       if (branch) {
         branch.matched = true;
         branch.services = JSON.parse( client.ClientServices );
       }
    });

    this.branches.forEach( function(branch) {
      if (!branch.matched) {
        that.noMatch++;
        that.unmatchedBranches.push( branch );
      }
    });
  }

  displayBranch(idx: any) {
    const branch = this.pageBranches[idx];
    let msg =  '<p><strong>' + branch.name + '</strong><br/><br/><b> Courses Assigned </b><br/><ul>';
    branch.courses.forEach( function(course) { msg += '<li class="pb-0">' + course.name + '</li><br/>'; });
    msg += '</ul><br/><b>Users</b><br/><br/><ul>';
    branch.users.forEach( user => msg += '<li class="pb-0">' + user.name + '</li>' );
    this.displayMessage( msg );
  }


  swapIcon( event: any ) {
    const ele = event.target;
    const expanded = $(ele).hasClass('fa-expand-alt');
    if (expanded) {
      $(ele).removeClass('fa-expand-alt').addClass('fa-compress-alt');
    } else {
      $(ele).removeClass('fa-compress-alt').addClass('fa-expand-alt');
    }
  }

  convertToString( arr: any[] ) {
    let result = '';
    for (var idx = 0; idx < arr.length; idx++ ) {
      result += arr[idx];
    }
    return result;
  }


  nameSearch(event: any) {
    console.log( event.target.value );
    const searchName = event.target.value;
    this.searchBranches = [];
    const that = this;
    this.branches.forEach( function(branch) {
       const idx = branch.name.indexOf( searchName );
       if (idx > 0) {
         that.searchBranches.push( branch );
       }
    });
    this.all = 3;
    // $('#all').checked = false;
    // $('#nomatch').checked = false;
    // $('#search').checked = true;
    this.setPage( 1 );
  }

  public setTimer() {
    this.timer = Observable.timer(2500);
    this.subscription = this.timer.subscribe(() => {
        this.error_result = false;
    });
  }

  toggleChange(event: any) {
    this.status = (event.target.checked ? 'Yes' : 'No');
  }

}
