import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpEventType} from '@angular/common/http';
import { IClients} from '../model/clients';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { IUser } from '../model/user';
import { ISubject } from '../model/subject';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AdminTiketService {

  constructor(private http: HttpClient, private router: Router) {
  }


  // getAssignUser( token ): Observable<IClients> {
  //   return this.http.get(`${environment.apiUrl}user/agents`
  //     ).pipe(
  //       map((res: any) => {
  //         return res.data;
  //       })
  //     );
  // }

  getKeywords( ): Observable <IClients> {
    return this.http.get(`${environment.apiUrl}tag/`
      )
      .pipe(
        map( (res: any) => {
           return res.data;
        })
      );
  }



  //
  // Get Subject matter
  //
  // getSubject(): Observable<ISubject> {
  //   return this.http.get(`${environment.apiUrl}code/subjects`
  //   )
  //   .pipe(
  //     map( (res: any) => {
  //       if (res.status) {
  //        return res.data;
  //       }
  //     })
  //   );
  // }

  //
  // Post Data
  //
  saveTicket(data): Observable<any> {
     return this.http.post(`${environment.apiUrl}ticket/`, data)
      .pipe(
        map( (res: any) => {
          return res.data;
        })
       );
  }

}
